import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"

interface Props {
    title: string,
    btnPath?: string,
    btntext?: string
}

const HeaderCard: React.FC<Props> = ({ title, btnPath, btntext }) => {
    const navigate = useNavigate();




    return (
        <div className="bg-green-100 border-2 border-green-200 rounded-lg p-3 flex flex-col sm:flex-row justify-between items-center">
            <h2 className={`text-lg font-semibold text-green-700 mb-2 sm:mb-0 ${btnPath == undefined && " text-center w-full"}`}>
                {title}
            </h2>
            {
                btnPath != undefined  && (
                    <Button
                        label={btntext}
                        icon="pi pi-plus"
                        className="p-button-sm p-button-outlined"
                        onClick={() => navigate(btnPath)}
                    />
                )
            }

        </div>
    )
}

export default HeaderCard; 