import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import useApiRequest from '@mbs-dev/api-request';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { CountrySchema } from '../../../utils/schemas/CountrySchema';
import { notify } from '@mbs-dev/react-helpers';
import { CountryIV } from '@utils/initValues/CountryIV';
import LoadingData from '@components/Loading/LoadingData';
import { getCountry, updateCountry } from '@utils/api/country';
import CountryFlag from '@components/Global/CountryFlag';
import HeaderCard from '@components/UI/Header/HeaderCard';

interface CountryData {
    name: string;
    code: string;
    currency: string;
}

const CountryEdit: React.FC = () => {
    const queryClient = useQueryClient();
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const { data: countryData, isLoading } = useQuery({
        queryKey: ['country', id],
        queryFn: () => getCountry(apiRequest, id),
        enabled: !!id,
    });

    const mutation = useMutation({
        mutationFn: (data: CountryData) => updateCountry(apiRequest, id, data),
        onSuccess: () => {
            navigate('/dashboard/setting/countries');
            queryClient.invalidateQueries({
                queryKey : ['countries']
            });
        },
        onError: () => {
            notify.errorNotify('Erreur lors de la mise à jour du pays');
        },
    });

    const formik = useFormik<CountryData>({
        initialValues: CountryIV,
        validationSchema: CountrySchema,
        onSubmit: (values) => mutation.mutate(values),
        enableReinitialize: true,
    });

    useEffect(() => {
        if (countryData) {
            formik.setValues({
                name: countryData.name || '',
                code: countryData.code || '',
                currency: countryData.currency || '',
            });
        }
    }, [countryData]);

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title={` Modifier le pays`} />

                {isLoading ? (
                    <LoadingData />
                ) : (
                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                        <div>
                            <label className="block text-sm font-medium mb-2">Nom du pays</label>
                            <InputText
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                                placeholder="Entrez le nom du pays"
                            />
                            {formik.errors.name && formik.touched.name && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-2">Code du pays</label>
                            <InputText
                                name="code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                className={`p-inputtext w-full ${formik.errors.code && formik.touched.code ? 'p-invalid' : ''}`}
                                placeholder="Entrez le code du pays (ex: FR)"
                            />
                            {formik.errors.code && formik.touched.code && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.code}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-2">Devise</label>
                            <InputText
                                name="currency"
                                value={formik.values.currency}
                                onChange={formik.handleChange}
                                className={`p-inputtext w-full ${formik.errors.currency && formik.touched.currency ? 'p-invalid' : ''}`}
                                placeholder="Entrez la devise (ex: EUR)"
                            />
                            {formik.errors.currency && formik.touched.currency && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.currency}</p>
                            )}
                        </div>

                        <div className="flex justify-end">
                            <Button
                                label="Mettre à jour"
                                icon="pi pi-check"
                                className="p-button-success"
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            </Card>
        </div>
    );
};

export default CountryEdit;
