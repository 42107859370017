import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import 'primereact/resources/primereact.min.css';         // Core CSS
import 'primeicons/primeicons.css';                       // Prime Icons
import { useAuth } from '@context/AuthContext';
import Header from '@components/Home/Header';
import ProfileHeader from '@components/profile/ProfileHeader';

type AppSidebarPros = {
    visible: boolean,
    setVisible: any
}
const AppSidebar: React.FC<AppSidebarPros> = ({ visible, setVisible }) => {

    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleNavigation = (path: string) => {
        navigate(path);
        setVisible(false);
    };


    const menuItems = [
        {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            command: () => handleNavigation('/dashboard'),
        },
        {
            label: 'Stock',
            icon: 'pi pi-fw pi-warehouse',
            command: () => handleNavigation('/dashboard/stock')
        },
        {
            label: 'Review',
            icon: 'pi pi-fw pi-star',
            command: () => handleNavigation('/dashboard/reviews')
        },
        {
            label: 'Commandes',
            icon: 'pi pi-fw pi-receipt',
            items: [
                {
                    label: 'List Commandes',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/orders'),
                },
                {
                    label: 'List Commandes Offline',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/ordersOffline'),
                }
            ],
        },
        {
            label: 'Products',
            icon: 'pi pi-fw pi-box',
            items: [
                {
                    label: 'List Products',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/products'),
                },
                {
                    label: 'Add Product',
                    icon: 'pi pi-fw pi-plus',
                    command: () => handleNavigation('/dashboard/products/add'),
                },

            ],
        },

        {
            label: 'Categories',
            icon: 'pi pi-fw pi-th-large',
            items: [

                {
                    label: 'List Categories',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/categories'),
                },
                {
                    label: 'Add Category',
                    icon: 'pi pi-fw pi-plus',
                    command: () => handleNavigation('/dashboard/categories/add'),
                }
            ],
        },





        {
            label: 'Users',
            icon: 'pi pi-fw pi-users',
            items: [
                {
                    label: 'List Users',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/users'),
                },
                {
                    label: 'Add User',
                    icon: 'pi pi-fw pi-user-plus',
                    command: () => handleNavigation('/dashboard/users/add'),
                },
            ],
        },
        {
            label: 'Promotion',
            icon: 'pi pi-fw pi-percentage',
            items: [
                {
                    label: 'List Promotion',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/promotion'),
                },
                {
                    label: 'Ajouter un Promotion',
                    icon: 'pi pi-fw pi-plus',
                    command: () => handleNavigation('/dashboard/promotion/add'),
                }
            ],
        },
        {
            label: 'Promo Code',
            icon: 'pi pi-fw pi-gift',
            items: [
                {
                    label: 'List Promo Code',
                    icon: 'pi pi-fw pi-list',
                    command: () => handleNavigation('/dashboard/promocode'),
                },
                {
                    label: 'Ajouter un Code Promo',
                    icon: 'pi pi-fw pi-plus',
                    command: () => handleNavigation('/dashboard/promocode/add'),
                }
            ],
        },
        {
            label: 'Settings',
            icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: "Language",
                    icon: "pi pi-fw pi-language",
                    command: () => handleNavigation("/dashboard/setting/languages")
                },
                {
                    label: "Countries",
                    icon: "pi pi-fw pi-globe",
                    command: () => handleNavigation("/dashboard/setting/countries")
                },
                {
                    label: "Attributes",
                    icon: "pi pi-fw pi-list",
                    command: () => handleNavigation("/dashboard/setting/attributes")
                },
                {
                    label: "Social Media",
                    icon: "pi pi-fw pi-facebook",
                    command: () => handleNavigation("/dashboard/setting/socialMedia")
                },
                {
                    label: "Website info",
                    icon: "pi pi-fw pi-info-circle",
                    command: () => handleNavigation("/dashboard/setting/info")
                },
                {
                    label: "Slider",
                    icon: "pi pi-fw pi-images",
                    command: () => handleNavigation("/dashboard/setting/slider")
                }
            ]
        },
        {
            label: 'Logout',
            icon: 'pi pi-fw pi-sign-out',
            command: () => {
                logout()
            },
        },
    ];

    let logo = require('@resources/logo.png');
    return (
        <div className="sidebar-container">
            {/* <Button label='Menu' iconPos='right' icon="pi pi-arrow-right" onClick={() => setVisible(true)} className="absolute top-5 left-5 border-spacing-2 border-whitel" /> */}
            <Sidebar visible={visible} onHide={() => setVisible(false)}>

                <div>
                    <Link to="/dashboard/home" className="flex items-center justify-center mb-3">
                        <img alt="logo" src={logo} className="h-8 md:h-12 w-auto" />
                    </Link>
                </div>


                <PanelMenu model={menuItems} className="w-full" />
            </Sidebar>
        </div>
    );
};

export default AppSidebar;
