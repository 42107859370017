// ReferenceInput.tsx
import React from 'react';

interface ReferenceInputProps {
    reference: string;
    setReference: (value: string) => void;
    error?: string;
    touched?: boolean;
}

const ReferenceInput: React.FC<ReferenceInputProps> = ({
    reference,
    setReference,
    error,
    touched,
}) => {
    return (
        <div>
            <label className="block text-sm font-bold mb-2">Référence :</label>
            <input
                type="text"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="Entrez la référence"
            />
            {touched && error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
            )}
        </div>
    );
};

export default ReferenceInput;
