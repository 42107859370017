import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";
import { AddAttributePayload } from "@utils/interfaces/Attribute";


export const fetchAttributes = async (apiRequest: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/attributes.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Attributes Not Found !!');
        throw new Error('Erreur lors de la récupération de la Attributes');
    }
};


export const addAttribute = async (apiRequest: any, data: AddAttributePayload) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/attributes`,
            method: 'POST',
            data: data,
            // requiresAuth: true,
            // token: `${localStorage.getItem('userToken')}`,
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
        });

        notify.successNotify("Attribute create successfully");
    } catch (error) {
        notify.errorNotify("Erreur lors de l'ajout de l'attribut")
    }
};

export const getAttributeById = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/attributes/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Attribute Not Found !!');
        throw new Error('Erreur lors de la récupération de la Attribute');
    }
};


export const updateAttribute = async (apiRequest: any, id: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/attributes/${id}`,
            method: 'PUT',
            data: data,
        });

        notify.successNotify('Attribute mise à jour avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la mise à jour de la Attribute');
    }
};


export const deleteAttribute = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/attributes/${id}.json`,
            method: 'DELETE',
        });
        notify.successNotify('Attribute supprimée avec succès');
    } catch (error) {
        notify.errorNotify("Erreur lors de la suppression de l'Attribute");
        throw new Error("Erreur lors de la suppression de l'Attribute");
    }
};
