// src/components/orders/invoice/OnlineInvoiceDocument.tsx
import React, { useMemo } from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import { Order, OrderItem } from "@utils/interfaces/order";
import { styles } from "./styleinvoice";// Détails de facturation réutilisables
import { chunkItemsOrders } from "../helper";
import { InvoiceHeader } from "./components/InvoiceHeader";
import { InvoiceDetails } from "./components/InvoiceDetails";
import { InvoiceFooter } from "./components/InvoiceFooter";

const MAX_FIRST_PAGE = 17;
const MAX_OTHER_PAGES = 23;

const TableRow = React.memo(({ item }: { item: OrderItem }) => {
  const totalItem = item.quantity * item.price;
  const productLabel = item.product?.child?.[0]?.label || item.product.label;

  return (
    <View style={styles.tableRow}>
      <View style={{ width: "40%" }}>
        <Text style={styles.tableCell}>{productLabel}</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text style={styles.tableCell}>{item.quantity}</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text style={styles.tableCell}>{`${item.price.toFixed(2)} €`}</Text>
      </View>
      <View style={{ width: "20%" }}>
        <Text style={styles.tableCell}>{`${totalItem.toFixed(2)} €`}</Text>
      </View>
    </View>
  );
});




const OnlineInvoiceDocument: React.FC<{ order: Order }> = ({ order }) => {
  const pages = useMemo(
    () => chunkItemsOrders<OrderItem>(order.orderItems, MAX_FIRST_PAGE, MAX_OTHER_PAGES),
    [order.orderItems]
  );

  return (
    <Document>
      {pages.map((itemsChunk, pageIndex) => (
        <Page style={styles.page} key={`page_${pageIndex}`}>
          <InvoiceHeader />

          {pageIndex === 0 && (
            <InvoiceDetails
              reference={order.reference}
              date={new Date(order.updatedAt).toLocaleDateString()}
              totalDue={order.price}
              name={order.billingAddress?.fullName ?? ""}
              email={order.billingAddress?.email ?? ""}
              telephone={order.billingAddress?.telephone ?? ""}
              address={`${order.billingAddress?.address ?? ""}, ${order.billingAddress?.ville ?? ""} - ${order.billingAddress?.zipCode ?? ""}`}
            />
          )}

          <View style={styles.tableHeader}>
            <View style={{ width: "40%" }}>
              <Text style={styles.tableHeaderText}>PRODUCT</Text>
            </View>
            <View style={{ width: "20%" }}>
              <Text style={styles.tableHeaderText}>QTY</Text>
            </View>
            <View style={{ width: "20%" }}>
              <Text style={styles.tableHeaderText}>PRICE</Text>
            </View>
            <View style={{ width: "20%" }}>
              <Text style={styles.tableHeaderText}>TOTAL</Text>
            </View>
          </View>

          {itemsChunk.map((item) => (
            <TableRow key={item.id} item={item} />
          ))}

          <InvoiceFooter />
        </Page>
      ))}
    </Document>
  );
};

export default OnlineInvoiceDocument;
