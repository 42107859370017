// Définition des styles pour le document PDF
import {

    StyleSheet,
} from '@react-pdf/renderer';


const TAILWIND_GREEN = "#10B981";


export const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      padding: 40,
      paddingTop: 15,
      fontSize: 11,
      fontFamily: "Helvetica",
      position: "relative",
    },
    headerBar: {
      backgroundColor: "#7BF1A8",
      height: 35,
      marginBottom: 40,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    headerBarText: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: 1.2,
      textTransform: "uppercase",
    },
    logoContainer: {
      marginVertical: 15,
      alignItems: "center",
    },
    logo: {
      width: 110,
    },
    invoiceTitle: {
      fontSize: 14,
      fontWeight: "bold",
      color: TAILWIND_GREEN,
      marginBottom: 10,
      textTransform: "uppercase",
    },
    // Facturation / Totaux
    invoiceDetailsContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
      borderBottomWidth: 1,
      borderBottomColor: "#ccc",
      paddingBottom: 10,
    },
    invoiceLeft: {
      flex: 0.6,
    },
    labelValueRow: {
      flexDirection: "row",
      marginBottom: 2,
    },
    label: {
      width: 40,
      fontSize: 10,
      textAlign: "right",
      marginRight: 3,
      color: "#666",
    },
    value: {
      marginLeft: 5,
      fontSize: 10,
      flex: 1,
      color: "#000",
    },
    invoiceRight: {
      flex: 0.4,
      alignItems: "flex-end",
    },
    totalDueLabel: {
      fontSize: 11,
      fontWeight: "bold",
      color: TAILWIND_GREEN,
    },
    totalDueValue: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: 4,
      color: "#000",
    },
    invoiceNumberDate: {
      fontSize: 10,
      marginTop: 6,
      color: "#333",
    },
    // Tableau
    tableHeader: {
      marginTop: 10,
      flexDirection: "row",
      backgroundColor: "#7BF1A8",
      borderTopWidth: 1,
      borderColor: "#000",
    },
    tableHeaderText: {
      fontWeight: "bold",
      padding: 8,
      fontSize: 10,
      color: "#000",
      textAlign: "center",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: "#ddd",
    },
    tableCell: {
      padding: 6,
      fontSize: 10,
      textAlign: "center",
    },
    // Footer
    footer: {
      backgroundColor: "#7BF1A8",
      width: "100%",
      paddingVertical: 10,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "#fff",
      fontSize: 10,
    },
  });