import React, { ReactNode, useEffect, useMemo } from 'react';
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from '@hooks/LocalStorage';
import { Authenticate } from '@api/AuthApi';
import { authInitialValuesTypes } from '@utils/types/AuthTypes';
import { getUser } from '@api/UserApi';
import { Toast } from 'primereact/toast';
import { User } from '@utils/interfaces/User';

let initialValues: authInitialValuesTypes = {
    userToken: null,
    user: null,
    roles: [],
    login: async () => { },
    logout: () => { },
    toast: null,
    loading: true // Initialisez loading à true
}

let AuthContext = createContext(initialValues);

function AuthProvider({ children }: { children: ReactNode }) {

    let [userToken, setUserToken] = useLocalStorage('userToken', null);
    let [user, setUser] = React.useState<User | null>(null);
    let [roles, setRoles] = React.useState<string[]>([]);
    let [loading, setLoading] = React.useState<boolean>(true); // Initialisez loading à true
    let toast = React.useRef<Toast>(null);
    let navigate = useNavigate();

    let login = (body: any) => {
        setLoading(true);
        return Authenticate(body)
            .then((res: any) => {
                const {token} = res.data 
                localStorage.setItem("userToken2" , token)
                setUserToken(res.data.token);
                setLoading(false);
                 // Naviguez après la connexion réussie
            })
            .catch((err: any) => {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Email or password is invalid"
                });
                console.error("Authentication failed", { err });
                setLoading(false);
            });
    };

    let logout = () => {
        setUserToken(null);
        setUser(null);
        setRoles([]);
        navigate('/', { replace: true });
    };

    useEffect(() => {
        if (userToken) {
            setLoading(true);
            getUser(userToken)
                .then((res: any) => {
                    setUser(res.data);
                    const backendRoles = res.data.roles;
                    if (backendRoles && backendRoles.length > 0) {
                        setRoles(backendRoles);
                    } else {
                        setRoles([]);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.error("Failed to get user data", err);
                    logout();
                    setLoading(false);
                });
        } else {
            setUser(null);
            setRoles([]);
            setLoading(false);
        }
    }, [userToken]);

    let value = useMemo(() => ({
        userToken,
        user,
        roles,
        login,
        logout,
        toast,
        loading
    }), [user, userToken, roles, loading]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
