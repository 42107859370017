
import * as Yup from 'yup';

export const SocialMediaSchema = Yup.object({
    name: Yup.string()
        .required("Le nom de la plateforme est requis.") // Make name field required
        .min(3, "Le nom de la plateforme doit comporter au moins 3 caractères.") // Ensure at least 3 characters
        .max(50, "Le nom de la plateforme ne doit pas dépasser 50 caractères."), // Max length validation

    url: Yup.string()
        .required("url la platform est requis")
});
