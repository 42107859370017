import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Panel } from "primereact/panel";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl, apiUrlbase } from "@helpers/Helpers";
import HeaderCard from "@components/UI/Header/HeaderCard";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import LoadingSkeleton from "@components/setting/slider/LoadingSkeleton";

interface Slider {
    id: number;
    image: string;
    createdAt: string;
    updatedAt: string;
}

const SliderList: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const [filters, setFilters] = useState({ item_page: 10 });
    const [totalItems, setTotalItems] = useState(0);

    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(
        () => ({
            page: currentPage,
            items_per_page: filters.item_page,
        }),
        [filters, currentPage]
    );

    const fetchSliders = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/sliders.json`,
            method: "GET",
            params,
        });
        const rawSliders: Slider[] = response.data || [];
        setTotalItems(rawSliders.length);
        return rawSliders;
    }, [params, apiRequest]);

    const { data, isLoading, error } = useQuery({
        queryKey: ["sliders", params],
        queryFn: fetchSliders,
    });

    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prev) => ({
                    ...prev,
                    [key]: key === "item_page" ? parseInt(value, 10) : value,
                }));
                setPage(1);
            }, 500),
        [setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="sliders" />;

    return (
        <div className="pt-3">
            <HeaderCard
                title="Liste des Sliders"
                btnPath="/dashboard/setting/slider/add"
                btntext="Ajouter un Slider"
            />
            <Panel className="rounded-lg p-4 shadow-lg bg-white">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {isLoading ? (
                        [...Array(8)].map((_, index) => <LoadingSkeleton key={index} />)
                    ) : data && data.length > 0 ? (
                        data.map((slider) => <SliderCard key={slider.id} slider={slider} />)
                    ) : (
                        <div className="col-span-full text-center py-4 text-gray-500">
                            Aucun slider trouvé.
                        </div>
                    )}
                </div>
                <div className="mt-6">
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setPage} />
                </div>
            </Panel>
        </div>
    );
};

interface SliderCardProps {
    slider: Slider;
}

const SliderCard: React.FC<SliderCardProps> = ({ slider }) => {
    return (
        <Card className="shadow-md rounded-xl transition-transform transform hover:scale-105">
            <div className="flex flex-col items-center">
                {/* Image avec width et height fixes pour éviter les sauts de mise en page */}
                <div className="w-full max-w-[280px] h-40 overflow-hidden rounded-t-xl">
                    <Image
                        src={`${apiUrlbase}/${slider.image}`}
                        alt={`Slider ${slider.id}`}
                        preview
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="p-4 pb-0  w-full text-center">
                    <p className="text-xs text-gray-500">
                        <strong>Créé le :</strong> {new Date(slider.createdAt).toLocaleString()}
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default SliderList;
