import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAttributeById, updateAttribute } from '@utils/api/attribute';
import { notify } from '@mbs-dev/react-helpers';
import HeaderCard from '@components/UI/Header/HeaderCard';
import useApiRequest from '@mbs-dev/api-request';
import * as Yup from 'yup';
import LoadingData from '@components/Loading/LoadingData';
import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';

// Définir le type des données du formulaire
interface AttributeData {
    name: string;
}

interface AttributeResponse {
    id: number;
    name: string;
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Le nom de l'attribut est requis")
        .min(3, "Le nom de l'attribut doit comporter au moins 3 caractères")
        .max(50, "Le nom de l'attribut ne doit pas dépasser 50 caractères")
});

const AttributEdit: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { id } = useParams(); // Get the attribute ID from URL params

    // Fetch the existing attribute data
    const { data: attribute, isLoading, isError } = useQuery<AttributeResponse>({
        queryKey: ['attribute', id],
        queryFn: () => getAttributeById(apiRequest, id as string), // Fetch attribute by ID
        enabled: !!id // Only fetch if ID is available
    });

    // Mutation to update the attribute
    const mutation = useMutation({
        mutationFn: (data: AttributeData) => updateAttribute(apiRequest, id as string, data),
        onSuccess: () => {
            navigate('/dashboard/setting/attributes');
            queryClient.invalidateQueries({ queryKey: ['attributes'] });
        },
        onError: () => {
            notify.errorNotify("Erreur lors de la mise à jour de l'attribut");
        },
    });

    // Formik setup for editing the attribute
    const formik = useFormik<AttributeData>({
        initialValues: {
            name: attribute?.name || '', // Populate with fetched attribute data
        },
        validationSchema,
        enableReinitialize: true, // Reinitialize the form when attribute data changes
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    // Loading or error states
    if (isLoading) {
        return   <LoadingData />
    }

    if (isError || !attribute) {
        return <ErrorLoadingData entity={"Attributes"} />;
    }

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title="Modifier l'attribut" />

                <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                    <div>
                        <label className="block text-sm font-medium mb-2">Nom de l'attribut</label>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                            placeholder="Entrez le nom de l'attribut"
                        />
                        {formik.errors.name && formik.touched.name && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                        )}
                    </div>

                    <div className="flex justify-end space-x-4">
                        <Button type="submit" label="Enregistrer" icon="pi pi-check" className="p-button-success" />
                        <Button type="button" label="Annuler" icon="pi pi-times" className="p-button-secondary" onClick={() => navigate('/dashboard/setting/attributes')} />
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default AttributEdit;
