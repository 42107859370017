import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import { Order, SerializedOrder } from "@utils/interfaces/order";
import { orderColumns } from "@components/orders/Ordercolumns";
import LoadingTable from "@components/Loading/LoadingTable";
import { Button } from "primereact/button";
import { getPaymentMethodBadge, getStatusBadge, OrderStatus, PaymentMethod, PaymentStatus } from "@components/orders/helper";
import { Review, SerializedReview } from "@utils/interfaces/Review";
import { reviewColumns } from "@components/Reviews/Reviewscolumns";



// Interface pour les filtres
interface FilterReview {
    username: string,
    item_page: number,
}

const ReviewsList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // État des filtres
    const [filters, setFilters] = useState<FilterReview>({
        username: "",
        item_page: 10,
    });

    // Contrôle de l'affichage des filtres supplémentaires
    const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

    const [totalItems, setTotalItems] = useState(0);

    // Pagination
    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(
        () => ({
            page: currentPage,
            items_per_page: filters.item_page,
            ...(filters.username && { "user.username": filters.username }),

        }),
        [filters, currentPage]
    );

    const fetchFilteredReveiw = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/reviews.jsonld`,
            method: "GET",
            params,
            headers: {
                Accept: "application/ld+json",
            },
        });

        if (response.data && response.data["hydra:totalItems"]) {
            setTotalItems(response.data["hydra:totalItems"]);
        } else {
            setTotalItems(0);
        }

        const rawOrders = response.data?.["hydra:member"] || [];

        const serializedReview: SerializedReview[] = rawOrders.map((item: Review) => ({
            id: item.id,
            rating: item.rating ?? 0,
            status: item.status ?? false,
            message:item.message ?? "--" ,
            username: item.user.username ?? "N/A",
            product: item.product.child[0].label ?? "--",
            createdAt: item.createdAt
        }));

        return serializedReview;
    }, [params, apiRequest]);

    const { data, isLoading, error } = useQuery({
        queryKey: ["reviews", params],
        queryFn: fetchFilteredReveiw,
    });

    const table = useReactTable({
        data: data || [],
        columns: reviewColumns,
        getCoreRowModel: getCoreRowModel(),
    });

    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prev) => ({
                    ...prev,
                    [key]: key === "item_page" ? parseInt(value, 10) : value,
                }));
                setPage(1);
            }, 500),
        [setFilters, setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="orders" />;

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
            <h2 className="text-lg font-semibold text-green-700">Review List</h2>
        </div>
    );

    return (
        <div className="pt-3">
            <Panel headerTemplate={header} className="rounded-lg p-2">
                {/* Filtres */}
                {/* Filtres avec flex row */}
                <div className="flex flex-wrap items-center gap-3 p-4 border-b border-gray-300">
                    <input
                        type="text"
                        placeholder="user name"
                        defaultValue={filters.username}
                        onChange={(e) => setFilters({ ...filters, username: e.target.value })}
                        className="p-inputtext p-2 border rounded-md"
                    />

                </div>

                {/* Tableau */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="bg-green-400 text-white uppercase text-xs text-center leading-tight"
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        className="border-b hover:bg-green-50 transition ease-in-out text-sm"
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setPage}
                />
            </Panel>
        </div>
    );
};

export default ReviewsList;
