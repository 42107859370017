// src/components/products/add/CountryPrices.tsx

import React from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Button as PrimeButton } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import 'primeicons/primeicons.css';
import LoadingData from '@components/Loading/LoadingData';
import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';
import { fetchcountries } from '@utils/api/country';
import useApiRequest from '@mbs-dev/api-request';
import CountryFlag from '@components/Global/CountryFlag';

interface CountryPrice {
    country: string;
    price: number;
}

interface Country {
    id: number;
    name: string;
    code: string;
    currency: string;
}

interface CountryPricesProps {
    formik: FormikProps<any>;
}

const CountryPrices: React.FC<CountryPricesProps> = ({ formik }) => {
    const { apiRequest } = useApiRequest();
    const { data: countries, isLoading, error } = useQuery({
        queryKey: ['countries'],
        queryFn: () => fetchcountries(apiRequest)
    });

    if (isLoading) return <LoadingData />;
    if (error) return <ErrorLoadingData entity="Pays" />;

    // Ajouter une option par défaut pour le placeholder
    const countryOptions = [
        { label: 'Sélectionnez un pays', value: '' },
        ...countries.map((country: Country) => ({
            label: country.name,
            code:country.code , 
            value: country.id, // Utiliser 'code' pour correspondre à CountryFlag
        })),
    ];

    const itemTemplate = (option: any) => {
        if (!option.label) {
            return null;
        }
        return (
            <div className="flex items-center gap-2">
                <CountryFlag countryCode={option.code}  />
                <span>{option.label} - ({option.code})</span>
            </div>
        );
    };

    const selectedItemTemplate = (option: any) => {
        if (!option || !option.label) {
            return <span>Sélectionnez un pays</span>;
        }
        return (
            <div className="flex items-center gap-2">
                <CountryFlag countryCode={option.value}  />
                <span>{option.label}</span>
            </div>
        );
    };

    return (
        <div>
            <h3 className="text-lg font-bold mb-2">Prix par Pays : </h3>
            <FieldArray name="countryPrices">
                {({ remove, push }) => (
                    <div className="space-y-3">
                        {formik.values.countryPrices.map((price: CountryPrice, index: number) => (
                            <div
                                key={index}
                                className="flex flex-col md:flex-row items-center bg-white p-2 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
                            >
                                {/* Pays */}
                                <div className="flex-1 flex items-center mb-2 md:mb-0">
                                    <div className="w-full">
                                        <label htmlFor={`countryPrices[${index}].country`} className="block text-sm font-bold mb-1">
                                            Pays :
                                        </label>
                                        <Dropdown
                                            id={`countryPrices[${index}].country`}
                                            name={`countryPrices[${index}].country`}
                                            value={price.country}
                                            options={countryOptions}
                                            onChange={formik.handleChange}
                                            placeholder="Sélectionnez un pays"
                                            className={`w-full text-sm border-gray-300 `}
                                            itemTemplate={itemTemplate}
                                            valueTemplate={selectedItemTemplate}
                                            aria-required="true"
                                            // aria-invalid={formik.touched.countryPrices?.[index]?.country && Boolean(formik.errors.countryPrices?.[index]?.country)}
                                        />
                                        {/* {formik.touched.countryPrices?.[index]?.country && formik.errors.countryPrices?.[index]?.country && (
                                            <small className="text-red-500 text-xs">{formik.errors.countryPrices[index].country}</small>
                                        )} */}
                                    </div>
                                </div>

                                {/* Prix */}
                                <div className="flex-1 flex items-center mb-2 md:mb-0">
                                    <i className="pi pi-dollar text-green-500 text-2xl mr-2"></i>
                                    <div className="w-full">
                                        <label htmlFor={`countryPrices[${index}].price`} className="block text-sm font-bold mb-1">
                                            Prix :
                                        </label>
                                        <input
                                            type="number"
                                            id={`countryPrices[${index}].price`}
                                            name={`countryPrices[${index}].price`}
                                            value={price.price}
                                            onChange={formik.handleChange}
                                            placeholder="Entrez le prix"
                                            className={`w-full p-1 text-lg border rounded border-gray-300 `}
                                            aria-required="true"
                                            // aria-invalid={formik.touched.countryPrices?.[index]?.price && Boolean(formik.errors.countryPrices?.[index]?.price)}
                                        />
                                        {/* {formik.touched.countryPrices?.[index]?.price && formik.errors.countryPrices?.[index]?.price && (
                                            <small className="text-red-500 text-xs">{formik.errors.countryPrices[index].price}</small>
                                        )} */}
                                    </div>
                                </div>

                                {/* Bouton Supprimer */}
                                <div className="flex items-end">
                                    <PrimeButton
                                        type="button"
                                        icon="pi pi-trash"
                                        className="p-button-danger p-button-text"
                                        onClick={() => remove(index)}
                                        aria-label={`Supprimer le prix pour ${price.country}`}
                                    />
                                </div>
                            </div>
                        ))}

                        {/* Bouton Ajouter un Prix */}
                        <div className="flex justify-start">
                            <PrimeButton
                                type="button"
                                label="Ajouter un Prix"
                                icon="pi pi-plus"
                                onClick={() => push({ country: '', price: 0 })}
                                className="p-button-secondary text-xs"
                            />
                        </div>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default CountryPrices;
