import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Link } from "react-router-dom";
import imageNotFound from "@resources/imageNotFound.png";
import { apiUrlbase } from "@helpers/Helpers";

export const columns: ColumnDef<any>[] = [
    {
        header: "Image",
        accessorKey: "productTranslates[0].image",
        cell: ({ row }) =>
            row.original.productTranslates?.[0]?.image ? (
                <Image
                    src={`${apiUrlbase}/${row.original.productTranslates[0].image}`}
                    alt="Product"
                    width="100"
                    preview
                    downloadable={true}
                />
            ) : (
                <Image src={imageNotFound} alt="Image Not Found" width="100" />
            ),
    },
    {
        header: "Label",
        accessorKey: "label",
        cell: ({ row }) => <span className="text-sm">{row.original.label}</span>,
    },
    {
        header: "Stock",
        accessorKey: "stock",
        cell: ({ row }) => (
            <div className="flex items-center space-x-2">
                <span className="text-sm">{row.original.stock}</span>
            </div>
        ),
    },
    {
        header: "Category",
        accessorKey: "category.categoryTranslates[0].title",
        cell: ({ row }) => {
            const categoryTitle = row.original.category?.categoryTranslates?.[0]?.title;
            return categoryTitle ? (
                <Badge value={`${categoryTitle}`} severity="success" />
            ) : (
                <Badge value="No Category" severity="warning" />   
            );
        },
    },
    {
        header: "Variant",
        accessorKey: "nbrVariant",
        cell: ({ row }) => (
            <div className="flex items-center space-x-2">
                {row.original.nbrVariant > 0 ? (
                    <Badge value={`Multiple Variants (${row.original.nbrVariant})`} severity="info" />
                ) : ("---")}
            </div>
        ),
    },
    {
        header: "Actions",
        accessorKey: "actions",
        cell: ({ row }) => (
            <div className="flex space-x-1">
                <Link to={`/dashboard/products/details/${row.original.id}`}>
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-help p-button-sm"
                        tooltip="View Details"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
                <Link to={`/dashboard/products/edit/${row.original.id}`}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info p-button-sm"
                        tooltip="Edit"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
                <Link to={`/dashboard/products/delete/${row.original.id}`}>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-sm"
                        tooltip="Delete"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
            </div>
        ),
    },
];
