// src/components/orders/invoice/InvoiceFooter.tsx
import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../styleinvoice";

export const InvoiceFooter: React.FC = () => (
    <View style={styles.footer}>
        <Text>Argori - Argori@gmail.com - Tel: +123456789</Text>
        <Text>123 Rue Exemple, Ville, CodePostal</Text>
    </View>
);
