// src/components/products/add/helpers/StepContent.tsx

import React from 'react';
import { FormikProps } from 'formik';

import ProductTypeSelector from '../components/ProductTypeSelector';
import CategorySelector from '../components/CategorySelector';
import ParentProductSelector from '../components/ParentProductSelector';
import AvailabilityToggle from '../components/AvailabilityToggle';
import StockInput from '../components/StockInput';
import ReferenceInput from '../components/ReferenceInput';
import BarcodeInput from '../components/BarcodeInput';
import ProductTranslationFields from '../ProductTranslationFields';
import CountryPrices from '../components/CountryPrices';
import { ProductFormValues } from './buildProductFormData';
import { Button as PrimeButton } from 'primereact/button';
import ProductAttributes from '../components/ProductAttributes';
import CategoryContainer from '../components/CategoryContainer';
interface StepContentProps {
    label: string;
    productType: string;
    formik: FormikProps<ProductFormValues>;
    languages: any; // Remplacez par le type approprié
    parentCategoryOptions: Array<{ label: string; value: string }>;
    parentProductOptions: Array<{ label: string; value: string }>;
    parentProductVariantOptions: Array<{ label: string; value: string }>;
    setProductType: (value: string) => void;
}

const StepContent: React.FC<StepContentProps> = ({
    label,
    productType,
    formik,
    languages,
    parentCategoryOptions,
    parentProductOptions,
    parentProductVariantOptions,
    setProductType
}) => {
    switch (label) {
        case 'Informations Générales':
            return (
                <div className="space-y-4">
                    {/* Étape 1: Informations Générales */}
                    <ProductTypeSelector
                        productType={productType}
                        setProductType={(value: string) => {
                            formik.setFieldValue('type', value);
                            setProductType(value);
                            // Réinitialiser certains champs si nécessaire
                            if (value === 'parent' || value === 'variant') {
                                formik.setFieldValue('availability', true);
                                formik.setFieldValue('stock', 0);
                                formik.setFieldValue('reference', '');
                                formik.setFieldValue('barcode', '');
                            }
                        }}
                        options={[
                            { label: 'Parent', value: 'parent' },
                            { label: 'Variante', value: 'variant' },
                            { label: 'Produit Final Solo', value: 'final_solo' },
                            { label: 'Produit Final avec Parent', value: 'final_with_parent' },
                        ]}
                        error={formik.touched.type ? formik.errors.type : undefined}
                        touched={formik.touched.type}
                    />

                    {/* Champs conditionnels basés sur le type de produit */}

                    {(productType === 'parent' || productType === 'final_solo') && (
                        <CategoryContainer
                            formik={formik}
                            error={formik.touched.category ? formik.errors.category : undefined}
                            touched={formik.touched.category}
                        />
                    )}


                    {(productType === 'variant') && (
                        <ParentProductSelector
                            parentProduct={formik.values.parentProduct}
                            setParentProduct={(value: string) => formik.setFieldValue('parentProduct', value)}
                            options={parentProductVariantOptions}
                            error={formik.touched.parentProduct ? formik.errors.parentProduct : undefined}
                            touched={formik.touched.parentProduct}
                        />
                    )}

                    {(productType === 'final_with_parent') && (
                        <ParentProductSelector
                            parentProduct={formik.values.parentProduct}
                            setParentProduct={(value: string) => formik.setFieldValue('parentProduct', value)}
                            options={parentProductOptions}
                            error={formik.touched.parentProduct ? formik.errors.parentProduct : undefined}
                            touched={formik.touched.parentProduct}
                        />
                    )}
                </div>
            );
        case 'Disponibilité et Stock':
            return (
                <div className="space-y-4 mt-5">
                    {/* Étape 2: Disponibilité et Stock */}
                    {(productType === 'final_solo' || productType === 'final_with_parent') && (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* Disponibilité */}
                            <div className="flex items-center p-4 border border-gray-300 rounded-lg bg-white shadow-sm w-full min-w-[200px] max-w-[300px]">
                                <i className="pi pi-check-circle text-green-500 text-2xl mr-3"></i>
                                <AvailabilityToggle
                                    availability={formik.values.availability}
                                    setAvailability={(value: boolean) => formik.setFieldValue('availability', value)}
                                    error={formik.touched.availability ? formik.errors.availability : undefined}
                                    touched={formik.touched.availability}
                                />
                            </div>

                            {/* Stock */}
                            <div className="flex items-center p-4 border border-gray-300 rounded-lg bg-white shadow-sm w-full min-w-[200px] max-w-[300px]">
                                <i className="pi pi-box text-blue-500 text-2xl mr-3"></i>
                                <StockInput
                                    stock={formik.values.stock}
                                    setStock={(value: number) => formik.setFieldValue('stock', value)}
                                    error={formik.touched.stock ? formik.errors.stock : undefined}
                                    touched={formik.touched.stock}
                                />
                            </div>

                            {/* Référence */}
                            <div className="flex items-center p-4 border border-gray-300 rounded-lg bg-white shadow-sm w-full min-w-[200px] max-w-[300px]">
                                <i className="pi pi-tag text-yellow-500 text-2xl mr-3"></i>
                                <ReferenceInput
                                    reference={formik.values.reference}
                                    setReference={(value: string) => formik.setFieldValue('reference', value)}
                                    error={formik.touched.reference ? formik.errors.reference : undefined}
                                    touched={formik.touched.reference}
                                />
                            </div>

                            {/* Barcode */}
                            <div className="flex items-center p-4 border border-gray-300 rounded-lg bg-white shadow-sm w-full min-w-[200px] max-w-[300px]">
                                <i className="pi pi-barcode text-purple-500 text-2xl mr-3"></i>
                                <BarcodeInput
                                    barcode={formik.values.barcode}
                                    setBarcode={(value: string) => formik.setFieldValue('barcode', value)}
                                    error={formik.touched.barcode ? formik.errors.barcode : undefined}
                                    touched={formik.touched.barcode}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );

        case 'Prix par Pays':
            return (
                <div className="space-y-4">
                    {/* Étape 4: Prix par Pays */}
                    <CountryPrices formik={formik} />
                </div>
            );
        case 'Traductions':
            return (
                <div className="space-y-4">
                    {/* Étape 3: Traductions */}
                    <ProductTranslationFields
                        formik={formik}
                        languages={languages}
                        advanced={productType === 'final_solo' || productType === 'final_with_parent'}
                    />
                </div>
            );

        case 'Attributs':
            return (
                <>
                    {productType === 'final_with_parent' ? (
                        <div className="space-y-4">
                            <ProductAttributes formik={formik} />
                        </div>
                    ) : null}
                </>
            );
        case 'Résumé':
            return (
                <div className="space-y-4">
                    {/* Étape 5: Résumé et Soumission */}
                    <div className="space-y-2">
                        <h3 className="text-lg font-semibold">Résumé du Produit</h3>
                        <div className="bg-gray-100 p-4 rounded-md overflow-auto max-h-60">
                            <dl>
                                <div className="flex justify-between">
                                    <dt>Type</dt>
                                    <dd>{formik.values.type}</dd>
                                </div>
                                {formik.values.category && (
                                    <div className="flex justify-between">
                                        <dt>Catégorie</dt>
                                        <dd>{parentCategoryOptions.find(option => option.value === formik.values.category)?.label || 'N/A'}</dd>
                                    </div>
                                )}
                                {formik.values.parentProduct && (
                                    <div className="flex justify-between">
                                        <dt>Produit Parent</dt>
                                        <dd>{parentProductOptions.find(option => option.value === formik.values.parentProduct)?.label || 'N/A'}</dd>
                                    </div>
                                )}
                                <div className="flex justify-between">
                                    <dt>Disponibilité</dt>
                                    <dd>{formik.values.availability ? 'Disponible' : 'Indisponible'}</dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt>Stock</dt>
                                    <dd>{formik.values.stock}</dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt>Référence</dt>
                                    <dd>{formik.values.reference || 'N/A'}</dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt>Barcode</dt>
                                    <dd>{formik.values.barcode || 'N/A'}</dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt>Prix par Pays</dt>
                                    <dd>
                                        {formik.values.countryPrices.map((price, index) => (
                                            <div key={index}>
                                                {price.country}: {price.price}€
                                            </div>
                                        ))}
                                    </dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt>Traductions</dt>
                                    <dd>
                                        {formik.values.productTranslates.map((translate, index) => (
                                            <div key={index}>
                                                {languages.find((lang: any) => lang.id === translate.language)?.code.toUpperCase()}: {translate.title}
                                            </div>
                                        ))}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <PrimeButton
                            type="submit"
                            onClick={() => formik.submitForm()}
                            label={formik.isSubmitting ? "Enregistrement..." : "Ajouter le Produit"}
                            icon={formik.isSubmitting ? "pi pi-spinner pi-spin" : "pi pi-check"}
                            className="p-button-success text-xs"
                            disabled={formik.isSubmitting}
                        />
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default StepContent;
