import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingData from '@components/Loading/LoadingData';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl } from '@helpers/Helpers';
import { useQuery } from '@tanstack/react-query';
import usePagination from '@hooks/usePagination';
import Pagination from '@components/pagination/Pagination';
import debounce from 'lodash.debounce';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import HeaderCard from '@components/UI/Header/HeaderCard';
import { Attribute } from '@utils/interfaces/Attribute';
import { SocialMedia } from '@utils/interfaces/SocialMedia';

interface FilterSocialMedia {
    name: string;
    item_page: number;
}


const SocialMediaList: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const [filters, setFilters] = useState<FilterSocialMedia>({
        name: '',
        item_page: 10,
    });

    const [totalItems, setTotalItems] = useState(0);

    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(() => {
        return {
            page: currentPage,
            'items_per_page': filters.item_page,
            ...(filters.name && { name: filters.name }),
        };
    }, [filters, currentPage]);

    const fetchSocialMedias = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/social_media.jsonld`,
            method: 'GET',
            params: params,
            headers: {
                Accept: 'application/ld+json',
            },
        });

        if (response.data && response.data['hydra:totalItems']) {
            setTotalItems(response.data['hydra:totalItems']);
        } else {
            setTotalItems(0);
        }

        return response.data ? response.data['hydra:member'] : [];
    }, [apiRequest, params]);

    const { data: socialMedia = [], isLoading, error, refetch } = useQuery<SocialMedia[]>({
        queryKey: ['SocialMedia', params],
        queryFn: fetchSocialMedias,
    });

    // Debounce the filter change handler
    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    [key]: value,
                }));
                setPage(1);
            }, 500),
        [setFilters, setPage]
    );


    const clearFilters = () => {
        setFilters({
            name: '',
            item_page: 10,
        });
    };




    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);



    return (
        <div className="container mx-auto p-4">
            <Card >
                {/* header */}
                <HeaderCard title="Social Media List" btnPath='/dashboard/setting/socialMedia/add' btntext='Add Social Media' />
                <div className="my-5 flex items-center space-x-2">
                    <InputText
                        onChange={(e) => debouncedHandleFilterChange('name', e.target.value)}
                        placeholder="Search Social Media..."
                        className="p-inputtext-sm w-1/3"
                    />
                    <Button label="Clear" onClick={clearFilters} className="p-button-sm p-button-outlined" />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-center">
                    {isLoading ? (
                        <LoadingData />
                    ) : (
                        socialMedia.length === 0 ? (
                            <div className="text-center text-lg text-gray-500">No Social Media found</div>
                        ) : (
                            socialMedia.map((item: SocialMedia) => (
                                <div key={item.id} className=" border-2 border-green-500 bg-green-50 p-4 rounded-lg shadow-md">
                                    <h3 className="text-lg font-semibold text-green-500 mb-2">{item.name}</h3>
                                    <p className='text-xs font-medium text-gray-500 mb-2'>{item.url}</p>
                                    <div className="flex space-x-4 mt-4">
                                        <Link
                                            to={`/dashboard/setting/socialMedia/edit/${item.id}`}
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            <Button
                                                label="Edit"
                                                icon="pi pi-pencil"
                                                className="p-button-sm p-button-outlined p-button-info px-3 py-2"

                                            />
                                        </Link>
                                        <Link
                                            to={`/dashboard/setting/socialMedia/delete/${item.id}`}
                                            className="text-red-600 hover:text-red-800 "
                                        >
                                            <Button
                                                label="Delete"
                                                icon="pi pi-trash"
                                                className="p-button-sm p-button-outlined p-button-danger px-3 py-2"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            ))
                        )

                    )}
                </div>
                <div className="mt-1 flex justify-center">
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setPage} />
                </div>
            </Card>

        </div>
    );
};

export default SocialMediaList;
