    import React, { useEffect } from 'react';
    import { useFormik, FormikProvider } from 'formik';
    import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
    import { Card } from 'primereact/card';
    import { Dropdown } from 'primereact/dropdown';
    import { Button as PrimeButton } from 'primereact/button';
    import { CategoriesAddSchema } from '@utils/schemas/CategoriesSchema';
    import { CategoriesIV } from '@utils/initValues/CategoriesIV';
    import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';
    import useApiRequest from '@mbs-dev/api-request';
    import { fetchLanguages, getGeo } from '@utils/api/Lang';
    import { fetchCategories } from '@utils/api/category';
    import CategoryTranslationFields from '@components/categories/add/CategoryTranslationFields';
    import FileUploadWithPreview from '@components/UI/Form/FileUploadWithPreview';
    import LoadingData from '@components/Loading/LoadingData';
    import { useNavigate } from 'react-router-dom';
    import { notify, slugGenerator } from '@mbs-dev/react-helpers';
    import { addCategory } from '@utils/api/category';

    interface Language {
        id: number;
        name: string;
        code: string;
    }

    interface Category {
        id: number;
        categoryTranslates: {
            title: string;
            language: {
                code: string;
            };
        }[];
    }

    interface CategoryFormData {
        image: File | null;
        banner: File | null;
        parent: string | null; // Nouvelle propriété pour la catégorie parent
        categoryTranslates: {
            title: string;
            language: string;
            description: string;
        }[];
    }

    const CreateCategoryForm: React.FC = () => {
        const { apiRequest } = useApiRequest();
        const navigate = useNavigate();
        const queryClient = useQueryClient();

        // Récupération des langues
        const { data: languages, isLoading: loadingLanguages, error: languagesError } = useQuery<Language[]>({
            queryKey: ['languages'],
            queryFn: () => fetchLanguages(apiRequest),
        });

        // Récupération des catégories pour le champ parent
        const { data: categories, isLoading: loadingCategories, error: categoriesError } = useQuery<Category[]>({
            queryKey: ['categories'],
            queryFn: () => fetchCategories(apiRequest),
        });

        // Mutation pour soumettre les données
        const mutation = useMutation({
            mutationFn: (data: FormData) => addCategory(apiRequest, data),
            onMutate: () => {
                formik.setSubmitting(true);
            },
            onSuccess: () => {
                navigate('/dashboard/categories');
                queryClient.invalidateQueries({ queryKey: ['categories'] });
                formik.setSubmitting(false);
            },
            onError: () => {
                formik.setSubmitting(false);
                notify.errorNotify("Error creating category. Check console for details.");
            },
        });

        const formik = useFormik<CategoryFormData>({
            initialValues: {
                ...CategoriesIV,
                parent: null, // Initialisation de la catégorie parent
            },
            validationSchema: CategoriesAddSchema,
            onSubmit: (values) => {
                const formData = new FormData();

                // Ajouter l'image et la bannière
                if (values.image) {
                    formData.append('image', values.image);
                }
                if (values.banner) {
                    formData.append('banner', values.banner);
                }

                // Ajouter les traductions
                values.categoryTranslates.forEach((translate, index) => {
                    formData.append(`categoryTranslates[${index}][title]`, translate.title);
                    formData.append(`categoryTranslates[${index}][slug]`, slugGenerator(translate.title));
                    formData.append(`categoryTranslates[${index}][language]`, `api/languages/${translate.language}`);
                    formData.append(`categoryTranslates[${index}][description]`, translate.description);
                });

                // Ajouter la catégorie parent
                if (values.parent) {
                    formData.append('parent', `${values.parent}`);
                }

                mutation.mutate(formData);
            },
        });

        const handleFileSelect = (setFieldValue: (field: string, value: any) => void, fieldName: string) => (file: File | null) => {
            setFieldValue(fieldName, file);
        };



        // Récupération des options pour le Dropdown des catégories parent
        const parentCategoryOptions = categories?.map((category) => {
            const translate = category.categoryTranslates.find((t) => t.language.code === 'en'); // Exemple : afficher en anglais
            return {
                label: translate ? translate.title : 'Untitled',
                value: category.id,
            };
        }) || [];


        useEffect(() => {

            console.log("taha");
            

            getGeo(apiRequest)

        }, [])

        // Gestion des erreurs pour les langues ou catégories
        if (languagesError) {
            return <ErrorLoadingData entity="languages" />;
        }
        if (categoriesError) {
            return <ErrorLoadingData entity="categories" />;
        }

        return (
            <FormikProvider value={formik}>
                <div className="max-w-4xl mx-auto my-4">
                    <Card className="shadow-lg border-round-lg">
                        <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                            <h2 className="text-xl font-semibold">Create Category</h2>
                        </div>

                        <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border border-green-200 rounded-bl-lg rounded-br-lg" >
                            <div className="flex flex-col gap-4 md:flex-row">
                                <div className="md:w-1/2 w-full">
                                    <label className="block text-sm font-medium mb-2">Category Image :</label>
                                    <FileUploadWithPreview
                                        label="Choose Category Image"
                                        onFileSelect={handleFileSelect(formik.setFieldValue, "image")}
                                    />
                                    {formik.touched.image && formik.errors.image && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.image}</p>
                                    )}
                                </div>

                                <div className="md:w-1/2 w-full">
                                    <label className="block text-sm font-medium mb-2">Banner Image :</label>
                                    <FileUploadWithPreview
                                        label="Choose Banner Image"
                                        onFileSelect={handleFileSelect(formik.setFieldValue, "banner")}
                                    />
                                    {formik.touched.banner && formik.errors.banner && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.banner}</p>
                                    )}
                                </div>
                            </div>

                            {/* Sélection de la catégorie parent */}
                            <div>
                                <label className="block text-sm font-medium mb-2">Parent Category :</label>
                                {loadingCategories ? (
                                    <LoadingData />
                                ) : (
                                    <Dropdown
                                        value={formik.values.parent}
                                        options={parentCategoryOptions}
                                        onChange={(e) => formik.setFieldValue('parent', e.value)}
                                        placeholder="Select a Parent Category"
                                        className="w-full"
                                    />
                                )}
                            </div>

                            {/* Traductions */}
                            <div className="mt-6">
                                {loadingLanguages ? (
                                    <LoadingData />
                                ) : (
                                    <CategoryTranslationFields
                                        formik={formik}
                                        languages={languages || []}
                                    />
                                )}
                            </div>

                            <div className="flex justify-center">
                                <PrimeButton
                                    type='submit'
                                    label={formik.isSubmitting ? "Creating..." : "Create Category"}
                                    icon={formik.isSubmitting ? "pi pi-spinner pi-spin" : "pi pi-check"}
                                    className="p-button-success text-xs"
                                    disabled={formik.isSubmitting}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </FormikProvider>
        );
    };

    export default CreateCategoryForm;
