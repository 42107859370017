import React from 'react';
import { FieldArray } from 'formik';
import { Button as PrimeButton } from 'primereact/button';
import { Card } from 'primereact/card';
import { Language } from '@utils/interfaces/language';
import { Dropdown } from 'primereact/dropdown';
import FileUploadWithPreview from '@components/products/add/FileUploadWithPreview';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer les styles de React Quill

interface Translation {
    language: number; // ID de la langue
    title: string;
    description?: string;
    howToUse?: string;
    ingredients?: string;
    image: File | null;
    productImages: (File | null)[];
}

interface ProductTranslationFieldsProps {
    formik: any;
    languages: Language[];
    advanced?: boolean; // Indique si les champs avancés doivent être affichés
}

const ProductTranslationFields: React.FC<ProductTranslationFieldsProps> = ({ formik, languages, advanced = false }) => {
    // Fonction pour obtenir les langues déjà sélectionnées, excluant l'index actuel
    const getAvailableLanguages = (currentIndex: number) => {
        const selectedLanguages = formik.values.productTranslates
            .map((t: Translation, index: number) => index !== currentIndex ? t.language : null)
            .filter((lang: any) => lang !== null);
        return languages.filter(lang => !selectedLanguages.includes(lang.id));
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <FieldArray name="productTranslates">
            {({ remove, push }) => (
                <div className="space-y-4">
                    {formik.values.productTranslates.map((translate: Translation, index: number) => (
                        <Card key={index} className="shadow-lg p-4 border rounded-lg">
                            <div className="flex justify-between items-center mb-4">
                                <h4 className="text-lg font-bold">Traduction #{index + 1}</h4>
                                <PrimeButton
                                    type="button"
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-text"
                                    onClick={() => remove(index)}
                                    aria-label={`Supprimer la traduction ${index + 1}`}
                                />
                            </div>

                            <div className="space-y-2">
                                {/* Langue */}
                                <div>
                                    <label className="block text-sm font-bold mb-1">Langue :</label>
                                    <Dropdown
                                        name={`productTranslates[${index}].language`}
                                        value={translate.language}
                                        options={getAvailableLanguages(index).map(lang => ({ label: lang.name, value: lang.id }))}
                                        onChange={formik.handleChange}
                                        placeholder="Sélectionnez une langue"
                                        className={`w-full text-sm border-gray-300 ${formik.touched.productTranslates?.[index]?.language && formik.errors.productTranslates?.[index]?.language ? 'p-invalid border-red-500' : ''}`}
                                        aria-required="true"
                                        aria-invalid={formik.touched.productTranslates?.[index]?.language && Boolean(formik.errors.productTranslates?.[index]?.language)}
                                    />
                                    {formik.touched.productTranslates?.[index]?.language && formik.errors.productTranslates?.[index]?.language && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].language}</p>
                                    )}
                                </div>

                                {/* Titre */}
                                <div>
                                    <label className="block text-sm font-bold mb-1">Titre :</label>
                                    <input
                                        type="text"
                                        name={`productTranslates[${index}].title`}
                                        value={translate.title}
                                        onChange={formik.handleChange}
                                        className={`w-full p-2 text-sm border rounded ${formik.touched.productTranslates?.[index]?.title && formik.errors.productTranslates?.[index]?.title ? 'p-invalid border-red-500' : 'border-gray-300'}`}
                                        placeholder="Entrez le titre"
                                        aria-required="true"
                                        aria-invalid={formik.touched.productTranslates?.[index]?.title && Boolean(formik.errors.productTranslates?.[index]?.title)}
                                    />
                                    {formik.touched.productTranslates?.[index]?.title && formik.errors.productTranslates?.[index]?.title && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].title}</p>
                                    )}
                                </div>

                                {advanced && (
                                    <>
                                        {/* Description */}
                                        <div>
                                            <label className="block text-sm font-bold mb-1">Description :</label>
                                            <ReactQuill
                                                theme="snow"
                                                value={translate.description}
                                                onChange={(value) => formik.setFieldValue(`productTranslates[${index}].description`, value)}
                                                placeholder="Entrez la description"
                                                className={`w-full text-sm border-gray-300 ${formik.touched.productTranslates?.[index]?.description && formik.errors.productTranslates?.[index]?.description ? 'p-invalid border-red-500' : 'border-gray-300'}`}
                                                onKeyDown={handleKeyDown}
                                            />
                                            {formik.touched.productTranslates?.[index]?.description && formik.errors.productTranslates?.[index]?.description && (
                                                <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].description}</p>
                                            )}
                                        </div>

                                        {/* Comment Utiliser */}
                                        <div>
                                            <label className="block text-sm font-bold mb-1">Comment Utiliser :</label>
                                            <ReactQuill
                                                theme="snow"
                                                value={translate.howToUse}
                                                onChange={(value) => formik.setFieldValue(`productTranslates[${index}].howToUse`, value)}
                                                placeholder="Entrez les instructions d'utilisation"
                                                className={`w-full text-sm border-gray-300 ${formik.touched.productTranslates?.[index]?.howToUse && formik.errors.productTranslates?.[index]?.howToUse ? 'p-invalid border-red-500' : 'border-gray-300'}`}
                                                onKeyDown={handleKeyDown}
                                            />
                                            {formik.touched.productTranslates?.[index]?.howToUse && formik.errors.productTranslates?.[index]?.howToUse && (
                                                <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].howToUse}</p>
                                            )}
                                        </div>

                                        {/* Ingrédients */}
                                        <div>
                                            <label className="block text-sm font-bold mb-1">Ingrédients :</label>
                                            <ReactQuill
                                                theme="snow"
                                                value={translate.ingredients}
                                                onChange={(value) => formik.setFieldValue(`productTranslates[${index}].ingredients`, value)}
                                                placeholder="Entrez les ingrédients"
                                                className={`w-full text-sm border-gray-300 ${formik.touched.productTranslates?.[index]?.ingredients && formik.errors.productTranslates?.[index]?.ingredients ? 'p-invalid border-red-500' : 'border-gray-300'}`}
                                                onKeyDown={handleKeyDown}
                                            />
                                            {formik.touched.productTranslates?.[index]?.ingredients && formik.errors.productTranslates?.[index]?.ingredients && (
                                                <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].ingredients}</p>
                                            )}
                                        </div>

                                        {/* Upload d'images principales */}
                                        <div>
                                            <label className="block text-sm font-bold mb-1">Image Principale :</label>
                                            <FileUploadWithPreview
                                                label="Choisir une image principale"
                                                multiple={false}
                                                onFileSelect={(files) => formik.setFieldValue(`productTranslates[${index}].image`, files ? files[0] : null)}
                                            />
                                            {formik.touched.productTranslates?.[index]?.image && formik.errors.productTranslates?.[index]?.image && (
                                                <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].image}</p>
                                            )}
                                        </div>

                                        {/* Upload d'images secondaires */}
                                        <div>
                                            <label className="block text-sm font-bold mb-1">Images Secondaires :</label>
                                            <FileUploadWithPreview
                                                label="Choisir des images secondaires"
                                                multiple
                                                onFileSelect={(files) => formik.setFieldValue(`productTranslates[${index}].productImages`, files)}
                                            />
                                            {formik.touched.productTranslates?.[index]?.productImages && formik.errors.productTranslates?.[index]?.productImages && (
                                                <p className="text-red-500 text-xs mt-1">{formik.errors.productTranslates[index].productImages}</p>
                                            )}
                                        </div>
                                    </>
                                )}


                            </div>
                        </Card>
                    ))}
                    {/* Bouton pour ajouter une traduction */}
                    <div className="flex justify-start">
                        <PrimeButton
                            type="button"
                            label="Ajouter une Traduction"
                            icon="pi pi-plus"
                            onClick={() => push({ language: '', title: '', description: '', howToUse: '', ingredients: '', image: null, productImages: [] })}
                            className="p-button-secondary text-xs"
                        />
                    </div>
                </div>
            )}
        </FieldArray>
    );
};

export default ProductTranslationFields;