import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import SocialMediaDelete from '@components/setting/socialMedia/SocialMediaDelete';

const SocialMediaDeletePage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Function to handle cancel action
    const handleCancel = () => {
        navigate('/dashboard/setting/socialMedia');  // Navigate to the attributes list or previous page
    };

    return (
        <div className="max-w-lg mx-auto my-6">
            <Card className="shadow-lg p-6 border-round-lg">
                <h2 className="text-2xl font-semibold text-center text-red-600 mb-6">Supprimer la Plateforme</h2>
                <p className="text-center text-sm text-gray-600 mb-6">
                    Vous êtes sur le point de supprimer cet attribut. Cette action est irréversible.
                    Assurez-vous que vous souhaitez vraiment continuer.
                </p>

                <div className="flex justify-center space-x-6">
                    <Button
                        label="Annuler"
                        icon="pi pi-times"
                        className="p-button-secondary p-button-text"
                        onClick={handleCancel}
                    />
                    <SocialMediaDelete id={id} />
                </div>
            </Card>
        </div>
    );
};

export default SocialMediaDeletePage;
