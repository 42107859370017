import React from 'react';
import { Dropdown } from 'primereact/dropdown';

interface CategorySelectorProps {
    category: string;
    setCategory: (value: string) => void;
    options: { label: string; value: string }[];
    error?: string;
    touched?: boolean;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
    category,
    setCategory,
    options,
    error,
    touched,
}) => {
    return (
        <div>
            <label className="block text-sm font-extrabold mb-2">Catégorie :</label>
            <Dropdown
                value={category}
                options={options}
                onChange={(e) => setCategory(e.value)}
                placeholder="Sélectionnez une catégorie"
                className="w-full"
            />
            {touched && error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
            )}
        </div>
    );
};

export default CategorySelector;
