import React from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addAttribute } from '@utils/api/attribute'; // Assuming this is the function to add attribute
import { notify } from '@mbs-dev/react-helpers';
import HeaderCard from '@components/UI/Header/HeaderCard'; // Assuming a reusable header component
import useApiRequest from '@mbs-dev/api-request';
import { AttributesSchema } from '@utils/schemas/AttributesSchema';

// Définir le type des données du formulaire
interface AttributeData {
    name: string;
}


const AttributAdd: React.FC = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    const mutation = useMutation({
        mutationFn: (data: AttributeData) => addAttribute(apiRequest, data),
        onSuccess: () => {
            navigate('/dashboard/setting/attributes');
            queryClient.invalidateQueries({
                queryKey: ["attributes"]
            });
        },
        onError: () => {
            notify.errorNotify("Erreur lors de l'ajout de l'attribut");
        },
    });

    // Initialiser Formik avec validationSchema
    const formik = useFormik<AttributeData>({
        initialValues: { name: '' },
        validationSchema : AttributesSchema, // Pass the validation schema to Formik
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title="Ajouter un nouvel attribut" />

                <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                    <div>
                        <label className="block text-sm font-medium mb-2">Nom de l'attribut</label>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                            placeholder="Entrez le nom de l'attribut"
                        />
                        {formik.errors.name && formik.touched.name && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                        )}
                    </div>

                    <div className="flex justify-end space-x-4">
                        <Button type="submit" label="Ajouter" icon="pi pi-check" className="p-button-success" />
                        <Button type="button" label="Annuler" icon="pi pi-times" className="p-button-secondary" onClick={() => navigate('/dashboard/setting/attributes')} />
                    </div>
                </form>
            </Card>
        </div>
    );
}

export default AttributAdd;
