import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Panel } from "primereact/panel";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import LoadingTable from "@components/Loading/LoadingTable";
import { reviewColumns } from "@components/Reviews/Reviewscolumns";
import { Promotion, SerializedPromotion } from "@utils/interfaces/Promotion";
import { formatDate } from "@mbs-dev/react-helpers";
import { Promotioncolumns } from "@components/Promotion/Promotioncolumns";
import { formatDateOrders } from "@components/orders/helper";
import HeaderCard from "@components/UI/Header/HeaderCard";



// Interface pour les filtres
interface FilterPromotion {
    // username: string,
    item_page: number,
}

const PromotionList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // État des filtres
    const [filters, setFilters] = useState<FilterPromotion>({
        // username: "",
        item_page: 10,
    });


    const [totalItems, setTotalItems] = useState(0);

    // Pagination
    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(
        () => ({
            page: currentPage,
            items_per_page: filters.item_page,
            // ...(filters.username && { "user.username": filters.username }),

        }),
        [filters, currentPage]
    );

    const fetchFilteredPromotion = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/promotions.jsonld`,
            method: "GET",
            params,
            headers: {
                Accept: "application/ld+json",
            },
        });

        if (response.data && response.data["hydra:totalItems"]) {
            setTotalItems(response.data["hydra:totalItems"]);
        } else {
            setTotalItems(0);
        }

        const rawPromition = response.data?.["hydra:member"] || [];

        const serializedReview: SerializedPromotion[] = rawPromition.map((item: Promotion) => ({
            id: item.id,
            priceOrDiscount: item.price ? `${item.price} ${item.country.currency}` : `${item.discount}%`,
            startAt: formatDate(item.startAt),
            endAt: formatDate(item.endAt),
            product: item.product.map(pro => {
                return {
                    label: pro.child[0].label
                }
            }),
            status: item.status,
            country: item.country,
            createdAt: item.createdAt
        }));

        return serializedReview;
    }, [params, apiRequest]);

    const { data, isLoading, error } = useQuery({
        queryKey: ["promotion", params],
        queryFn: fetchFilteredPromotion,
    });

    const table = useReactTable({
        data: data || [],
        columns: Promotioncolumns,
        getCoreRowModel: getCoreRowModel(),
    });

    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prev) => ({
                    ...prev,
                    [key]: key === "item_page" ? parseInt(value, 10) : value,
                }));
                setPage(1);
            }, 500),
        [setFilters, setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="orders" />;

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
            <h2 className="text-lg font-semibold text-green-700">Promition List</h2>
        </div>
    );

    return (
        <div className="pt-3">
            <HeaderCard title="Promotion List" btnPath='/dashboard/promotion/add' btntext='Add Promotion' />
            <Panel>
            {/* Filtres */}
            {/* Filtres avec flex row */}
            {/* <div className="flex flex-wrap items-center gap-3 p-4 border-b border-gray-300">
                    <input
                        type="text"
                        placeholder="user name"
                        defaultValue={filters.username}
                        onChange={(e) => setFilters({ ...filters, username: e.target.value })}
                        className="p-inputtext p-2 border rounded-md"
                    />

                </div> */}

            {/* Tableau */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className="bg-green-400 text-white uppercase text-xs text-center leading-tight"
                            >
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {!isLoading ? (
                            table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id}
                                    className="border-b hover:bg-green-50 transition ease-in-out text-sm"
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className="py-2 px-4 text-left">
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <LoadingTable />
                        )}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setPage}
            />
        </Panel>
        </div >
    );
};

export default PromotionList;
