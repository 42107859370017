import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import CategorySelector from './CategorySelector';
import { fetchCategoriesParent, getCategory } from '@utils/api/category';
import useApiRequest from '@mbs-dev/api-request';
import { useQuery } from '@tanstack/react-query';

interface CategoryTranslate {
    id: number;
    title: string;
    slug: string;
    description: string;
    language: {
        id: number;
        name: string;
        code: string;
    };
}

interface Category {
    id: number;
    subCategory: Category[];
    image: string;
    banner: string;
    categoryTranslates: CategoryTranslate[];
    totalProducts: number;
}

interface CategoryContainerProps {
    error?: string;
    touched?: boolean;
    formik: any;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({ formik, error, touched }) => {
    const { apiRequest } = useApiRequest();

    // Récupération des catégories parent
    const {
        data: categories,
        isLoading: loadingCategories,
        error: categoriesError,
    } = useQuery({
        queryKey: ['categories'],
        queryFn: () => fetchCategoriesParent(apiRequest),
    });

    // États pour la catégorie et la sous-catégorie sélectionnées
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>(null);

    // Récupération des détails de la catégorie sélectionnée
    const {
        data: selectedCategory,
        isLoading: loadingCategory,
        error: categoryError,
    } = useQuery({
        queryKey: ['category', selectedCategoryId],
        queryFn: () => selectedCategoryId !== null && getCategory(apiRequest, Number(selectedCategoryId)),
        enabled: !!selectedCategoryId,
    });

    // Transformation des catégories pour le Dropdown (valeur en string)
    const categoryOptions = categories?.map((cat: Category) => ({
        label: cat.categoryTranslates[0]?.title || 'N/A',
        value: cat.id.toString(),
    })) || [];

    // Transformation des sous-catégories pour le Dropdown
    const subCategoryOptions =
        selectedCategory?.subCategory?.map((sub: Category) => ({
            label: sub.categoryTranslates[0]?.title || 'N/A',
            value: sub.id.toString(),
        })) || [];

    useEffect(() => {
        if (selectedSubCategoryId) {
            formik.setFieldValue('subCategory', selectedSubCategoryId);
        }
    }, [selectedSubCategoryId]);

    useEffect(() => {
        if (selectedCategoryId) {
            formik.setFieldValue('category', selectedCategoryId);
        }
    }, [selectedCategoryId]);

    return (
        <div>
            {/* Sélection de la catégorie */}
            <CategorySelector
                category={selectedCategoryId || ''}
                setCategory={(value: string) => {
                    setSelectedCategoryId(value);
                    setSelectedSubCategoryId(null);
                }}
                options={categoryOptions}
                error={error}
                touched={touched}
            />

            {loadingCategories && <p>Chargement des catégories...</p>}
            {categoriesError && <p>Erreur lors du chargement des catégories.</p>}

            {/* Sélection de la sous-catégorie */}
            {loadingCategory && <p>Chargement de la catégorie...</p>}
            {categoryError && <p>Erreur lors du chargement de la catégorie.</p>}
            {selectedCategory &&
                selectedCategory.subCategory &&
                selectedCategory.subCategory.length > 0 && (
                    <div className="mt-4">
                        <label className="block text-sm font-extrabold mb-2">
                            Sous-catégorie :
                        </label>
                        <Dropdown
                            value={selectedSubCategoryId}
                            options={subCategoryOptions}
                            onChange={(e) => setSelectedSubCategoryId(e.value)}
                            placeholder="Sélectionnez une sous-catégorie"
                            className="w-full"
                        />
                    </div>
                )}
        </div>
    );
};

export default CategoryContainer;
