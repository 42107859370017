import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { getLanguage, updateLanguage } from '@utils/api/Lang';
import useApiRequest from '@mbs-dev/api-request';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { validationSchema } from '../../../utils/schemas/LanguageSchema';
import { notify } from '@mbs-dev/react-helpers';
import { LangIV } from '@utils/initValues/LanguageIV';
import LoadingTable from '@components/Loading/LoadingTable';
import LoadingData from '@components/Loading/LoadingData';
import HeaderCard from '@components/UI/Header/HeaderCard';

// Définir le type des données du formulaire
interface LanguageData {
    name: string;
    code: string;
}

const LanguageEdit: React.FC = () => {
    const queryClient = useQueryClient();
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const { data: languageData, isLoading } = useQuery({
        queryKey: ['language', id],
        queryFn: () => getLanguage(apiRequest, id)
    }
    );

    // Mutation pour mettre à jour la langue
    const mutation = useMutation({
        mutationFn: (data: LanguageData) => updateLanguage(apiRequest, id, data),
        onSuccess: () => {
            navigate('/dashboard/setting/languages');
            queryClient.invalidateQueries({
                queryKey: ['languages']
            });
        },
        onError: (error: Error) => {
            notify.errorNotify('Erreur lors de la mise à jour de la langue');
        },
    });


    const formik = useFormik<LanguageData>({
        initialValues: LangIV,
        validationSchema,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
        enableReinitialize: true,
    });


    useEffect(() => {
        if (languageData) {
            formik.setValues({
                name: languageData.name || '',
                code: languageData.code || '',
            });
        }
    }, [languageData]);

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title="Modifier la langue" />

                {isLoading ? (
                    <LoadingData />
                ) : (
                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                        <div>
                            <label className="block text-sm font-medium mb-2">Nom</label>
                            <InputText
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                                placeholder="Entrez le nom de la langue"
                            />
                            {formik.errors.name && formik.touched.name && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                            )}
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-2">Code</label>
                            <InputText
                                name="code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                className={`p-inputtext w-full ${formik.errors.code && formik.touched.code ? 'p-invalid' : ''}`}
                                placeholder="Entrez le code de la langue (2 lettres)"
                            />
                            {formik.errors.code && formik.touched.code && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.code}</p>
                            )}
                        </div>

                        <div className="flex justify-end">
                            <Button
                                label="Mettre à jour"
                                icon="pi pi-check"
                                className="p-button-success"
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            </Card>
        </div>
    );
};

export default LanguageEdit;
