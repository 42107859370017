import React from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notify } from '@mbs-dev/react-helpers';
import HeaderCard from '@components/UI/Header/HeaderCard'; // Assuming a reusable header component
import useApiRequest from '@mbs-dev/api-request';
import { SocialMediaSchema } from '@utils/schemas/SocialMediaSchema';
import { addSocialMedia } from '@utils/api/socialMedia';

// Définir le type des données du formulaire
interface SocialMediaData {
    name: string;
    url: string;
}


const SocialMediaAdd: React.FC = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    const mutation = useMutation({
        mutationFn: (data: SocialMediaData) => addSocialMedia(apiRequest, data),
        onSuccess: () => {
            navigate('/dashboard/setting/socialMedia');
            queryClient.invalidateQueries({
                queryKey: ["SocialMedia"]
            });
        },
        onError: () => {
            notify.errorNotify("Erreur lors de l'ajout du Social Media");
        },
    });


    const formik = useFormik<SocialMediaData>({
        initialValues: { name: '', url: "" },
        validationSchema: SocialMediaSchema,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title="Ajouter une nouvelle plateforme" />

                <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                    <div>
                        <label className="block text-sm font-medium mb-2">Nom du Plateforme</label>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                            placeholder="Entrez le nom "
                        />
                        {formik.errors.name && formik.touched.name && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">Url</label>
                        <InputText
                            name="url"
                            value={formik.values.url}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.url && formik.touched.url ? 'p-invalid' : ''}`}
                            placeholder="Entrez le url"
                        />
                        {formik.errors.url && formik.touched.url && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.url}</p>
                        )}
                    </div>

                    <div className="flex justify-end space-x-4">
                        <Button type="submit" label="Ajouter" icon="pi pi-check" className="p-button-success" />
                        <Button type="button" label="Annuler" icon="pi pi-times" className="p-button-secondary" onClick={() => navigate('/dashboard/setting/socialMedia')} />
                    </div>
                </form>
            </Card>
        </div>
    );
}

export default SocialMediaAdd;
