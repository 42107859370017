import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import { Order, SerializedOrder } from "@utils/interfaces/order";
import { orderColumns } from "@components/orders/Ordercolumns";
import LoadingTable from "@components/Loading/LoadingTable";
import { Button } from "primereact/button";
import { getPaymentMethodBadge, getStatusBadge, OrderStatus, PaymentMethod, PaymentStatus } from "@components/orders/helper";


// Options pour le filtre des statuts
// **Options pour les filtres**
const orderStatusOptions = Object.values(OrderStatus).map((status) => ({
    ...getStatusBadge("order", status),
    value: status,
}));

const paymentStatusOptions = Object.values(PaymentStatus).map((status) => ({
    ...getStatusBadge("payment", status),
    value: status,
}));

const paymentMethodOptions = Object.values(PaymentMethod).map((method) => ({
    ...getStatusBadge("method" , method) ,
    value: method,
}));


// Interface pour les filtres
interface FilterOrders {
    reference: string;
    username: string;
    item_page: number;
    orderStatus: string;
    paymentMethod: string;
    paymentStatus: string;
}

const OrdersList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // État des filtres
    const [filters, setFilters] = useState<FilterOrders>({
        reference: "",
        username: "",
        item_page: 10,
        orderStatus: "",
        paymentMethod: "",
        paymentStatus: "",
    });

    // Contrôle de l'affichage des filtres supplémentaires
    const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

    const [totalItems, setTotalItems] = useState(0);

    // Pagination
    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(
        () => ({
            page: currentPage,
            items_per_page: filters.item_page,
            ...(filters.reference && { reference: filters.reference }),
            ...(filters.username && { "user.username": filters.username }),
            ...(filters.orderStatus && { orderStatus: filters.orderStatus }),
            ...(filters.paymentMethod && { paymentMethod: filters.paymentMethod }),
            ...(filters.paymentStatus && { paymentStatus: filters.paymentStatus }),
        }),
        [filters, currentPage]
    );

    const fetchFilteredOrders = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/orders.jsonld`,
            method: "GET",
            params,
            headers: {
                Accept: "application/ld+json",
            },
        });

        if (response.data && response.data["hydra:totalItems"]) {
            setTotalItems(response.data["hydra:totalItems"]);
        } else {
            setTotalItems(0);
        }

        const rawOrders = response.data?.["hydra:member"] || [];

        const serializedOrders: SerializedOrder[] = rawOrders.map((order: Order) => ({
            id: order.id,
            reference: order.reference,
            userFullName: order.user?.fullName || "N/A",
            deliveryAddress: `${order.deliveryAddress?.address ?? ""} - ${order.deliveryAddress?.ville ?? ""} - ${order.deliveryAddress?.zipCode ?? ""}`,
            price: order.price ?? 0,
            itemsQuantity: order.itemsQuantity ?? 0,
            orderStatus: order.orderStatus ?? "PENDING",
            paymentMethod: order.paymentMethod ?? "---",
            paymentStatus: order.paymentStatus ?? "PENDING",
            comment: order.comment ? `${order.comment.slice(0, 30)}...` : "---",
        }));

        return serializedOrders;
    }, [params, apiRequest]);

    const { data, isLoading, error } = useQuery({
        queryKey: ["orders", params],
        queryFn: fetchFilteredOrders,
    });

    const table = useReactTable({
        data: data || [],
        columns: orderColumns,
        getCoreRowModel: getCoreRowModel(),
    });

    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prev) => ({
                    ...prev,
                    [key]: key === "item_page" ? parseInt(value, 10) : value,
                }));
                setPage(1);
            }, 500),
        [setFilters, setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="orders" />;

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
            <h2 className="text-lg font-semibold text-green-700">Orders List</h2>
            <Button
                label="Offline Order"
                icon="pi pi-receipt"
                className="p-button-sm p-button-outlined"
                onClick={() => navigate("/dashboard/ordersOffline")}
            />
        </div>
    );

    return (
        <div className="pt-3">
            <Panel headerTemplate={header} className="rounded-lg p-2">
                {/* Filtres */}
                {/* Filtres avec flex row */}
                <div className="flex flex-wrap items-center gap-3 p-4 border-b border-gray-300">
                    <input
                        type="text"
                        placeholder="Référence"
                        defaultValue={filters.reference}
                        onChange={(e) => setFilters({ ...filters, reference: e.target.value })}
                        className="p-inputtext p-2 border rounded-md"
                    />
                    <input
                        type="text"
                        placeholder="Username"
                        defaultValue={filters.username}
                        onChange={(e) => setFilters({ ...filters, username: e.target.value })}
                        className="p-inputtext p-2 border rounded-md"
                    />
                    <Dropdown
                        value={filters.orderStatus}
                        options={orderStatusOptions}
                        onChange={(e) => setFilters({ ...filters, orderStatus: e.value })}
                        placeholder="Statut commande"
                        className="w-48"
                        itemTemplate={(option) => (
                            <div className={`flex items-center gap-2 px-2 py-1 rounded-md text-white ${option.color}`}>
                                <i className={`${option.icon} text-sm`}></i>
                                <span>{option.label}</span>
                            </div>
                        )}
                    />
                    <Dropdown
                        value={filters.paymentMethod}
                        options={paymentMethodOptions}
                        onChange={(e) => setFilters({ ...filters, paymentMethod: e.value })}
                        placeholder="Méthode paiement"
                        className="w-48"
                        itemTemplate={(option) => (
                            <div className={`flex items-center gap-2 px-2 py-1 rounded-md text-white ${option.color}`}>
                                <i className={`${option.icon} text-sm`}></i>
                                <span>{option.label}</span>
                            </div>
                        )}
                    />
                    <Dropdown
                        value={filters.paymentStatus}
                        options={paymentStatusOptions}
                        onChange={(e) => setFilters({ ...filters, paymentStatus: e.value })}
                        placeholder="Statut paiement"
                        className="w-48"
                        itemTemplate={(option) => (
                            <div className={`flex items-center gap-2 px-2 py-1 rounded-md text-white ${option.color}`}>
                                <i className={`${option.icon} text-sm`}></i>
                                <span>{option.label}</span>
                            </div>
                        )}
                    />
                </div>

                {/* Tableau */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="bg-green-400 text-white uppercase text-xs text-center leading-tight"
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        className="border-b hover:bg-green-50 transition ease-in-out text-sm"
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setPage}
                />
            </Panel>
        </div>
    );
};

export default OrdersList;
