// src/components/orders/invoice/InvoiceDetails.tsx
import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../styleinvoice";


type InvoiceDetailsProps = {
    reference: string;
    date: string;
    totalDue: number;
    // Ces champs peuvent être adaptés selon vos besoins :
    name: string;
    email: string;
    telephone: string;
    address: string;
};

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
    reference,
    date,
    totalDue,
    name,
    email,
    telephone,
    address,
}) => (
    <View style={styles.invoiceDetailsContainer}>
        <View style={styles.invoiceLeft}>
            <Text style={{ fontSize: 10, color: "#666", marginBottom: 4 }}>
                Adresse Du Facturation :
            </Text>
            <View style={styles.labelValueRow}>
                <Text style={styles.label}>Nom :</Text>
                <Text style={styles.value}>{name}</Text>
            </View>
            <View style={styles.labelValueRow}>
                <Text style={styles.label}>Email :</Text>
                <Text style={styles.value}>{email}</Text>
            </View>
            <View style={styles.labelValueRow}>
                <Text style={styles.label}>Tel :</Text>
                <Text style={styles.value}>{telephone}</Text>
            </View>
            <View style={styles.labelValueRow}>
                <Text style={styles.label}>Addr :</Text>
                <Text style={styles.value}>{address}</Text>
            </View>
        </View>
        <View style={styles.invoiceRight}>
            <Text style={styles.invoiceNumberDate}>{`No: ${reference}`}</Text>
            <Text style={styles.invoiceNumberDate}>{`Date: ${date}`}</Text>
            <Text style={{ ...styles.totalDueLabel, marginTop: 20 }}>TOTAL DUE</Text>
            <Text style={styles.totalDueValue}>{`${totalDue.toFixed(2)} €`}</Text>
        </View>
    </View>
);
