import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from '@mbs-dev/react-helpers';
import HeaderCard from '@components/UI/Header/HeaderCard';
import useApiRequest from '@mbs-dev/api-request';
import * as Yup from 'yup';
import LoadingData from '@components/Loading/LoadingData';
import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';
import { getWebsiteInfoById, updateWebsiteInfo } from '@utils/api/wesiteInfo';

// Définition du type des données du formulaire
interface WebsiteInfoData {
    primaryPhone: string;
    secondaryPhone: string;
    primaryEmail: string;
    secondaryEmail: string;
    address: string;
    workingTime: string;
}

interface WebsiteInfoResponse extends WebsiteInfoData {
    id: string;
}

// Schéma de validation avec Yup
const WebsiteInfoSchema = Yup.object().shape({
    primaryPhone: Yup.string().required("Le téléphone principal est requis"),
    secondaryPhone: Yup.string(),
    primaryEmail: Yup.string().email("Email invalide").required("L'email principal est requis"),
    secondaryEmail: Yup.string().email("Email invalide"),
    address: Yup.string().required("L'adresse est requise"),
    workingTime: Yup.string().required("Les horaires sont requis"),
});

const WebsiteInfoEdit: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { id } = useParams(); // Récupération de l'ID depuis l'URL

    // Récupération des données existantes
    const { data: websiteInfo, isLoading, isError } = useQuery<WebsiteInfoResponse>({
        queryKey: ['websiteInfo', id],
        queryFn: () => getWebsiteInfoById(apiRequest, id as string),
        enabled: !!id
    });

    // Mutation pour la mise à jour
    const mutation = useMutation({
        mutationFn: (data: WebsiteInfoData) => updateWebsiteInfo(apiRequest, id as string, data),
        onSuccess: () => {
            navigate('/dashboard/setting/info');
            queryClient.invalidateQueries({ queryKey: ['websiteInfo'] });
        },
        onError: () => {
            notify.errorNotify("Erreur lors de la mise à jour");
        },
    });

    // Initialisation de Formik pour le formulaire
    const formik = useFormik<WebsiteInfoData>({
        initialValues: {
            primaryPhone: websiteInfo?.primaryPhone || '',
            secondaryPhone: websiteInfo?.secondaryPhone || '',
            primaryEmail: websiteInfo?.primaryEmail || '',
            secondaryEmail: websiteInfo?.secondaryEmail || '',
            address: websiteInfo?.address || '',
            workingTime: websiteInfo?.workingTime || ''
        },
        validationSchema: WebsiteInfoSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    // Gestion des états de chargement et d'erreur
    if (isLoading) {
        return <LoadingData />;
    }

    if (isError || !websiteInfo) {
        return <ErrorLoadingData entity={"Website Info"} />;
    }

    return (
        <div className="max-w-lg mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title="Modifier les informations du site" />

                <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">

                    {/* Téléphone principal */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Téléphone principal</label>
                        <InputText
                            name="primaryPhone"
                            value={formik.values.primaryPhone}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.primaryPhone && formik.touched.primaryPhone ? 'p-invalid' : ''}`}
                            placeholder="Ex: +33 (0) 768 968 507"
                        />
                        {formik.errors.primaryPhone && formik.touched.primaryPhone && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.primaryPhone}</p>
                        )}
                    </div>

                    {/* Téléphone secondaire */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Téléphone secondaire</label>
                        <InputText
                            name="secondaryPhone"
                            value={formik.values.secondaryPhone}
                            onChange={formik.handleChange}
                            className="p-inputtext w-full"
                            placeholder="Ex: +33 (0) 695 839 120"
                        />
                    </div>

                    {/* Email principal */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Email principal</label>
                        <InputText
                            name="primaryEmail"
                            value={formik.values.primaryEmail}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.primaryEmail && formik.touched.primaryEmail ? 'p-invalid' : ''}`}
                            placeholder="Ex: info@argori.com"
                        />
                        {formik.errors.primaryEmail && formik.touched.primaryEmail && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.primaryEmail}</p>
                        )}
                    </div>

                    {/* Email secondaire */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Email secondaire</label>
                        <InputText
                            name="secondaryEmail"
                            value={formik.values.secondaryEmail}
                            onChange={formik.handleChange}
                            className="p-inputtext w-full"
                            placeholder="Ex: contact@argori.com"
                        />
                    </div>

                    {/* Adresse */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Adresse</label>
                        <InputText
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.address && formik.touched.address ? 'p-invalid' : ''}`}
                            placeholder="Ex: 29 B, rue Salvador Allende, 92220 Bagneux, Paris, France"
                        />
                        {formik.errors.address && formik.touched.address && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.address}</p>
                        )}
                    </div>

                    {/* Horaires */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Horaires</label>
                        <InputText
                            name="workingTime"
                            value={formik.values.workingTime}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.workingTime && formik.touched.workingTime ? 'p-invalid' : ''}`}
                            placeholder="Ex: Lundi-Vendredi 09:00 - 18:00, Samedi 9:00 - 12:00"
                        />
                        {formik.errors.workingTime && formik.touched.workingTime && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.workingTime}</p>
                        )}
                    </div>

                    {/* Boutons */}
                    <div className="flex justify-end space-x-4">
                        <Button type="submit" label="Enregistrer" icon="pi pi-check" className="p-button-success" />
                        <Button type="button" label="Annuler" icon="pi pi-times" className="p-button-secondary" onClick={() => navigate('/dashboard/setting/website-info')} />
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default WebsiteInfoEdit;
