// reviewColumns.tsx
import { ColumnDef } from '@tanstack/react-table';
import { Tag } from 'primereact/tag';
import { formatDateOrders } from '@components/orders/helper';
import { SerializedPromotion } from '@utils/interfaces/Promotion';
import CountryFlag from '@components/Global/CountryFlag';
import PromotionStatusDropdown from './PromotionStatusDropdown';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

export const Promotioncolumns: ColumnDef<SerializedPromotion>[] = [
    {
        header: 'Produits',
        accessorKey: 'product',
        cell: ({ row }: any) => {
            return (
                <div className="flex flex-wrap gap-2">
                    {row.original.product.map((item: any, index: number) => (
                        <span key={index} className="bg-green-200 text-green-800 px-3 py-1 rounded-full text-xs font-medium">
                            {item.label}
                        </span>
                    ))}
                </div>
            );
        },
    },
    {
        header: 'priceOrDiscount',
        accessorKey: 'priceOrDiscount',
        cell: ({ row }: any) => <span>{row.original.priceOrDiscount}</span>,
    },
    {
        header: 'startAt',
        accessorKey: 'startAt',
        cell: ({ row }: any) => <span>{row.original.startAt}</span>,
    },
    {
        header: 'endAt',
        accessorKey: 'endAt',
        cell: ({ row }: any) => <span>{row.original.endAt}</span>,
    },
    {
        header: "country",
        accessorKey: "country",
        cell: ({ row }: any) => (
            <div className="flex items-center gap-2">
                <CountryFlag countryCode={row.original.country.code} />
                <span>{row.original.country.name}</span>
            </div>
        ),
    },

    {
        header: "Statut",
        accessorKey: "status",
        cell: ({ row }: any) => (
            <PromotionStatusDropdown
                promotionId={row.original.id}
                currentStatus={row.original.status}
            />
        ),
    },
    {
        header: 'Date de création',
        accessorKey: 'createdAt',
        cell: ({ row }: any) => formatDateOrders(row.original.createdAt),
    },
    {
        header: 'Actions',
        id: 'actions',
        cell: ({ row }: any) => (
            <div  className="flex space-x-2" >
                <Link to={`delete/${row.original.id}`}>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-sm"
                        tooltip="Delete"
                        tooltipOptions={{ position: 'top' }}
                    />
                </Link>
            </div>
        ),
    },
];
