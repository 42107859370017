import React, { useState } from "react";
import { Tag } from "primereact/tag";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@mbs-dev/api-request";
import { notify } from "@mbs-dev/react-helpers";
import { apiUrl } from "@helpers/Helpers";

interface CodePromoStatusDropdownProps {
    promotionId: number;
    currentStatus: boolean;
}

const CodePromoStatusDropdown: React.FC<CodePromoStatusDropdownProps> = ({
    promotionId,
    currentStatus,
}) => {
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();
    const [status, setStatus] = useState<boolean>(currentStatus);
    const [showDropdown, setShowDropdown] = useState(false);

    const statusOptions = [
        { label: "✅ Active", value: true, className: "text-green-700 font-semibold" },
        { label: "⛔ Désactivée", value: false, className: "text-red-700 font-semibold" },
    ];

    const mutation = useMutation({
        mutationFn: async (newStatus: boolean) => {
            const response = await apiRequest({
                route: `${apiUrl}/promo_codes/${promotionId}`,
                method: "PUT",
                data: { status: newStatus ? 1 : 0 },
            });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["promotion"] });
            notify.successNotify("Le statut a été mis à jour avec succès.");
        },
        onError: () => {
            notify.errorNotify("Erreur lors de la mise à jour du statut.");
        },
    });

    const handleStatusChange = (newStatus: boolean) => {
        if (newStatus === status) {
            notify.infoNotify("Le statut est déjà à jour.");
            return;
        }
        setStatus(newStatus);
        mutation.mutate(newStatus);
        setShowDropdown(false); // Fermer le dropdown après sélection
    };

    return (
        <div className="relative">
            {/* Badge cliquable */}
            <div
                className={`cursor-pointer px-3 py-1 text-xs rounded-md shadow-sm ${status ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"
                    }`}
                onClick={() => setShowDropdown(!showDropdown)}
            >
                {status ? "🟢 Active" : "🔴 Désactivée"}
            </div>

            {/* Dropdown affiché au clic */}
            {showDropdown && (
                <div className="absolute mt-2 bg-white border shadow-md rounded-md w-40 z-10">
                    {statusOptions.map((option) => (
                        <div
                            key={option.value.toString()}
                            className={`p-2 cursor-pointer hover:bg-gray-100 ${option.className}`}
                            onClick={() => handleStatusChange(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CodePromoStatusDropdown;
