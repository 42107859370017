// src/components/orders/invoice/InvoiceHeader.tsx
import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../styleinvoice";


const logo = require("@resources/logo.png");

export const InvoiceHeader: React.FC = () => (
    <>
        <View style={styles.logoContainer}>
            <Image style={styles.logo} src={logo} />
        </View>
        <View style={styles.headerBar}>
            <Text style={styles.headerBarText}>Facture</Text>
        </View>
    </>
);
