import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import CategoriesTreeView from "@components/categories/CategoryTree";
import LoadingTable from "@components/Loading/LoadingTable";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers"; // Votre API des langues
import { fetchLanguages } from "@utils/api/Lang";
import LoadingData from "@components/Loading/LoadingData";

interface FilterCategories {
  name: string;
  item_page: number;
  description: string;
  language_code: string | null; // Nouveau filtre pour la langue
}

const CategoriesList: React.FC = () => {
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();

  // États pour les filtres avec une langue par défaut
  const [filters, setFilters] = useState<FilterCategories>({
    name: "",
    description: "",
    item_page: 10,
    language_code: null, // Initialisation sans langue par défaut
  });

  // État pour le nombre total d'éléments
  const [totalItems, setTotalItems] = useState(0);

  // État pour les langues
  const [languages, setLanguages] = useState([]);

  // Système de pagination
  const { currentPage, totalPages, setPage } = usePagination({
    totalItems,
    itemsPerPage: filters.item_page,
  });

  // Charger les langues depuis l'API
  useEffect(() => {
    const loadLanguages = async () => {
      const langs = await fetchLanguages(apiRequest);
      setLanguages(langs || []);

      // Définir la langue par défaut si elle n'est pas déjà définie
      if (!filters.language_code && langs.length > 0) {
        setFilters((prev) => ({
          ...prev,
          language_code: langs[0].code,
        }));
      }
    };
    loadLanguages();
  }, [filters.language_code]);

  // Paramètres pour les filtres et la pagination
  const params = useMemo(() => {
    return {
      page: currentPage,
      items_per_page: filters.item_page,
      parent: "NULL",
      ...(filters.name && { "categoryTranslates.title": filters.name }),
      ...(filters.description && { "categoryTranslates.description": filters.description }),
      ...(filters.language_code && { language_code: filters.language_code }),
    };
  }, [filters, currentPage]);

  const fetchFilteredCategories = useCallback(async () => {
    const response = await apiRequest({
      route: `${apiUrl}/categories.jsonld`,
      method: "GET",
      params: params,
      headers: {
        Accept: "application/ld+json",
      },
    });

    if (response.data && response.data["hydra:totalItems"]) {
      setTotalItems(response.data["hydra:totalItems"]);
    } else {
      setTotalItems(0);
    }

    return response.data ? response.data["hydra:member"] : [];
  }, [apiRequest, params]);

  const { data, isLoading, error } = useQuery({
    queryKey: ["categories", params],
    queryFn: fetchFilteredCategories,
  });

  const debouncedHandleFilterChange = useMemo(
    () =>
      debounce((key: string, value: any) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [key]: key === "item_page" ? parseInt(value, 10) : value,
        }));
        setPage(1);
      }, 500),
    [setFilters, setPage]
  );

  useEffect(() => {
    return () => {
      debouncedHandleFilterChange.cancel();
    };
  }, [debouncedHandleFilterChange]);

  if (error) return <ErrorLoadingData entity="categories" />;

  const header = () => (
    <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
      <h2 className="text-lg font-semibold text-green-700">Categories List</h2>
      <Button
        label="Add Category"
        icon="pi pi-plus"
        className="p-button-sm p-button-outlined"
        onClick={() => navigate("/dashboard/categories/add")}
      />
    </div>
  );

  return (
    <div className="pt-3">
      <Panel headerTemplate={header} className="rounded-lg p-2">
        {/* Filtres */}
        <div className="px-4 py-3">
          {/* Responsive grid layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {/* Filter by name */}
            <div>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Filter by name"
                defaultValue={filters.name}
                onChange={(event) => debouncedHandleFilterChange("name", event.target.value)}
                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
              />
            </div>
            {/* Filter by description */}
            <div>
              <input
                type="text"
                id="description"
                name="description"
                placeholder="Filter by description"
                defaultValue={filters.description}
                onChange={(event) =>
                  debouncedHandleFilterChange("description", event.target.value)
                }
                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
              />
            </div>
            {/* Language dropdown */}
            <div>
              <Dropdown
                value={filters.language_code}
                options={languages.map((lang: any) => ({
                  label: lang.name,
                  value: lang.code,
                }))}
                onChange={(e) => setFilters((prev) => ({ ...prev, language_code: e.value }))}
                placeholder="Select Language"
                className="w-full"
              />
              <p className="text-xs text-gray-500 mt-2">The default language is English.</p>
            </div>
          </div>
        </div>

        <div>
          {!isLoading ? (
            data && data.length > 0 ? (
              <CategoriesTreeView categories={data} />
            ) : (
              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-md" role="alert">
                <p className="font-bold">Aucune catégorie trouvée</p>
                <p>Veuillez vérifier vos filtres ou ajouter une nouvelle catégorie.</p>
              </div>
            )
          ) : (

            <LoadingData />

          )}
        </div>

        {/* Pagination */}
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setPage} />
      </Panel>
    </div>
  );
};

export default CategoriesList;
