export function generateSlug(text: string): string {
    return text
        .toLowerCase()                       // Convertir en minuscules
        .normalize('NFD')                    // Normalisation Unicode pour séparer les accents
        .replace(/[\u0300-\u036f]/g, '')      // Suppression des accents
        .trim()                              // Suppression des espaces en début et fin
        .replace(/[^a-z0-9\s-]/g, '')         // Suppression des caractères non autorisés
        .replace(/\s+/g, '-')                // Remplacement des espaces par des tirets
        .replace(/-+/g, '-');                // Réduction des tirets multiples en un seul
}
