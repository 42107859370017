// FileUploadWithPreview.tsx
import React, { useState, useEffect } from 'react';

interface FileUploadWithPreviewProps {
    label: string;
    onFileSelect: (files: File[] | null) => void;
    multiple?: boolean;
    maxFileSizeMB?: number;
    initialPreviews?: string[]; // Pour accepter les aperçus initiaux depuis Formik
}

interface PreviewImage {
    file: File | null;
    preview: string;
    error?: string;
}

const FileUploadWithPreview: React.FC<FileUploadWithPreviewProps> = ({
    label,
    onFileSelect,
    multiple = false,
    maxFileSizeMB = 5,
    initialPreviews = [],
}) => {
    const [previewImages, setPreviewImages] = useState<PreviewImage[]>([]);

    useEffect(() => {
        if (initialPreviews.length > 0) {
            const initial = initialPreviews.map((url) => ({
                file: null,
                preview: url,
            }));
            setPreviewImages(initial);
        }
    }, [initialPreviews]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileArray = multiple ? Array.from(files) : [files[0]];
            const newPreviewImages: PreviewImage[] = fileArray.map((file) => {
                const fileSizeMB = file.size / (1024 * 1024);
                if (fileSizeMB > maxFileSizeMB) {
                    return {
                        file,
                        preview: '',
                        error: `La taille du fichier dépasse ${maxFileSizeMB} MB.`,
                    };
                }
                const preview = URL.createObjectURL(file);
                return { file, preview };
            });

            setPreviewImages((prev) => [...prev, ...newPreviewImages]);

            // Filtrer les fichiers valides et les passer au parent
            const validFiles = [...previewImages, ...newPreviewImages]
                .filter((img) => img.file && !img.error)
                .map((img) => img.file as File)
                .filter((file) => file !== null);
        
            onFileSelect(validFiles.length > 0 ? validFiles : null);

            // Réinitialiser l'input
            e.target.value = '';
        }
    };

    const handleRemoveImage = (index: number) => {
        setPreviewImages((prev) => {
            const updated = prev.filter((_, i) => i !== index);
            // Mettre à jour les fichiers valides à passer au parent
            const validFiles = updated
                .filter((img) => img.file && !img.error)
                .map((img) => img.file as File)
                .filter((file) => file !== null);
            onFileSelect(validFiles.length > 0 ? validFiles : null);
            return updated;
        });
    };

    // Nettoyer les URL des images pour éviter les fuites de mémoire
    useEffect(() => {
        return () => {
            previewImages.forEach((img) => {
                if (img.preview && img.file) {
                    URL.revokeObjectURL(img.preview);
                }
            });
        };
    }, [previewImages]);

    const calculateFileSize = (file: File): string => {
        const sizeInKB = (file.size / 1024).toFixed(2);
        const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        return parseFloat(sizeInMB) > 1 ? `${sizeInMB} MB` : `${sizeInKB} KB`;
    };

    return (
        <div className="flex flex-col items-start space-y-2">
            <label className="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-600 transition-colors duration-300">
                {label}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    multiple={multiple}
                    className="hidden"
                    aria-label={`Upload ${label}`}
                />
            </label>

            {previewImages.length > 0 && (
                <div className="flex flex-wrap gap-4 mt-4">
                    {previewImages.map((img, index) => (
                        <div key={index} className="relative w-32 h-32">
                            {img.preview ? (
                                <img
                                    src={img.preview}
                                    alt={`Preview ${index + 1}`}
                                    className="w-full h-full object-cover rounded-md shadow-md"
                                />
                            ) : img.error ? (
                                <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-md">
                                    <span className="text-red-500 text-sm">{img.error}</span>
                                </div>
                            ) : null}

                            <button
                                onClick={() => handleRemoveImage(index)}
                                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-300"
                                aria-label="Remove selected image"
                            >
                                &#10006;
                            </button>

                            {img.file && !img.error && (
                                <span className="absolute bottom-1 right-1 bg-gray-800 text-white text-xs px-2 py-1 rounded-md shadow-lg">
                                    {calculateFileSize(img.file)}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FileUploadWithPreview;
