// src/pages/OrderDetailsPage.tsx
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { apiUrl } from "@helpers/Helpers";
import useApiRequest from "@mbs-dev/api-request";
import { Panel } from "primereact/panel";
import { Timeline } from "primereact/timeline";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Order } from "@utils/interfaces/order";
import { formatDateOrders, orderStatusSequence, paymentMethodBadge, paymentStatusBadge, timelineEvents } from "@components/orders/helper";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import OnlineInvoiceDocument from "@components/orders/invoice/OnlineInvoiceDocument";




const OrderDetailsPage: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // Récupération des détails de la commande via React Query
    const { data: order, isLoading, error } = useQuery<Order>({
        queryKey: ["orderDetails", orderId],
        queryFn: async () => {
            const response = await apiRequest({
                route: `${apiUrl}/orders/${orderId}`,
                method: "GET",
                headers: { Accept: "application/ld+json" },
            });
            return response.data;
        },
    });

    if (isLoading)
        return (
            <div className="flex justify-center items-center h-screen">
                <ProgressSpinner />
            </div>
        );

    if (error || !order)
        return (
            <div className="p-4 text-center text-red-500">
                Erreur lors du chargement des détails de la commande.
            </div>
        );

    // Calcul de l'indice du statut courant pour le timeline horizontal
    const currentStatusIndex = orderStatusSequence.indexOf(order.orderStatus);

    // Template pour personnaliser le marqueur du Timeline
    const timelineMarkerTemplate = (item: any) => {
        const index = orderStatusSequence.indexOf(item.status);
        let style = {};
        if (order.orderStatus === "CANCELED") {
            style = { backgroundColor: "red", color: "#fff" };
        } else if (index <= currentStatusIndex) {
            style = { backgroundColor: "#4caf50", color: "#fff" };
        } else {
            style = { backgroundColor: "#e0e0e0", color: "#000" };
        }
        return (
            <span
                className="p-shadow-2"
                style={{
                    ...style,
                    borderRadius: "50%",
                    padding: "0.5rem",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <i className={item.icon}></i>
            </span>
        );
    };

    return (
        <div className="w-full flex flex-col items-center p-4">
            <div className="w-full max-w-5xl">
                {/* En-tête */}
                <div className="flex w-full justify-between items-center mt-6 p-3 bg-green-100 border border-green-200 rounded-lg">
                    <Button
                        icon="pi pi-arrow-left"
                        onClick={() => navigate(-1)}
                        className="p-button-outlined"
                    />
                    <h1 className="text-xl font-semibold text-green-700">
                        Détails de la commande - {order.reference}
                    </h1>


                    {
                        order && (
                            <BlobProvider document={<OnlineInvoiceDocument order={order} />}>
                                {({ blob, url, loading }) =>
                                    loading ? (
                                        <Button
                                            label="Génération..."
                                            icon="pi pi-spin pi-spinner"
                                            className="p-button-sm p-button-warning"
                                            disabled
                                        />
                                    ) : (
                                        <Button
                                            label="Télécharger la facture"
                                            icon="pi pi-download"
                                            className="p-button-sm p-button-success"
                                            onClick={() => blob && saveAs(blob, `invoice_order_${order.reference}.pdf`)}
                                        />
                                    )
                                }
                            </BlobProvider>
                        )
                    }

                    <div className="hidden sm:block">

                    </div>

                </div>

                {/* Carte principale */}
                <div className="bg-white shadow-md border border-gray-200 rounded-lg p-6 mt-4">
                    {/* Timeline horizontal */}
                    <div className="mb-5">
                        <Timeline
                            value={timelineEvents}
                            layout="horizontal"
                            marker={timelineMarkerTemplate}
                            content={(item) => <p className="m-0 text-xs">{item.content}</p>}
                        />
                    </div>

                    <hr className="my-5 border-gray-300" />

                    {/* Grille responsive des 4 sections */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {/* Adresse de facturation */}
                        <div className="flex gap-2 flex-col">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-home text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Facturation</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Nom :</strong> {order.billingAddress.fullName}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-envelope text-green-700 text-xs"></i>
                                {order.billingAddress.email}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-phone text-green-700 text-xs"></i>
                                {order.billingAddress.telephone}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-map-marker text-green-700 text-xs"></i>
                                {order.billingAddress.address}, {order.billingAddress.ville} - {order.billingAddress.zipCode}
                            </p>
                        </div>

                        {/* Adresse de livraison */}
                        <div className="flex gap-2 flex-col">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-map text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Livraison</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Nom :</strong> {order.deliveryAddress.fullName}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-envelope text-green-700 text-xs"></i>
                                {order.deliveryAddress.email}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-phone text-green-700 text-xs"></i>
                                {order.deliveryAddress.telephone}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-map-marker text-green-700 text-xs"></i>
                                {order.deliveryAddress.address}, {order.deliveryAddress.ville} - {order.deliveryAddress.zipCode}
                            </p>
                        </div>

                        {/* Profil utilisateur */}
                        <div className="flex gap-2 flex-col">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-user text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Profil</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Nom :</strong> {order.user.fullName}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-envelope text-green-700 text-xs"></i>
                                {order.user.email}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-user text-green-700 text-xs"></i>
                                {order.user.username}
                            </p>
                        </div>

                        {/* Récap de la commande */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-info-circle text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Récap</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Prix Total :</strong> {order.price} €
                            </p>
                            <p className="text-xs">
                                <strong>Quantité :</strong> {order.itemsQuantity}
                            </p>
                            {/* Affichage du mode de paiement avec badge */}
                            <p className="text-xs flex items-center gap-5">
                                <strong>Pay. :</strong>
                                <span
                                    className={`px-2 rounded-full text-xs font-semibold ${paymentMethodBadge[order.paymentMethod]?.color || "bg-gray-300 text-black"
                                        }`}
                                >
                                    {paymentMethodBadge[order.paymentMethod]?.label || order.paymentMethod}
                                </span>
                            </p>
                            {/* Affichage du statut de paiement avec badge */}
                            <p className="text-xs flex items-center gap-4">
                                <strong>Statut pay. :</strong>
                                <span
                                    className={`px-2 text-white rounded-full text-xs font-semibold ${paymentStatusBadge[order.paymentStatus]?.color || "bg-gray-300 text-black"
                                        }`}
                                >
                                    {paymentStatusBadge[order.paymentStatus]?.label || order.paymentStatus}
                                </span>
                            </p>
                        </div>


                        {/* date */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-calendar text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Dates</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Créée :</strong>{" "}
                                {/* {formatDateOrders(order.createdAt, true)} */}
                            </p>
                            <p className="text-xs">
                                <strong>Modifiée :</strong>{" "}
                                {formatDateOrders(order.updatedAt, true)}
                            </p>
                        </div>
                    </div>

                    <hr className="my-3 border-gray-300" />

                    <div>
                        <div className="flex items-center gap-2 mb-2">
                            <i className="pi pi-comment text-green-700 text-xl"></i>
                            <h3 className="text-base font-semibold text-green-700">commentaire</h3>
                        </div>
                        <span>{order.comment}</span>
                    </div>

                    <hr className="my-3 border-gray-300" />

                    {/* Liste des articles commandés */}
                    <div className="mt-6">
                        <div className="flex items-center gap-2 mb-2">
                            <i className="pi pi-shopping-cart text-green-700 text-xl"></i>
                            <h3 className="text-base font-semibold text-green-700">Articles commandés</h3>
                        </div>
                        <DataTable value={order.orderItems} responsiveLayout="scroll" className="text-xs">
                            <Column
                                field="product.label"
                                header="Produit"
                                body={(rowData) => rowData.product?.child[0]?.label || rowData.product.label}
                            />
                            <Column field="quantity" header="Quantité" />
                            <Column
                                field="price"
                                header="Prix"
                                body={(rowData) => `${rowData.price} €`}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetailsPage;
