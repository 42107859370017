import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";
import { AddSocialMediaPayload } from "@utils/interfaces/SocialMedia";


export const addSocialMedia = async (apiRequest: any, data: AddSocialMediaPayload) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/social_media`,
            method: 'POST',
            data: data,
            // requiresAuth: true,
            // token: `${localStorage.getItem('userToken')}`,
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
        });

        notify.successNotify("social media name create successfully");
    } catch (error) {
        notify.errorNotify("Erreur lors de l'ajout du Plateforme")
    }
};

export const getSocialMediaById = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/social_media/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Social Media Not Found !!');
        // throw new Error('Erreur lors de la récupération de la Attribute');
    }
};


export const updateSocialMedia = async (apiRequest: any, id: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/social_media/${id}`,
            method: 'PUT',
            data: data,
        });

        notify.successNotify('Social Media mise à jour avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la mise à jour social media');
    }
};


export const deleteSocialMedia = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/social_media/${id}.json`,
            method: 'DELETE',
        });
        notify.successNotify('Social Media est  supprimée avec succès');
    } catch (error) {
        notify.errorNotify("Erreur lors de la suppression social media");
    }
};
