import React from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Button as PrimeButton } from 'primereact/button';
import { useQuery } from '@tanstack/react-query';
import LoadingData from '@components/Loading/LoadingData';
import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';
import { fetchAttributes } from '@utils/api/attribute';
import useApiRequest from '@mbs-dev/api-request';


interface ProductAttribute {
    attribute: string;
    value: string;
}

interface Attribute {
    id: number;
    name: string;
}

interface ProductAttributesProps {
    formik: FormikProps<any>;
}

const ProductAttributes: React.FC<ProductAttributesProps> = ({ formik }) => {
    const { apiRequest } = useApiRequest();
    // Récupération des attributs via l'API
    const { data: attributes, isLoading, error } = useQuery({
        queryKey: ['attributes'],
        queryFn: () => fetchAttributes(apiRequest)
    });

    if (isLoading) return <LoadingData />;
    if (error) return <ErrorLoadingData entity="Attributs" />;

    // Préparation des options pour la dropdown
    const attributeOptions = [
        { label: 'Sélectionnez un attribut', value: '' },
        ...attributes.map((attr: Attribute) => ({
            label: attr.name,
            value: `api/attributes/${attr.id}`, // format IRI
        })),
    ];

    return (
        <div>
            <h3 className="text-lg font-bold mb-2">Attributs du Produit :</h3>
            <FieldArray name="productAttributes">
                {({ remove, push }) => (
                    <div className="space-y-3">
                        {formik.values.productAttributes.map((attr: ProductAttribute, index: number) => (
                            <div
                                key={index}
                                className="flex flex-col md:flex-row items-center bg-white p-2 border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
                            >
                                {/* Dropdown pour sélectionner l'attribut */}
                                <div className="flex-1 flex items-center mb-2 md:mb-0">
                                    <div className="w-full">
                                        <label htmlFor={`productAttributes[${index}].attribute`} className="block text-sm font-bold mb-1">
                                            Attribut :
                                        </label>
                                        <Dropdown
                                            id={`productAttributes[${index}].attribute`}
                                            name={`productAttributes[${index}].attribute`}
                                            value={attr.attribute}
                                            options={attributeOptions}
                                            onChange={formik.handleChange}
                                            placeholder="Sélectionnez un attribut"
                                            className="w-full text-sm border-gray-300"
                                        />
                                    </div>
                                </div>
                                {/* Champ pour la valeur de l'attribut */}
                                <div className="flex-1 flex items-center mb-2 md:mb-0">
                                    <div className="w-full">
                                        <label htmlFor={`productAttributes[${index}].value`} className="block text-sm font-bold mb-1">
                                            Valeur :
                                        </label>
                                        <input
                                            type="text"
                                            id={`productAttributes[${index}].value`}
                                            name={`productAttributes[${index}].value`}
                                            value={attr.value}
                                            onChange={formik.handleChange}
                                            placeholder="Entrez la valeur"
                                            className="w-full p-1 text-lg border rounded border-gray-300"
                                        />
                                    </div>
                                </div>
                                {/* Bouton pour supprimer l'attribut */}
                                <div className="flex items-end">
                                    <PrimeButton
                                        type="button"
                                        icon="pi pi-trash"
                                        className="p-button-danger p-button-text"
                                        onClick={() => remove(index)}
                                        aria-label={`Supprimer l'attribut`}
                                    />
                                </div>
                            </div>
                        ))}
                        {/* Bouton pour ajouter un nouvel attribut */}
                        <div className="flex justify-start">
                            <PrimeButton
                                type="button"
                                label="Ajouter un Attribut"
                                icon="pi pi-plus"
                                onClick={() => push({ attribute: '', value: '' })}
                                className="p-button-secondary text-xs"
                            />
                        </div>
                    </div>
                )}
            </FieldArray>
        </div>
    );
};

export default ProductAttributes;
