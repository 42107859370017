// src/pages/OrderOfflineDetails.tsx
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { apiUrl } from "@helpers/Helpers";
import useApiRequest from "@mbs-dev/api-request";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { Timeline } from "primereact/timeline";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { orderStatusSequence, paymentMethodBadge, paymentStatusBadge, timelineEvents } from "@components/orders/helper";
import { OfflineOrder } from "@utils/interfaces/order";
import { BlobProvider } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import OfflineInvoiceDocument from "@components/orders/invoice/OfflineInvoiceDocument";
import { formatDateOrders } from '../../components/orders/helper';




const OrderOfflineDetails: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    const { data: offlineOrder, isLoading, error } = useQuery<OfflineOrder>({
        queryKey: ["offlineOrderDetails", orderId],
        queryFn: async () => {
            const response = await apiRequest({
                route: `${apiUrl}/offline_orders/${orderId}`,
                method: "GET",
                headers: { Accept: "application/ld+json" },
            });
            return response.data;
        },
    });

    if (isLoading)
        return (
            <div className="flex justify-center items-center h-screen">
                <ProgressSpinner />
            </div>
        );
    if (error || !offlineOrder)
        return (
            <div className="p-4 text-center text-red-500">
                Erreur lors du chargement des détails de la commande offline.
            </div>
        );

    // Calcul de l'indice du statut courant pour la timeline
    const currentStatusIndex = orderStatusSequence.indexOf(offlineOrder.orderStatus);

    const timelineMarkerTemplate = (item: any) => {
        const index = orderStatusSequence.indexOf(item.status);
        let style = {};
        if (offlineOrder.orderStatus === "CANCELED") {
            style = { backgroundColor: "red", color: "#fff" };
        } else if (index <= currentStatusIndex) {
            style = { backgroundColor: "#4caf50", color: "#fff" };
        } else {
            style = { backgroundColor: "#e0e0e0", color: "#000" };
        }
        return (
            <span
                className="p-shadow-2"
                style={{
                    ...style,
                    borderRadius: "50%",
                    padding: "0.5rem",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <i className={item.icon}></i>
            </span>
        );
    };

    return (
        <div className="w-full flex flex-col items-center p-4">
            <div className="w-full max-w-5xl">
                {/* En-tête */}
                <div className="flex w-full justify-between items-center mt-6 p-3 bg-green-100 border border-green-200 rounded-lg">
                    <Button icon="pi pi-arrow-left" onClick={() => navigate(-1)} className="p-button-outlined" />
                    <h1 className="text-xl font-semibold text-green-700">
                        Détails de la commande offline - {offlineOrder.reference}
                    </h1>
                    {
                        offlineOrder && (
                            <BlobProvider document={<OfflineInvoiceDocument order={offlineOrder} />}>
                                {({ blob, url, loading }) =>
                                    loading ? (
                                        <Button
                                            label="Génération..."
                                            icon="pi pi-spin pi-spinner"
                                            className="p-button-sm p-button-warning"
                                            disabled
                                        />
                                    ) : (
                                        <Button
                                            label="Télécharger la facture"
                                            icon="pi pi-download"
                                            className="p-button-sm p-button-success"
                                            onClick={() => blob && saveAs(blob, `invoice_order_${offlineOrder.reference}.pdf`)}
                                        />
                                    )
                                }
                            </BlobProvider>
                        )
                    }
                    <div className="hidden sm:block"></div>
                </div>

                {/* Carte principale */}
                <div className="bg-white shadow-md border border-gray-200 rounded-lg p-6 mt-4">
                    {/* Timeline horizontal */}
                    <div className="mb-5">
                        <Timeline
                            value={timelineEvents}
                            layout="horizontal"
                            marker={timelineMarkerTemplate}
                            content={(item) => <p className="m-0 text-xs">{item.content}</p>}
                        />
                    </div>
                    <hr className="my-5 border-gray-300" />

                    {/* Grille responsive des sections */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {/* Adresse */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-map-marker text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Adresse</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Nom :</strong> {offlineOrder.fullName}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-envelope text-green-700 text-xs"></i>
                                {offlineOrder.email}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-phone text-green-700 text-xs"></i>
                                {offlineOrder.telephone}
                            </p>
                            <p className="text-xs">
                                <strong>Adresse :</strong> {offlineOrder.address}, {offlineOrder.ville} - {offlineOrder.zipCode}
                            </p>
                        </div>

                        {/* Profil offline */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-user text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Profil</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Nom :</strong> {offlineOrder.fullName}
                            </p>
                            <p className="text-xs flex items-center gap-1">
                                <i className="pi pi-envelope text-green-700 text-xs"></i>
                                {offlineOrder.email}
                            </p>
                        </div>

                        {/* Récap offline */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-info-circle text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Récap</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Prix Total :</strong> {offlineOrder.price} €
                            </p>
                            <p className="text-xs">
                                <strong>Quantité :</strong> {offlineOrder.itemsQuantity}
                            </p>
                            <p className="text-xs flex items-center gap-2">
                                <strong>Pay. :</strong>
                                <span
                                    className={`px-2 py-1 rounded-full text-xs font-semibold ${paymentMethodBadge[offlineOrder.paymentMethod]?.color || "bg-gray-300 text-black"
                                        }`}
                                >
                                    {paymentMethodBadge[offlineOrder.paymentMethod]?.label || offlineOrder.paymentMethod}
                                </span>
                            </p>
                            <p className="text-xs flex items-center gap-2">
                                <strong>Statut pay. :</strong>
                                <span
                                    className={`px-2 py-1 rounded-full text-xs font-semibold ${paymentStatusBadge[offlineOrder.paymentStatus]?.color || "bg-gray-300 text-black"
                                        }`}
                                >
                                    {paymentStatusBadge[offlineOrder.paymentStatus]?.label || offlineOrder.paymentStatus}
                                </span>
                            </p>
                            <p className="text-xs">
                                <strong>Commentaire :</strong> {offlineOrder.comment}
                            </p>
                        </div>

                        {/* Dates */}
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2 mb-1">
                                <i className="pi pi-calendar text-green-700 text-xl"></i>
                                <h3 className="text-base font-semibold text-green-700">Dates</h3>
                            </div>
                            <p className="text-xs">
                                <strong>Créée :</strong>{" "}
                                {formatDateOrders(offlineOrder.createdAt, true)}
                            </p>
                            <p className="text-xs">
                                <strong>Modifiée :</strong>{" "}
                                {formatDateOrders(offlineOrder.updatedAt, true)}
                            </p>
                        </div>
                    </div>

                    {/* Liste des articles offline */}
                    <div className="mt-6">
                        <div className="flex items-center gap-2 mb-2">
                            <i className="pi pi-shopping-cart text-green-700 text-xl"></i>
                            <h3 className="text-base font-semibold text-green-700">Articles commandés</h3>
                        </div>
                        <DataTable value={offlineOrder.offlineOrderItems} responsiveLayout="scroll" className="text-xs">
                            <Column
                                field="product.label"
                                header="Produit"
                                body={(rowData) =>
                                    rowData.product?.child[0]?.label || rowData.product.label
                                }
                            />
                            <Column field="quantity" header="Quantité" />
                            <Column
                                field="price"
                                header="Prix"
                                body={(rowData) => `${rowData.price} €`}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderOfflineDetails;
