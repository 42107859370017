import React from 'react';
import { FieldArray, FormikTouched } from 'formik';
import { Button as PrimeButton } from 'primereact/button';
import { Card } from 'primereact/card'; 
import { Language } from '@utils/interfaces/language';

interface Translation {
    title: string;
    description: string;
    language: number // Language ID
}

interface CategoryTranslationFieldsProps {
    formik: any;
    languages: Language[];
}

const CategoryTranslationFields: React.FC<CategoryTranslationFieldsProps> = ({ formik, languages }) => {
    // Helper function to get selected languages
    const getSelectedLanguages = (): number[] => {
        return formik.values.categoryTranslates.map((translate: Translation) => translate.language); // Get language IDs
    };

    // Helper function to filter available languages
    const availableLanguages = (selectedLanguages: number[], currentLang: number): Language[] => {
        return languages.filter(
            (lang) => !selectedLanguages.includes(lang.id) || lang.id === currentLang
        );
    };

    // Helper function to display form field errors above inputs
    const displayError = (errors: any, touched: FormikTouched<Translation> | undefined, field: keyof Translation) => {
        return touched?.[field] && errors?.[field] ? (
            <p className="text-red-500 text-xs mb-1">{errors[field]}</p>
        ) : null;
    };

    return (
        <FieldArray name="categoryTranslates">
            {({ remove, push }) => (
                <div className="space-y-4">
                    {formik.values.categoryTranslates.map((translate: Translation, index: number) => {
                        const selectedLanguages = getSelectedLanguages();
                        const filteredLanguages = availableLanguages(selectedLanguages, translate.language);

                        // Safely access errors and touched arrays
                        const errors = Array.isArray(formik.errors.categoryTranslates)
                            ? formik.errors.categoryTranslates[index]
                            : undefined;

                        const touched = Array.isArray(formik.touched.categoryTranslates)
                            ? formik.touched.categoryTranslates[index]
                            : undefined;

                        // Conditionally apply border-red-500 if there's an error
                        const getInputClasses = (field: keyof Translation) =>
                            `p-2 w-full border rounded-lg shadow-sm transition-all focus:ring-2 focus:ring-green-400 ${errors?.[field] && touched?.[field] ? 'border-red-500' : 'border-gray-300'
                            }`;

                        return (
                            <Card key={index} className="shadow-lg px-4 border-2 border-gray-200 rounded-lg">
                                <div className="flex justify-between items-center mb-3">
                                    <h4 className="text-lg font-bold text-gray-800">Translation #{index + 1}</h4>
                                    {/* Remove Button */}
                                    <button
                                        type="button"
                                        onClick={() => remove(index)}
                                        className="p-2 bg-red-500 hover:bg-red-600 text-white rounded-lg shadow transition-all"
                                    >
                                        <i className='pi pi-trash'></i>
                                    </button>
                                </div>

                                <div className="space-y-4">

                                    <div className="w-full">
                                        {/* Language dropdown */}
                                        {displayError(errors, touched, 'language')}
                                        <select
                                            name={`categoryTranslates[${index}][language]`}
                                            value={translate.language || ''} // Use the language ID directly from the populated formik values
                                            onChange={(e) => {
                                                const selectedLanguageId = parseInt(e.target.value, 10);
                                                formik.setFieldValue(
                                                    `categoryTranslates[${index}][language]`,
                                                    selectedLanguageId // Set the selected language ID directly
                                                );
                                            }}
                                            className={getInputClasses('language')}
                                        >
                                            <option value="">Select Language</option>
                                            {filteredLanguages.map((lang) => (
                                                <option key={lang.id} value={lang.id}>
                                                    {lang.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="w-full">
                                        {/* Title input */}
                                        {displayError(errors, touched, 'title')}
                                        <input
                                            type="text"
                                            name={`categoryTranslates[${index}][title]`}
                                            value={translate.title}
                                            placeholder="Enter Title"
                                            onChange={formik.handleChange}
                                            className={getInputClasses('title')}
                                        />
                                    </div>


                                    <div className="w-full">
                                        {/* Description textarea */}
                                        {displayError(errors, touched, 'description')}
                                        <textarea
                                            name={`categoryTranslates[${index}][description]`}
                                            value={translate.description}
                                            placeholder="Enter Description"
                                            rows={3}
                                            onChange={formik.handleChange}
                                            className={`${getInputClasses('description')} resize-none`}
                                        />
                                    </div>

                                </div>
                            </Card>
                        );
                    })}

                    {/* Add Translation Button */}
                    <div className="flex justify-start">
                        <PrimeButton
                            label="Add Translation"
                            icon="pi pi-plus"
                            size="small"
                            onClick={() => push({ title: '', slug: '', description: '', language: null })}
                            className="bg-green-500 hover:bg-green-600 text-white text-xs px-4 py-2 rounded-lg shadow-sm transition-all"
                        />
                    </div>
                </div>
            )}
        </FieldArray>
    );
};

export default CategoryTranslationFields;
