import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Tag } from 'primereact/tag';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface EditableFieldProps {
    value: string | boolean;
    onSave: (newValue: string) => Promise<void>;
    inputType?: 'text' | 'textarea' | 'file' | 'richtext' | 'boolean';
    placeholder?: string;
    displayValue?: string;
}

const EditableField: React.FC<EditableFieldProps> = ({
    value,
    onSave,
    inputType = 'text',
    placeholder,
    displayValue
}) => {
    // State pour gérer la valeur éditée (peut être une chaîne, un booléen ou un File)
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState<string | boolean | File | null>(value);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    // À la réception de la valeur, on la convertit en booléen si le type est "boolean"
    useEffect(() => {
        if (inputType === 'boolean') {
            if (typeof value === 'string') {
                setEditedValue(value === 'true' || value === '1');
            } else {
                setEditedValue(value);
            }
        } else {
            setEditedValue(value);
        }
    }, [value, inputType]);

    // Ouvre le mode édition (pour richtext ou autres types)
    const handleClick = () => {
        setError(null);
        if (inputType === 'richtext') {
            setIsDialogVisible(true);
        } else {
            setIsEditing(true);
        }
    };

    // Lors de la sauvegarde, on convertit la valeur booléenne en "1" ou "0"
    const handleSave = async () => {
        setError(null);
        setIsLoading(true);
        try {
            if (inputType === 'boolean') {
                await onSave((editedValue as boolean) ? "1" : "0");
            } else {
                await onSave(editedValue as string);
            }
            setIsEditing(false);
            setIsDialogVisible(false);
        } catch (error) {
            setError('Échec de la sauvegarde. Veuillez réessayer.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setEditedValue(value);
        setIsEditing(false);
        setIsDialogVisible(false);
        setError(null);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSave();
        } else if (e.key === 'Escape') {
            handleCancel();
        }
    };

    // Configuration de base pour l'éditeur riche
    const modules = {
        toolbar: {
            container: [
                [{ 'font': [] }, { 'size': [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
                [{ 'align': [] }, 'clean']
            ]
        }
    };

    return (
        <div className="editable-field">
            {/* Branch pour les données booléennes */}
            {inputType === 'boolean' ? (
                isEditing ? (
                    <div className="flex flex-col gap-2">
                        <InputSwitch
                            checked={editedValue as boolean}
                            onChange={(e) => setEditedValue(e.value)}
                        />
                        {error && <div className="text-red-500 text-xs">{error}</div>}
                        <div className="flex gap-2 mt-2">
                            <button
                                onClick={handleSave}
                                disabled={isLoading}
                                className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-300 text-xs"
                            >
                                {isLoading ? 'Sauvegarde...' : '✓'}
                            </button>
                            <button
                                onClick={handleCancel}
                                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-xs"
                            >
                                ✕
                            </button>
                        </div>
                    </div>
                ) : (
                    <div
                        className="cursor-pointer hover:bg-gray-100 p-2 rounded"
                        onClick={handleClick}
                    >
                        <span>
                            {(typeof value === 'string' && (value === 'true' || value === '1')) || (value === true) ? (
                                <Tag severity="success">Oui</Tag>
                            ) : (
                                <Tag severity="danger">Non</Tag>
                            )}
                        </span>
                    </div>
                )
            ) : inputType === 'richtext' ? (
                // Branch pour l'éditeur richtext
                <>
                    {!isDialogVisible && (
                        <div
                            className="cursor-pointer hover:bg-gray-100 p-2 rounded"
                            onClick={handleClick}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: displayValue || (typeof value === 'string' ? value : '') || placeholder || ''
                                }}
                            />
                        </div>
                    )}
                </>
            ) : (
                // Branch pour les autres types (text, textarea, file)
                !isEditing ? (
                    <div
                        className="cursor-pointer hover:bg-gray-100 p-2 rounded"
                        onClick={handleClick}
                    >
                        {displayValue || (typeof value === 'string' ? value : '') || placeholder}
                    </div>
                ) : (
                    <div className="flex flex-col gap-2">
                        {inputType === 'textarea' ? (
                            <textarea
                                value={editedValue as string}
                                onChange={(e) => setEditedValue(e.target.value)}
                                onKeyDown={handleKeyPress}
                                placeholder={placeholder}
                                className="p-2 border rounded text-sm w-full"
                                autoFocus
                                rows={4}
                            />
                        ) : (
                            <input
                                type={inputType}
                                value={editedValue as string}
                                onChange={(e) => setEditedValue(e.target.value)}
                                onKeyDown={handleKeyPress}
                                placeholder={placeholder}
                                className="p-2 border rounded text-sm"
                                autoFocus
                            />
                        )}
                        {error && <div className="text-red-500 text-xs">{error}</div>}
                        <div className="flex gap-2 mt-2">
                            <button
                                onClick={handleSave}
                                disabled={isLoading}
                                className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-300 text-xs"
                            >
                                {isLoading ? 'Sauvegarde...' : '✓'}
                            </button>
                            <button
                                onClick={handleCancel}
                                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-xs"
                            >
                                ✕
                            </button>
                        </div>
                    </div>
                )
            )}

            {/* Modal pour l'éditeur riche */}
            {inputType === 'richtext' && (
                <Dialog
                    header="Modifier le contenu"
                    visible={isDialogVisible}
                    style={{ width: '50vw' }}
                    onHide={handleCancel}
                    dismissableMask
                    closable
                >
                    <ReactQuill
                        value={editedValue as string}
                        onChange={setEditedValue}
                        modules={modules}
                        theme="snow"
                        className="bg-white"
                    />
                    <div className="flex gap-2 mt-3">
                        <button
                            onClick={handleSave}
                            className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 text-xs"
                        >
                            Sauvegarder
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-xs"
                        >
                            Annuler
                        </button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default EditableField;
