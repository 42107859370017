import React, { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Button as PrimeButton } from 'primereact/button';
import useApiRequest from '@mbs-dev/api-request';
import { useNavigate } from 'react-router-dom';
import { notify } from '@mbs-dev/react-helpers';
import * as Yup from 'yup';
import { apiUrl } from '@helpers/Helpers';
import LoadingData from '@components/Loading/LoadingData';
import { InputSwitch } from 'primereact/inputswitch';

// ----------------------------------------------------------------------
// Interfaces et types
// ----------------------------------------------------------------------
interface Country {
    id: number;
    name: string;
    code: string;
}

interface ProductOption {
    value: number;
    label: string;
}

interface PromotionFormData {
    promoType: 'percentage' | 'fixed';
    discount?: number;
    price?: number;
    startAt: Date | null;
    endAt: Date | null;
    product: number[]; // tableau d'IDs de produits
    status: number;
    country: Country | null;
}

// ----------------------------------------------------------------------
// Valeurs initiales et schéma de validation
// ----------------------------------------------------------------------
const PromotionIV: PromotionFormData = {
    promoType: 'percentage',
    discount: 0,
    price: 0,
    startAt: null,
    endAt: null,
    product: [],
    status: 1,
    country: null,
};

const PromotionAddSchema = Yup.object().shape({
    promoType: Yup.string().oneOf(['percentage', 'fixed']).required('Le type de promotion est requis'),
    discount: Yup.number().min(0, 'Doit être positif').required('Le discount est requis') ,
    price: Yup.number().min(0, 'Doit être positif').required('Le prix est requis') ,
    startAt: Yup.date().required('La date de début est requise'),
    endAt: Yup.date().required('La date de fin est requise'),
    product: Yup.array().of(Yup.number()).min(1, 'Sélectionnez au moins un produit'),
    country: Yup.object().nullable().required('Le pays est requis'),
});

// ----------------------------------------------------------------------
// Composant PromotionAdd
// ----------------------------------------------------------------------
const PromotionAdd: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    // Pour la recherche de produit
    const [productSearch, setProductSearch] = useState("");

    // Formik
    const formik = useFormik<PromotionFormData>({
        initialValues: PromotionIV,
        validationSchema: PromotionAddSchema,
        onSubmit: (values) => {
            // Construction du payload selon le type de promotion
            const payload: any = {
                startAt: values.startAt ? values.startAt.toISOString() : null,
                endAt: values.endAt ? values.endAt.toISOString() : null,
                product: values.product.map((id) => `api/products/${id}`),
                status: values.status ? 1:0,
                country: values.country ? `api/countries/${values.country.id}` : null,
            };

            if (values.promoType === 'percentage') {
                payload.discount = values.discount;
            } else {
                payload.price = values.price;
            }

            mutation.mutate(payload);
        },
    });

    // Mutation pour créer la promotion (POST)
    const mutation = useMutation({
        mutationFn: (data: any) => {
            return apiRequest({
                route: `${apiUrl}/promotions`,
                method: "POST",
                data,
            });
        },
        onMutate: () => {
            formik.setSubmitting(true);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['promotion'] });
            navigate('/dashboard/promotion');
            formik.setSubmitting(false);
            notify.successNotify("Promotion créée avec succès !");
        },
        onError: () => {
            formik.setSubmitting(false);
            notify.errorNotify("Erreur lors de la création de la promotion. Vérifiez la console pour plus de détails.");
        },
    });

    // Récupération des pays
    const { data: countries, isLoading: loadingCountries, error: countriesError } = useQuery<Country[]>({
        queryKey: ['countries'],
        queryFn: () =>
            apiRequest({
                route: `${apiUrl}/countries.json`,
                method: "GET"
            }).then((res) => res.data),
    });


    // ----------------------------------------------------------------------
    // Récupération des produits filtrés
    // ----------------------------------------------------------------------
    // Se déclenche uniquement si un pays a été sélectionné
    const selectedCountryCode = formik.values.country ? formik.values.country.code : null;

    // Transforme les données brutes pour générer des options pour le Dropdown.
    const transformProducts = (rawProducts: any[]): ProductOption[] => {
        const options: ProductOption[] = [];
        rawProducts.forEach(item => {
            console.log("produt to get in selecct ", item);

            if (item.type === "PARENT") {
                // Pour un PARENT, chaque enfant devient une option
                if (item.child && Array.isArray(item.child)) {
                    options.push({
                        value: item.child[0].id,
                        label: item.child[0].label || "N/A",
                    });
                }
            } else if (item.type === "FINAL") {
                // Pour un FINAL, on utilise le label du premier enfant
                if (item.child && item.child.length > 0) {
                    options.push({
                        value: item.id,
                        label: item.child[0].label || "N/A",
                    });
                }
            }
        });
        return options;
    };

    const { data: productOptions, isLoading: loadingProducts, refetch: refetchProducts } = useQuery<ProductOption[]>({
        queryKey: ['filteredProducts', selectedCountryCode, productSearch],
        queryFn: async () => {
            if (!selectedCountryCode) return [];
            const params: any = { country_code: selectedCountryCode };
            if (productSearch) {
                params.label = productSearch;
            }
            const response = await apiRequest({
                route: `${apiUrl}/products.jsonld`,
                method: "GET",
                params,
                headers: { Accept: "application/ld+json" },
            });
            const rawProducts = response.data?.["hydra:member"] || [];
            return transformProducts(rawProducts);
        },
        enabled: !!selectedCountryCode,
    });

    // Refetch dès que le texte de recherche ou le pays change
    useEffect(() => {
        if (selectedCountryCode) {
            refetchProducts();
        }
    }, [productSearch, selectedCountryCode, refetchProducts]);

    if (countriesError) {
        return <div>Erreur lors du chargement des pays.</div>;
    }

    // ----------------------------------------------------------------------
    // Rendu du formulaire
    // ----------------------------------------------------------------------
    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Créer une Promotion</h2>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 border border-green-200 rounded-bl-lg rounded-br-lg text-sm">
                        {/* Boutons radio pour le type de promotion */}
                        <div className="flex items-center gap-4">
                            <label className="flex items-center">
                                <RadioButton
                                    inputId="percentage"
                                    name="promoType"
                                    value="percentage"
                                    checked={formik.values.promoType === "percentage"}
                                    onChange={(e) => formik.setFieldValue('promoType', e.value)}
                                />
                                <span className="ml-2">Promotion par pourcentage</span>
                            </label>
                            <label className="flex items-center">
                                <RadioButton
                                    inputId="fixed"
                                    name="promoType"
                                    value="fixed"
                                    checked={formik.values.promoType === "fixed"}
                                    onChange={(e) => formik.setFieldValue('promoType', e.value)}
                                />
                                <span className="ml-2">Prix fixe</span>
                            </label>
                        </div>

                        {/* Champ conditionnel pour le discount ou le prix */}
                        {formik.values.promoType === "percentage" ? (
                            <div>
                                <label className="block text-sm font-medium mb-2">Discount (%) :</label>
                                <input
                                    type="number"
                                    name="discount"
                                    value={formik.values.discount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Entrer le pourcentage de discount"
                                    className="p-2 border rounded w-full"
                                />
                                {formik.touched.discount && formik.errors.discount && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.discount}</p>
                                )}
                            </div>
                        ) : (
                            <div>
                                <label className="block text-sm font-medium mb-2">Prix fixe :</label>
                                <input
                                    type="number"
                                    name="price"
                                    value={formik.values.price}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Entrer le prix fixe"
                                    className="p-2 border rounded w-full"
                                />
                                {formik.touched.price && formik.errors.price && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.price}</p>
                                )}
                            </div>
                        )}

                        {/* Dates de début et de fin */}
                        <div className="flex flex-col gap-4 md:flex-row">
                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium mb-2">Date de début :</label>
                                <Calendar
                                    value={formik.values.startAt}
                                    onChange={(e) => formik.setFieldValue('startAt', e.value)}
                                    showTime
                                    dateFormat='dd/mm/yy'

                                    className="w-full"
                                />
                                {formik.touched.startAt && formik.errors.startAt && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.startAt}</p>
                                )}
                            </div>
                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium mb-2">Date de fin :</label>
                                <Calendar
                                    value={formik.values.endAt}
                                    onChange={(e) => formik.setFieldValue('endAt', e.value)}
                                    showTime
                                    dateFormat="dd/mm/yy"
                                    className="w-full"
                                />
                                {formik.touched.endAt && formik.errors.endAt && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.endAt}</p>
                                )}
                            </div>
                        </div>

                        {/* Sélection du pays */}
                        <div>
                            <label className="block text-sm font-medium mb-2">Pays :</label>
                            {loadingCountries ? (
                                <div><LoadingData /></div>
                            ) : (
                                <Dropdown
                                    value={formik.values.country}
                                    options={countries?.map((ctry) => ({
                                        label: ctry.name,
                                        value: ctry,
                                    })) || []}
                                    onChange={(e) => formik.setFieldValue('country', e.value)}
                                    placeholder="Sélectionner un pays"
                                    className="w-full"
                                />
                            )}
                            {formik.touched.country && formik.errors.country && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.country}</p>
                            )}
                        </div>

                        {/* Recherche de produit */}
                        {selectedCountryCode && (
                            <div>
                                <label className="block text-sm font-medium mb-2">Rechercher un produit :</label>
                                <input
                                    type="text"
                                    value={productSearch}
                                    onChange={(e) => setProductSearch(e.target.value)}
                                    placeholder="Rechercher par label"
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                        )}

                        {/* Dropdown pour la sélection des produits */}
                        {selectedCountryCode && (
                            <div>
                                <label className="block text-sm font-medium mb-2">Produits :</label>
                                {loadingProducts ? (
                                    <div><LoadingData /></div>
                                ) : (
                                    <MultiSelect
                                        value={formik.values.product}
                                        options={productOptions || []}
                                        onChange={(e) => formik.setFieldValue('product', e.value)}
                                        placeholder="Sélectionner les produits"
                                        optionLabel="label"
                                        filter
                                        filterPlaceholder="Chercher par label"
                                        className="w-full"
                                    />
                                )}
                                {formik.touched.product && formik.errors.product && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.product}</p>
                                )}
                            </div>
                        )}

                        {/* Status */}
                        <div>
                            <label className="block text-sm font-medium mb-2">Status :</label>
                            <InputSwitch checked={formik.values.status == 1 ? true : false} onChange={(e) => formik.setFieldValue('status', e.value)} />

                        </div>

                        {/* Bouton de soumission */}
                        <div className="flex justify-center">
                            <PrimeButton
                                type="submit"
                                label={formik.isSubmitting ? "Création en cours..." : "Créer la Promotion"}
                                // label={"Créer la Promotion"}
                                icon={formik.isSubmitting ? "pi pi-spinner pi-spin" : "pi pi-check"}
                                className="p-button-success text-xs"
                            disabled={formik.isSubmitting}
                            />
                        </div>
                    </form>
                </Card>
            </div>
        </FormikProvider>
    );
};

export default PromotionAdd;
