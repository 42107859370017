import React from "react";
import { ColumnDef, CellContext } from "@tanstack/react-table";
import CountryFlag from "@components/Global/CountryFlag";
import { formatDateOrders } from "@components/orders/helper";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { CodePromo } from "@utils/interfaces/CodePromo";
import CodePromoStatusDropdown from "./CodePromoStatusDropdown";
import { formatDate } from "@mbs-dev/react-helpers";

export const CodePromoColumns: ColumnDef<CodePromo>[] = [
    {
        header: "Nom",
        accessorKey: "name",
        cell: ({ row }: CellContext<CodePromo, unknown>) => <span>{row.original.name}</span>,
    },
    {
        header: "Discount",
        accessorKey: "discount",
        cell: ({ row }: CellContext<CodePromo, unknown>) => <span>{row.original.discount}%</span>,
    },
    {
        header: "Date de début",
        accessorKey: "startAt",
        cell: ({ row }: CellContext<CodePromo, unknown>) => (
            <span>{formatDate(row.original.startAt)}</span>
        ),
    },
    {
        header: "Date de fin",
        accessorKey: "endAt",
        cell: ({ row }: CellContext<CodePromo, unknown>) => (
            <span>{formatDate(row.original.endAt)}</span>
        ),
    },
    {
        header: "Pays",
        accessorKey: "country",
        cell: ({ row }: CellContext<CodePromo, unknown>) => (
            <div className="flex items-center gap-2">
                <CountryFlag countryCode={row.original.country.code} />
                <span>{row.original.country.name}</span>
            </div>
        ),
    },
    {
        header: "Statut",
        accessorKey: "status",
        cell: ({ row }: CellContext<CodePromo, unknown>) => (
            <CodePromoStatusDropdown
                promotionId={row.original.id}
                currentStatus={row.original.status}
            />
        ),
    },
    {
        header: "Créé le",
        accessorKey: "createdAt",
        cell: ({ row }: CellContext<CodePromo, unknown>) => (
            <span>{formatDateOrders(row.original.createdAt)}</span>
        ),
    },
    {
        header: "Actions",
        id: "actions",
        cell: ({ row }: CellContext<CodePromo, unknown>) => (
            <div className="flex space-x-2">
                <Link to={`edit/${row.original.id}`}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info p-button-sm"
                        tooltip="Edit"
                        tooltipOptions={{ position: 'top' }}
                    />
                </Link>
                <Link to={`delete/${row.original.id}`}>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-sm"
                        tooltip="Supprimer"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
            </div>
        ),
    },
];
