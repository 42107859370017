// src/components/OrderStatusDropdown.tsx
import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl } from '@helpers/Helpers';
import { notify } from '@mbs-dev/react-helpers';
import { PaymentStatus } from './helper';



interface OrderPaymentStatusDropdownProps {
    orderId: string;
    endpoint:string;
    currentStatus: PaymentStatus | string;
}

interface PaymentStatusOption {
    label: string;
    value: PaymentStatus;
    icon: string;
    color: string; // Background color
}

// Options du dropdown avec icônes et couleurs personnalisées
const statusOptions: PaymentStatusOption[] = [
    { label: 'En attente', value: PaymentStatus.PENDING, icon: 'pi pi-clock', color: 'bg-yellow-500' },
    { label: 'Complété', value: PaymentStatus.COMPLETED, icon: 'pi pi-check-circle', color: 'bg-green-500' },
    { label: 'Payé', value: PaymentStatus.PAID, icon: 'pi pi-dollar', color: 'bg-blue-500' },
    { label: 'Non payé', value: PaymentStatus.UNPAID, icon: 'pi pi-times', color: 'bg-red-500' },
];

const PaymentStatusDropdown : React.FC<OrderPaymentStatusDropdownProps> = ({ orderId, currentStatus , endpoint }) => {
    const [selectedStatus, setSelectedStatus] = useState(currentStatus);
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();

    // Mutation pour mettre à jour le statut via une requête PUT
    const mutation = useMutation({
        mutationFn: async (newStatus: string) => {
            const response = await apiRequest({
                route: `${apiUrl}/${endpoint}/${orderId}`,
                method: 'PUT',
                data: { "paymentStatus": newStatus },
                token: `${localStorage.getItem("userToken")}`,
                headers: {
                    Accept: 'application/ld+json',
                },
            });
            notify.successNotify("Status updated successfully");
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [endpoint] });
        },
        onError: (error: any) => {
            notify.warningNotify("Erreur lors de la mise à jour du statut");
        },
    });

    // Gestion du changement de statut
    const handleStatusChange = (e: { value: PaymentStatus | string }) => {
        const newStatus = e.value;
        setSelectedStatus(newStatus);
        mutation.mutate(newStatus);
    };

    // Récupération de la couleur actuelle du statut sélectionné
    const selectedOption = statusOptions.find(opt => opt.value === selectedStatus);
    const selectedColor = selectedOption ? selectedOption.color : 'bg-gray-200';

    // Template personnalisé pour l'élément dans la liste
    const itemTemplate = (option: any) => {
        return (
            <div className="flex items-center space-x-2">
                <i className={`${option.icon} text-xl text-black`}></i>
                <span className="font-medium">{option.label}</span>
            </div>
        );
    };

    // Template pour afficher la valeur sélectionnée
    const selectedItemTemplate = (option: any) => {
        return option ? (
            <div className={`rounded-lg text-white ${option.color} transition-all duration-200`}>
                <i className={`${option.icon} text-lg text-white`}></i>
            </div>
        ) : (
            <span className="text-gray-500">Select a Status</span>
        );
    };

    return (
        <div className="relative">
            <Dropdown
                value={selectedStatus}
                options={statusOptions}
                onChange={handleStatusChange}
                optionLabel="label"
                placeholder="Select a Status"
                itemTemplate={itemTemplate}
                valueTemplate={(option: any) => selectedItemTemplate(option)}
                className={`shadow-md border rounded-lg p-0 w-auto transition-all duration-200 ${selectedColor}`}
                panelClassName="w-auto"
            />
        </div>
    );
};

export default PaymentStatusDropdown ;
