// StockInput.tsx
import React from 'react';

interface StockInputProps {
    stock: number;
    setStock: (value: number) => void;
    error?: string;
    touched?: boolean;
}

const StockInput: React.FC<StockInputProps> = ({
    stock,
    setStock,
    error,
    touched,
}) => {
    return (
        <div>
            <label className="block text-sm font-bold mb-2">Stock :</label>
            <input
                type="number"
                value={stock}
                onChange={(e) => setStock(Number(e.target.value))}
                className="w-full p-2 border rounded"
                placeholder="Entrez le stock"
            />
            {touched && error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
            )}
        </div>
    );
};

export default StockInput;
