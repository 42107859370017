// ReviewActions.tsx
import React from 'react';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useApiRequest from '@mbs-dev/api-request';
import { notify } from '@mbs-dev/react-helpers';
import { apiUrl } from '@helpers/Helpers';

interface ReviewActionsProps {
    reviewId: number;
    currentStatus: boolean;
}

const ReviewActions: React.FC<ReviewActionsProps> = ({ reviewId, currentStatus }) => {
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();

    // Mutation pour mettre à jour le statut d'un avis
    const mutation = useMutation({
        mutationFn: async (newStatus: boolean) => {
            const response = await apiRequest({
                route: `${apiUrl}/reviews/${reviewId}`,
                method: 'PUT',
                data: { status: newStatus },
            });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['reviews'] });
            notify.successNotify('Le statut a été mis à jour avec succès.');
        },
        onError: () => {
            notify.errorNotify("Erreur lors de la mise à jour du statut.");
        },
    });

    const handleUpdateStatus = (newStatus: boolean) => {
        if (newStatus === currentStatus) {
            notify.infoNotify('Le statut est déjà à jour.');
            return;
        }
        mutation.mutate(newStatus)
    };

    return (
        <div className="flex space-x-2">
            <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger p-button-sm"
                tooltip="Marquer comme Refusé"
                tooltipOptions={{ position: 'top' }}
                onClick={() => handleUpdateStatus(false)}
                disabled={mutation.isPending}
            />
            <Button
                icon="pi pi-check"
                className="p-button-rounded p-button-success p-button-sm"
                tooltip="Marquer comme Validé"
                tooltipOptions={{ position: 'top' }}
                onClick={() => handleUpdateStatus(true)}
                disabled={mutation.isPending}
            />
        </div>
    );
};

export default ReviewActions;
