import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import LoadingData from '@components/Loading/LoadingData';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl } from '@helpers/Helpers';
import { useQuery } from '@tanstack/react-query';
import usePagination from '@hooks/usePagination';
import Pagination from '@components/pagination/Pagination';
import debounce from 'lodash.debounce';
import HeaderCard from '@components/UI/Header/HeaderCard';

interface WebsiteInfo {
    id: number;
    primaryPhone: string;
    secondaryPhone: string;
    primaryEmail: string;
    secondaryEmail: string;
    address: string;
    workingTime: string;
    socialMedia: { id: number; name: string; url: string }[];
    createdAt: string;
}

const WebsiteInfoList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // Gestion de la pagination et filtres
    const [totalItems, setTotalItems] = useState(0);
    const {
        currentPage,
        totalPages,
        setPage,
    } = usePagination({
        totalItems,
        itemsPerPage: 10, // Nombre fixe d'éléments par page
    });

    const params = useMemo(() => ({ page: currentPage, items_per_page: 10 }), [currentPage]);

    // Fetch des données `website_infos`
    const fetchWebsiteInfos = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/website_infos.jsonld`,
            method: 'GET',
            params,
            headers: {
                Accept: 'application/ld+json',
            },
        });

        if (response.data && response.data['hydra:totalItems']) {
            setTotalItems(response.data['hydra:totalItems']);
        } else {
            setTotalItems(0);
        }

        return response.data ? response.data['hydra:member'] : [];
    }, [apiRequest, params]);

    const { data: websiteInfos = [], isLoading, error, refetch } = useQuery({
        queryKey: ['website_infos', params],
        queryFn: fetchWebsiteInfos
    });

    if (error) {
        return (
            <div className="text-center mt-4">
                <p className="text-red-500">Failed to load website information. Please try again.</p>
                <Button label="Retry" onClick={() => refetch()} />
            </div>
        );
    }

    return (
        <div className="max-w-5xl mx-auto my-4 px-2 sm:px-4">
            <Card className="shadow-lg border-round-lg">
                <HeaderCard title="Website Information"  />

                {/* Table */}
                <div className="px-4 py-1 overflow-x-auto">
                    {isLoading ? (
                        <LoadingData />
                    ) : (
                        <div className="grid place-content-center mt-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {websiteInfos.map((info:WebsiteInfo) => (
                                <div key={info.id} className="p-4 border rounded-lg shadow-md bg-white">
                                    <h3 className="text-lg font-bold text-green-600">📞 {info.primaryPhone}</h3>
                                    <p className="text-sm text-gray-600">📧 {info.primaryEmail}</p>
                                    <p className="text-sm text-gray-600">🏠 {info.address}</p>
                                    <p className="text-sm text-gray-600">🕒 {info.workingTime}</p>

                                    <div className="mt-2">
                                        {info.socialMedia.map((social) => (
                                            <a key={social.id} href={social.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mr-2">
                                                {social.name}
                                            </a>
                                        ))}
                                    </div>

                                    <div className="mt-4 flex justify-end">
                                        <Link to={`edit/${info.id}`} className="text-blue-500 hover:underline btn ">✏️ Modifier</Link>
                                    </div>
                                </div>
                            ))}
                        </div>

                    )}
                </div>
            </Card>
        </div>
    );
};

export default WebsiteInfoList;
