// src/components/products/add/ProductTypeSelector.tsx

import React from 'react';
import 'primeicons/primeicons.css';

interface ProductTypeSelectorProps {
    productType: string;
    setProductType: (value: string) => void;
    options: { label: string; value: string }[];
    error?: string;
    touched?: boolean;
}

const getIcon = (value: string) => {
    switch (value) {
        case 'parent':
            return 'pi pi-user';
        case 'variant':
            return 'pi pi-cog';
        case 'final_solo':
            return 'pi pi-star';
        case 'final_with_parent':
            return 'pi pi-sitemap';
        default:
            return 'pi pi-question';
    }
};

const ProductTypeSelector: React.FC<ProductTypeSelectorProps> = ({
    productType,
    setProductType,
    options,
    error,
    touched,
}) => {
    return (
        <div>
            <label className="block text-sm font-extrabold mb-2">Type de Produit :</label>
            <div className="flex flex-wrap gap-4">
                {options.map((option) => (
                    <div
                        key={option.value}
                        onClick={() => setProductType(option.value)}
                        className={`cursor-pointer p-4 border rounded-lg flex-1 flex flex-col items-center justify-center transition-transform transform hover:scale-105 ${productType === option.value
                            ? 'bg-green-100 border-green-500'
                            : 'bg-white border-gray-300'
                            }`}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                setProductType(option.value);
                            }
                        }}
                        aria-pressed={productType === option.value}
                    >
                        <i className={`${getIcon(option.value)} text-2xl mb-2`} />
                        <span className="text-md font-semibold">{option.label}</span>
                    </div>
                ))}
            </div>
            {touched && error && (
                <p className="text-red-500 text-xs mt-2">{error}</p>
            )}
        </div>
    );
};

export default ProductTypeSelector;
