// Stock.tsx
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import LoadingData from "@components/Loading/LoadingData";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import EditableField from "@components/products/edit/EditableField";
import { updateProduct } from "@utils/api/Product";
import { notify } from "@mbs-dev/react-helpers";

interface StockProduct {
    id: number;
    label: string;
    stock: number;
    disponibilite: boolean;
}

const Stock: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();

    // Récupération et transformation des données
    const fetchStockProducts = async (): Promise<StockProduct[]> => {
        const response = await apiRequest({
            route: `${apiUrl}/products.jsonld`,
            method: "GET",
            headers: { Accept: "application/ld+json" },
            params: {
                parent: "NULL",
                as_admin: "true",
            },
        });

        const rawProducts = response.data?.["hydra:member"] || [];
        const availableProducts: StockProduct[] = [];

        rawProducts.forEach((item: any) => {
            if (item.type === "PARENT" && Array.isArray(item.child)) {
                // Pour un produit PARENT, parcourir tous les enfants et ajouter ceux disponibles
                item.child.forEach((child: any) => {
                    if (child.disponibilite) {
                        availableProducts.push({
                            id: child.id,
                            label: child.label || "N/A",
                            stock: child.stock ?? 0,
                            disponibilite: child.disponibilite,
                        });
                    }
                });
            } else if (item.type === "FINAL") { 
                // Pour un produit FINAL, utiliser le premier enfant si disponible
                if (item.child && item.child.length > 0 ) {
                    availableProducts.push({
                        id: item.id,
                        label: item.child[0].label || "N/A",
                        stock: item.stock ?? 0,
                        disponibilite: item.child[0].disponibilite,
                    });
                }
            }
        });

        return availableProducts;
    };

    const { data: products, isLoading, error } = useQuery<StockProduct[]>({
        queryKey: ["stockProducts"],
        queryFn: fetchStockProducts,
    });

    const productMutation = useMutation({
        mutationFn: ({ id, formData }: { id: any; formData: FormData }) =>
            updateProduct(apiRequest, id, formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["stockProducts"] });
        },
        onError: (error: any) => {
            notify.errorNotify(error.message || "Erreur lors de la mise à jour");
        },
    });

    const handleUpdate = async (field: string, value: string, id: any) => {
        const formData: FormData = new FormData();
        formData.append(field, value);
        await productMutation.mutateAsync({ id, formData });
    };

    if (isLoading) return <LoadingData />;
    if (error) return <ErrorLoadingData entity="products" />;

    return (
        <div className="max-w-4xl mx-auto my-4">
            <Card className="p-4 shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Stock</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            <tr className="bg-blue-500 text-white uppercase text-xs">
                                <th className="py-2 px-4 text-left">Produit</th>
                                <th className="py-2 px-4 text-left">Stock</th>
                                <th className="py-2 px-4 text-left">Disponibilité</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products &&
                                products.map((product) => (
                                    <tr
                                        key={product.id}
                                        className="border-b hover:bg-blue-50 transition-colors"
                                    >
                                        <td className="py-2 px-4">{product.label}</td>
                                        <td className="py-2 px-4">
                                            <EditableField
                                                value={product.stock + ""}
                                                onSave={(newValue) =>
                                                    handleUpdate("stock", newValue, product.id)
                                                }
                                                placeholder="N/A"
                                            />
                                        </td>
                                        <td className="py-2 px-4">
                                            <EditableField
                                                value={product.disponibilite}
                                                inputType="boolean"
                                                onSave={(newValue) =>
                                                    handleUpdate("disponibilite", newValue, product.id)
                                                }
                                                displayValue={
                                                    product.disponibilite ? "Disponible" : "Indisponible"
                                                }
                                                placeholder="N/A"
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </div>
    );
};

export default Stock;
