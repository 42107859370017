// AvailabilityToggle.tsx
import React from 'react';
import { ToggleButton } from 'primereact/togglebutton';

interface AvailabilityToggleProps {
    availability: boolean;
    setAvailability: (value: boolean) => void;
    error?: string;
    touched?: boolean;
}

const AvailabilityToggle: React.FC<AvailabilityToggleProps> = ({
    availability,
    setAvailability,
    error,
    touched,
}) => {
    return (
        <div className="flex items-center">
            <label className="block text-sm font-bold mb-2">Disponibilité :</label>
            <ToggleButton
                checked={availability}
                onChange={(e) => setAvailability(e.value)}
                onLabel="Oui"
                offLabel="Non"
                className="ml-4"
            />
            {touched && error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
            )}
        </div>
    );
};

export default AvailabilityToggle;
