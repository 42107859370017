import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import EditableField from "./EditableField";
import CountryFlag from "@components/Global/CountryFlag";
import { Button } from "primereact/button";
import { useCallback, useState } from "react";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import { useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";

interface PriceSectionProps {
    productPrice: any[];
    onPriceUpdate: (priceId: string, index: any, newPrice: string) => Promise<void>;
    onAddPrice: (countryId: string, price: string) => void;
    formatCurrency: (amount: number, currencyCode: string) => string;
}

const PriceSection: React.FC<PriceSectionProps> = ({
    productPrice,
    onPriceUpdate,
    onAddPrice,
    formatCurrency,
}) => {
    const [isAddPriceDialogVisible, setIsAddPriceDialogVisible] = useState(false);
    const [selectedCountryForPrice, setSelectedCountryForPrice] = useState<any>(null);
    const [newCountryPrice, setNewCountryPrice] = useState('');
    const { apiRequest } = useApiRequest();
    const params = {};

    // Récupération de la liste complète des pays
    const fetchCountries = async () => {
        const response = await apiRequest({
            route: `${apiUrl}/countries.jsonld`,
            method: 'GET',
            params,
            headers: {
                Accept: 'application/ld+json',
            },
        });
        return response.data ? response.data['hydra:member'] : [];
    };

    const { data: countries, isLoading: isCountriesLoading, error: countriesError } = useQuery<any>({
        queryKey: ['countries', params],
        queryFn: fetchCountries
    });

    // Filtrer les pays pour n'afficher que ceux qui ne sont pas déjà saisis
    const availableCountries = countries
        ? countries.filter((country: any) =>!productPrice.some((price: any) => price.country.id === country.id) ): [];

    const handleAddCountryPrice = async () => {
        if (selectedCountryForPrice && newCountryPrice) {
            await onAddPrice(selectedCountryForPrice.id, newCountryPrice);
            setIsAddPriceDialogVisible(false);
            setSelectedCountryForPrice(null);
            setNewCountryPrice('');
        }
    };

    return (
        <div>
            <div className="flex items-center justify-between mb-2">
                <h3 className="text-lg font-semibold text-gray-800">Prix :</h3>
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-success"
                    tooltip="Ajouter un prix par pays"
                    onClick={() => setIsAddPriceDialogVisible(true)}
                />
            </div>
            {productPrice && productPrice.length > 0 ? (
                <div className="space-y-2">
                    {productPrice.map((price: any, index: any) => (
                        <div
                            key={price.id}
                            className="flex items-center justify-between text-sm bg-gray-50 p-2 rounded-lg"
                        >
                            <div className="flex items-center gap-2">
                                <CountryFlag countryCode={price.country.code} size={16} />
                                <span className="text-gray-700">{price.country.name}</span>
                            </div>
                            <EditableField
                                value={price.price.toString()}
                                displayValue={formatCurrency(price.price, price.country.currency)}
                                onSave={(newValue) => onPriceUpdate(price.id, index, newValue)}
                                inputType="text"
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-gray-600 text-sm">Prix non disponible.</p>
            )}
            <Dialog
                header="Ajouter un prix par pays"
                visible={isAddPriceDialogVisible}
                style={{ width: '40vw' }}
                onHide={() => setIsAddPriceDialogVisible(false)}
                dismissableMask
                closable
            >
                <div className="flex flex-col gap-4">
                    {isCountriesLoading ? (
                        <div><ProgressSpinner /></div>
                    ) : countriesError ? (
                        <div className="text-red-500">Erreur lors du chargement des pays.</div>
                    ) : (
                        <>
                            <div className="flex flex-col gap-1">
                                <label className="font-semibold text-gray-700">Sélectionner un pays :</label>
                                <Dropdown
                                    value={selectedCountryForPrice}
                                    options={availableCountries}
                                    onChange={(e) => setSelectedCountryForPrice(e.value)}
                                    optionLabel="name"
                                    placeholder={availableCountries.length ? "Choisir un pays" : "Tous les pays sont saisis"}
                                    className="w-full"
                                    disabled={!availableCountries.length}
                                />
                            </div>
                            <div className="flex flex-col gap-1">
                                <label className="font-semibold text-gray-700">Prix :</label>
                                <InputText
                                    value={newCountryPrice}
                                    onChange={(e) => setNewCountryPrice(e.target.value)}
                                    placeholder="Entrer le prix"
                                    className="w-full"
                                />
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    label="Annuler"
                                    icon="pi pi-times"
                                    className="p-button-outlined p-button-danger"
                                    onClick={() => setIsAddPriceDialogVisible(false)}
                                />
                                <Button
                                    label="Ajouter"
                                    icon="pi pi-check"
                                    className="p-button-success"
                                    onClick={handleAddCountryPrice}
                                    disabled={!selectedCountryForPrice || !newCountryPrice}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

export default PriceSection;
