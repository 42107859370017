// BarcodeInput.tsx
import React from 'react';

interface BarcodeInputProps {
    barcode: string;
    setBarcode: (value: string) => void;
    error?: string;
    touched?: boolean;
}

const BarcodeInput: React.FC<BarcodeInputProps> = ({
    barcode,
    setBarcode,
    error,
    touched,
}) => {
    return (
        <div>
            <label className="block text-sm font-bold mb-2">Barcode :</label>
            <input
                type="text"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="Entrez le barcode"
            />
            {touched && error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
            )}
        </div>
    );
};

export default BarcodeInput;
