import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Tag } from "primereact/tag";
import { Link } from "react-router-dom";
import { SerializedOrder } from "@utils/interfaces/order";
import OrderStatusDropdown from "./OrderStatusDropdown";
import { formatDateOrders, getPaymentMethodBadge, OrderStatus, PaymentStatus } from "./helper";
import PaymentStatusDropdown from "./PaymentStatusDropdown";



export const orderColumns: ColumnDef<SerializedOrder>[] = [

    {
        header: "Client",
        accessorKey: "userFullName",
        cell: ({ row }: any) => <span>{row.original.userFullName}</span>,
    },
    {
        header: "Adresse de livraison",
        accessorKey: "deliveryAddress",
        cell: ({ row }: any) => <span>{row.original.deliveryAddress}</span>,
    },
    {
        header: "Quantité",
        accessorKey: "itemsQuantity",
        cell: ({ row }: any) => <span>{row.original.itemsQuantity}</span>,
    },
    {
        header: "Date de create",
        accessorKey: "createdAt",
        cell: ({ row }: any) => formatDateOrders(row.original.createdAt),
    },
    {
        header: "Prix",
        accessorKey: "price",
        cell: ({ row }: any) => (
            <Tag
                value={`${row.original.price} €`}
                className="px-3 py-1 w-24 text-sm font-semibold bg-gray-200 text-gray-700 rounded-lg"
            />
        ),
    },
    {
        header: "Statut",
        accessorKey: "orderStatus",
        cell: ({ row }: any) => (
            <OrderStatusDropdown
                endpoint="orders"
                orderId={row.original.id}
                currentStatus={row.original.orderStatus || OrderStatus.PENDING}
            />
        ),
    },
    {
        header: "Méthode de paiement",
        accessorKey: "paymentMethod",
        cell: ({ row }: any) => {
            const { label, color, icon } = getPaymentMethodBadge(row.original.paymentMethod);
            return (
                <div className={`flex items-center gap-2 px-3 py-1 rounded-full text-white ${color}`}>
                    <i className={`${icon} text-sm`}></i>
                    <span className="text-sm font-medium">{label}</span>
                </div>
            );
        },
    },
    {
        header: "Statut de paiement",
        accessorKey: "paymentStatus",
        cell: ({ row }: any) => (
            <PaymentStatusDropdown
                endpoint="orders"
                orderId={row.original.id}
                currentStatus={row.original.paymentStatus || PaymentStatus.PENDING}
            />
        ),
    },

    {
        header: "Actions",
        id: "actions",
        cell: ({ row }: any) => (
            <div className="flex space-x-2">
                <Link to={`details/${row.original.id}`}>
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-help p-button-sm"
                        tooltip="Afficher les détails"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info p-button-sm"
                    tooltip="Modifier le statut"
                    tooltipOptions={{ position: "top" }}
                    onClick={() => console.log("Modifier le statut de la commande :", row.original.id)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-sm"
                    tooltip="Supprimer"
                    tooltipOptions={{ position: "top" }}
                    onClick={() => console.log("Supprimer la commande :", row.original.id)}
                />
            </div>
        ),
    },
];
