// reviewColumns.tsx
import { ColumnDef } from '@tanstack/react-table';
import { Tag } from 'primereact/tag';
import { SerializedReview } from '@utils/interfaces/Review';
import { formatDateOrders } from '@components/orders/helper';
import { Rating } from 'primereact/rating';
import ReviewActions from './ReviewActions'; // Ajustez le chemin selon votre arborescence

export const reviewColumns: ColumnDef<SerializedReview>[] = [
    {
        header: 'Client',
        accessorKey: 'username',
        cell: ({ row }: any) => <span>{row.original.username}</span>,
    },
    {
        header: 'Produit',
        accessorKey: 'product',
        cell: ({ row }: any) => <span>{row.original.product}</span>,
    },
    {
        header: 'Rating',
        accessorKey: 'rating',
        cell: ({ row }: any) => (
            <Rating value={row.original.rating} readOnly cancel={false} />
        ),
    },
    {
        header: 'Message',
        accessorKey: 'message',
        cell: ({ row }: any) => <span>{row.original.message}</span>,
    },
    {
        header: 'Statut',
        accessorKey: 'status',
        cell: ({ row }: any) =>
            row.original.status ? (
                <Tag severity="success">Validé</Tag>
            ) : (
                <Tag severity="danger">Refusé</Tag>
            ),
    },
    {
        header: 'Date de création',
        accessorKey: 'createdAt',
        cell: ({ row }: any) => formatDateOrders(row.original.createdAt),
    },
    {
        header: 'Actions',
        id: 'actions',
        cell: ({ row }: any) => (
            <ReviewActions reviewId={row.original.id} currentStatus={row.original.status} />
        ),
    },
];
