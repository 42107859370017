import React, { useState, useCallback } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import CountryFlag from '@components/Global/CountryFlag';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { updateProduct } from '@utils/api/Product';
import { notify } from '@mbs-dev/react-helpers';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl } from '@helpers/Helpers';
import EditableField from '../edit/EditableField';
import PriceSection from '../edit/PriceSection';

interface ProductInfoProps {
    product: any;
    selectedLanguage: any;
    onUpdateSuccess: () => void;
}

const formatCurrency = (amount: number, currencyCode: string): string => {
    return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
};


const ProductInfo: React.FC<ProductInfoProps> = ({ product, selectedLanguage, onUpdateSuccess }) => {
    const queryClient = useQueryClient();
    const { apiRequest } = useApiRequest();


    // Récupération de la traduction sélectionnée (ou la première disponible)
    const translation =
        product.productTranslates.find((pt: any) => pt.language.code === selectedLanguage.code) ||
        product.productTranslates[0];

    const attributes = product.productAttributes
        .map((attr: any) => attr.attribute.name)
        .join(', ');

    // Mutation principale pour les mises à jour
    const productMutation = useMutation({
        mutationFn: (formData: FormData) => updateProduct(apiRequest, product.id, formData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product'] });
            onUpdateSuccess();
        },
        onError: (error: any) => {
            notify.errorNotify(error.message || "Erreur lors de la mise à jour");
        },
    });

    const handleUpdate = async (field: string, value: string, id?: string) => {
        const formData = new FormData();
        formData.append(field, value);
        await productMutation.mutateAsync(formData);
    };

    const handleTranslationUpdate = async (field: string, value: string) => {
        const formData = new FormData();
        formData.append(`productTranslates[${selectedLanguage.idIndex}][id]`, selectedLanguage.idRow);
        formData.append(`productTranslates[${selectedLanguage.idIndex}][${field}]`, value);
        await productMutation.mutateAsync(formData);
    };

    const handlePriceUpdate = async (priceId: string, indexProc: string, newPrice: string) => {
        const formData = new FormData();
        formData.append(`productPrices[${indexProc}][id]`, priceId);
        formData.append(`productPrices[${indexProc}][price]`, newPrice)
        await productMutation.mutateAsync(formData);
    };

    // Fonction pour ajouter un nouveau prix par pays
    const addPrice = async (countryId: string, price: string) => {
        const formData = new FormData();
        formData.append('productPrices[0][country]', `api/countries/${countryId}`);
        formData.append('productPrices[0][price]', price);
        // console.log(formData);

        await productMutation.mutateAsync(formData);
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
            {/* Titre et attributs */}
            <div className="mb-4">
                <h2 className="text-xl font-bold text-gray-800 mb-2 flex items-center gap-2">
                    <label>Titre :</label>
                    <EditableField
                        value={translation.title || ''}
                        onSave={(newValue) => handleTranslationUpdate('title', newValue)}
                        placeholder="N/A"
                    />
                    {attributes && (
                        <span className="text-sm text-gray-500 font-normal ml-2">
                            ({attributes})
                        </span>
                    )}
                </h2>
            </div>

            {/* Stock, Référence, Code-barres */}
            <div className="flex flex-wrap gap-2 mb-4">
                {[
                    {
                        label: "Stock",
                        value: product.stock || '',
                        field: "stock",
                        bg: "from-green-50 to-teal-50",
                        icon: "pi pi-box",
                    },
                    {
                        label: "Référence",
                        value: product.reference || '',
                        field: "reference",
                        bg: "from-blue-50 to-indigo-50",
                        icon: "pi pi-hashtag",
                    },
                    {
                        label: "Code-barres",
                        value: product.barcode || '',
                        field: "barcode",
                        bg: "from-yellow-50 to-orange-50",
                        icon: "pi pi-barcode",
                    },
                ].map(({ label, value, field, bg, icon }) => (
                    <div
                        key={field}
                        className={`flex items-center gap-3 px-3 py-2 rounded-xl shadow-md bg-gradient-to-r ${bg} hover:shadow-lg transition-shadow`}
                    >
                        <i className={`${icon} text-xl text-gray-600`} />
                        <div className="flex flex-col">
                            <span className="text-sm font-semibold text-gray-700">{label}</span>
                            <EditableField
                                value={value}
                                onSave={(newValue) => handleUpdate(field, newValue)}
                                placeholder="N/A"
                            />
                        </div>
                    </div>
                ))}
                <div
                    key={"disponibiliter"}
                    className={`flex items-center gap-3 px-3 py-2 rounded-xl shadow-md bg-gradient-to-r from-green-50 to-teal-50 hover:shadow-lg transition-shadow`}
                >
                    <div className="flex flex-col">
                        <span className="text-sm font-semibold text-gray-700">Disponibilite</span>
                        <EditableField
                            value={product.disponibilite}
                            inputType="boolean"
                            onSave={(newValue) =>
                                handleUpdate(
                                    "disponibilite",
                                    newValue,
                                    product.id
                                )
                            }
                            displayValue={
                                product.disponibilite ? "Disponible" : "Indisponible"
                            }
                            placeholder="N/A"
                        />
                    </div>
                </div>


            </div>

            {/* Sections de traduction */}
            <Accordion multiple className="text-sm mb-4">
                <AccordionTab header={<span className="font-medium">Description</span>}>
                    <EditableField
                        value={translation.description || ''}
                        onSave={(newValue) => handleTranslationUpdate('description', newValue)}
                        inputType="richtext"
                        placeholder="Aucune description disponible."
                    />
                </AccordionTab>
                <AccordionTab header={<span className="font-medium">Comment utiliser</span>}>
                    <EditableField
                        value={translation.howToUse || ''}
                        onSave={(newValue) => handleTranslationUpdate('howToUse', newValue)}
                        inputType="richtext"
                        placeholder="Information non disponible."
                    />
                </AccordionTab>
                <AccordionTab header={<span className="font-medium">Ingrédients</span>}>
                    <EditableField
                        value={translation.ingredients || ''}
                        onSave={(newValue) => handleTranslationUpdate('ingredients', newValue)}
                        inputType="richtext"
                        placeholder="Information non disponible."
                    />
                </AccordionTab>
            </Accordion>

            {/* Section dédiée aux prix par pays */}
            <PriceSection
                productPrice={product.productPrice}
                onPriceUpdate={handlePriceUpdate}
                onAddPrice={addPrice}
                formatCurrency={formatCurrency}
            />
        </div>
    );
};

export default ProductInfo;
