// buildProductFormData.ts

import { generateSlug } from "./slugGenerator";
import { compressAndAppendImage } from "@mbs-dev/react-image-compressor";

interface ProductTranslate {
  language: number;
  title: string;
  slug: string;
  description?: string;
  howToUse?: string;
  ingredients?: string;
  image: File | null;
  productImages: (File | null)[];
}

interface CountryPrice {
  country: string;
  price: number;
}

export interface ProductFormValues {
  type: string;
  category: string;
  subCategory: string;
  parentProduct: string;
  availability: boolean;
  stock: number;
  reference: string;
  barcode: string;
  productTranslates: ProductTranslate[];
  countryPrices: CountryPrice[];
  productAttributes: { attribute: string; value: string }[];
}

const buildProductFormData = async (values: ProductFormValues): Promise<FormData> => {
  const formData = new FormData();
  const quality = 0.2;

  // Conditionally add fields depending on the product type
  switch (values.type) {
    case "parent":
      formData.append("category[0]", "api/categories/" + values.category);
      if (values.subCategory) {
        formData.append("category[1]", "api/categories/" + values.subCategory);
      }
      break;

    case "variant":
      formData.append("parent", values.parentProduct);
      break;

    case "final_solo":
      formData.append("disponibilite", values.availability ? "true" : "false");
      formData.append("stock", `${values.stock}`);
      formData.append("reference", values.reference);
      formData.append("barcode", values.barcode);
      formData.append("category[0]", "api/categories/" + values.category);
      if (values.subCategory) {
        formData.append("category[1]", "api/categories/" + values.subCategory);
      }
      break;

    case "final_with_parent":
      formData.append("disponibilite", values.availability ? "true" : "false");
      formData.append("stock", `${values.stock}`);
      formData.append("reference", values.reference);
      formData.append("barcode", values.barcode);
      formData.append("parent", values.parentProduct);

      if (values.productAttributes && Array.isArray(values.productAttributes)) {
        values.productAttributes.forEach((attr, index) => {
          formData.append(`productAttributes[${index}][attribute]`, attr.attribute);
          formData.append(`productAttributes[${index}][value]`, attr.value);
        });
      }
      break;

    default:
      // No extra fields for other types
      break;
  }

  // Add translations (common to all types)
  for (let index = 0; index < values.productTranslates.length; index++) {
    const translate = values.productTranslates[index];

    formData.append(`productTranslates[${index}][language]`, "api/languages/" + translate.language);
    formData.append(`productTranslates[${index}][title]`, translate.title);
    formData.append(`productTranslates[${index}][slug]`, generateSlug(translate.title));

    if (translate.description) {
      formData.append(`productTranslates[${index}][description]`, translate.description);
    }
    if (translate.howToUse) {
      formData.append(`productTranslates[${index}][howToUse]`, translate.howToUse);
    }
    if (translate.ingredients) {
      formData.append(`productTranslates[${index}][ingredients]`, translate.ingredients);
    }

    // Compress and append main image if it exists
    if (translate.image) {
      await compressAndAppendImage(
        translate.image as File,
        formData,
        `productTranslates[${index}][image]`,
        quality
      );
    }

    // Compress and append secondary images
    if (translate.productImages && translate.productImages.length > 0) {
      for (let imgIndex = 0; imgIndex < translate.productImages.length; imgIndex++) {
        const img = translate.productImages[imgIndex];
        if (img) {
          await compressAndAppendImage(
            img as File,
            formData,
            `productTranslates[${index}][productImages][${imgIndex}]`,
            quality
          );
        }
      }
    }
  }

  // Add country prices for final products only
  if (values.type === "final_solo" || values.type === "final_with_parent") {
    values.countryPrices.forEach((price, index) => {
      formData.append(`productPrices[${index}][country]`, "api/countries/" + price.country);
      formData.append(`productPrices[${index}][price]`, `${price.price}`);
    });
  }

  return formData;
};

export default buildProductFormData;
