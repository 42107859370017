// src/components/orders/invoice/OfflineInvoiceDocument.tsx
import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "./styleinvoice";

import { InvoiceHeader } from "./components/InvoiceHeader";
import { InvoiceDetails } from "./components/InvoiceDetails";
import { InvoiceFooter } from "./components/InvoiceFooter";
import { chunkItemsOrders } from "../helper";

const MAX_FIRST_PAGE = 17;
const MAX_OTHER_PAGES = 23;

const TableRow = React.memo(({ item }: { item: any }) => {
    const totalItem = item.quantity * item.price;
    const productLabel = item.product?.child?.[0]?.label || item.product.label;
    return (
        <View style={styles.tableRow}>
            <View style={{ width: "40%" }}>
                <Text style={styles.tableCell}>{productLabel}</Text>
            </View>
            <View style={{ width: "20%" }}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
            </View>
            <View style={{ width: "20%" }}>
                <Text style={styles.tableCell}>{`${item.price.toFixed(2)} €`}</Text>
            </View>
            <View style={{ width: "20%" }}>
                <Text style={styles.tableCell}>{`${totalItem.toFixed(2)} €`}</Text>
            </View>
        </View>
    );
});

const OfflineInvoiceDocument: React.FC<{ order: any }> = ({ order }) => {
    const pages = React.useMemo(
        () => chunkItemsOrders(order.offlineOrderItems, MAX_FIRST_PAGE, MAX_OTHER_PAGES),
        [order.offlineOrderItems]
    );

    return (
        <Document>
            {pages.map((itemsChunk, pageIndex) => (
                <Page style={styles.page} key={`page_${pageIndex}`}>
                    <InvoiceHeader />
                    {pageIndex === 0 && (
                        <InvoiceDetails
                            reference={order.reference}
                            date={new Date(order.updatedAt).toLocaleDateString()}
                            totalDue={order.price}
                            name={order.fullName}
                            email={order.email}
                            telephone={order.telephone}
                            address={`${order.address}, ${order.ville} - ${order.zipCode}`}
                        />
                    )}
                    <View style={styles.tableHeader}>
                        <View style={{ width: "40%" }}>
                            <Text style={styles.tableHeaderText}>PRODUCT</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={styles.tableHeaderText}>QTY</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={styles.tableHeaderText}>PRICE</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                            <Text style={styles.tableHeaderText}>TOTAL</Text>
                        </View>
                    </View>
                    {itemsChunk.map((item: any) => (
                        <TableRow key={item.id} item={item} />
                    ))}
                    <InvoiceFooter />
                </Page>
            ))}
        </Document>
    );
};

export default OfflineInvoiceDocument;
