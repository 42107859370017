import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { apiUrlbase } from "@helpers/Helpers";
import { Link } from "react-router-dom";

interface CategoriesTreeProps {
    categories: any;
}

const CategoriesTreeView: React.FC<CategoriesTreeProps> = ({ categories }) => {
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        const buildTreeNodes = (categories: any, parent: any = null) => {
            return categories.map((category: any) => ({
                key: category.id.toString(),
                label: category.categoryTranslates.map((t: any) => t.title).join(', '),
                data: { ...category, parent },
                icon: category.image
                    ? `${apiUrlbase}/${category.image}` // Utilisation de l'image comme icône
                    : require("@resources/imageNotFound.png"), // Image par défaut si non disponible
                children: category.subCategory?.length
                    ? buildTreeNodes(category.subCategory, category)
                    : null,
            }));
        };

        setNodes(buildTreeNodes(categories));
    }, [categories]);

    const nodeTemplate = (node: any) => {
        const { data } = node;
        const isSubCategory = !!data.parent;

        console.log('node', node)

        return (
            <div
                className={`flex flex-col md:flex-row gap-4 py-3 px-4 rounded-lg w-full ${isSubCategory ? 'bg-blue-50 hover:bg-blue-100' : 'bg-green-50 hover:bg-green-100'
                    } transition-all duration-200 shadow-sm`}
                style={{
                    borderLeft: `5px solid ${isSubCategory ? '#2196F3' : '#4CAF50'}`,
                }}
            >
                {/* Image */}
                <div className="flex-shrink-0">
                    <Image
                        src={node.icon} // Utilise l'image définie comme icône
                        alt="Category Icon"
                        width="80"
                        height="80"
                        className="rounded-md"
                        preview
                        downloadable
                    />
                </div>

                {/* Détails de la catégorie */}
                <div className="flex-grow">
                    <div
                        className={`text-lg font-semibold ${isSubCategory ? 'text-blue-800' : 'text-green-800'
                            }`}
                    >
                        {node.label} ( {node.data?.categoryTranslates[0]?.language.code} )
                    </div>
                    <div className="text-sm text-gray-700">
                        {data.categoryTranslates.map((translate: any) => (
                            <div key={translate.id} className="mb-1">
                                <div className="text-s text-gray-500">
                                    <strong>Description:</strong> {translate.description}
                                </div>
                                <div className="text-s text-gray-500">
                                    <strong>Slug:</strong> {translate.slug}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-xs text-gray-600 mt-2">
                        <strong>Total Products:</strong> {data.totalProducts}
                    </div>
                </div>

                {/* Actions */}
                <div className="flex items-center gap-2">
                    <Link to={`/dashboard/categories/edit/${data.id}`}>
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-sm p-button-success"
                            tooltip="Edit"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </Link>
                    <Link to={`/dashboard/categories/delete/${data.id}`}>
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-sm p-button-danger"
                            tooltip="Delete"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <>
            <Tree
                value={nodes}
                nodeTemplate={nodeTemplate}
                className="w-full"
                style={{
                    border: 'none',
                }}
            />
        </>

    );
};

export default CategoriesTreeView;
