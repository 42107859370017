// CountryAdd.tsx
import React from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import useApiRequest from '@mbs-dev/api-request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notify } from '@mbs-dev/react-helpers';
import { addCountry } from '@utils/api/country';
import { CountryIV } from '@utils/initValues/CountryIV';
import { CountrySchema } from '@utils/schemas/CountrySchema';
import HeaderCard from '@components/UI/Header/HeaderCard';

interface CountryData {
    name: string;
    code: string;
    currency: string;
}

const CountryAdd: React.FC = () => {
    const queryClient = useQueryClient();
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: (data: CountryData) => addCountry(apiRequest, data),
        onSuccess: () => {
            navigate('/dashboard/setting/countries');
            queryClient.invalidateQueries({ queryKey: ["countries"] });
        },
        onError: (error: Error) => {
            notify.errorNotify("Erreur lors de la création du pays");
        },
    });

    const formik = useFormik<CountryData>({
        initialValues: CountryIV,
        validationSchema: CountrySchema,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">

                <HeaderCard title="Ajouter un nouveau pays"  />

                <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                    <div>
                        <label className="block text-sm font-medium mb-2">Nom du pays</label>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                            placeholder="Entrez le nom du pays"
                        />
                        {formik.errors.name && formik.touched.name && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">Code du pays</label>
                        <InputText
                            name="code"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.code && formik.touched.code ? 'p-invalid' : ''}`}
                            placeholder="Entrez le code du pays (ex: FR)"
                        />
                        {formik.errors.code && formik.touched.code && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.code}</p>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">Devise</label>
                        <InputText
                            name="currency"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.currency && formik.touched.currency ? 'p-invalid' : ''}`}
                            placeholder="Entrez la devise (ex: EUR)"
                        />
                        {formik.errors.currency && formik.touched.currency && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.currency}</p>
                        )}
                    </div>

                    <div className="flex justify-end">
                        <Button
                            label="Ajouter le pays"
                            icon="pi pi-check"
                            className="p-button-success"
                            type="submit"
                            disabled={mutation.isPending} // Disable button while loading
                        />
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default CountryAdd;
