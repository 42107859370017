import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";
import { AddSocialMediaPayload } from "@utils/interfaces/SocialMedia";




export const getWebsiteInfoById = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/website_infos/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Website Info Not Found !!');
        // throw new Error('Erreur lors de la récupération de la Attribute');
    }
};


export const updateWebsiteInfo = async (apiRequest: any, id: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/website_infos/${id}`,
            method: 'PUT',
            data: data,
        });

        notify.successNotify('Website info mise à jour avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la mise à jour info');
    }
};


