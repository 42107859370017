// ProductDetails.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiUrlbase } from '@helpers/Helpers';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import useApiRequest from '@mbs-dev/api-request';
import { fetchProduct } from '@utils/api/Product';
import ProductImages from '@components/products/details/ProductImages';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tag } from 'primereact/tag';
import ProductInfo from '@components/products/details/ProductInfo';

const ProductDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();

    // On initialise selectedLanguage à null pour le définir dynamiquement plus tard
    const [selectedLanguage, setSelectedLanguage] = useState<any>(null);

    const { data: product, isLoading, error } = useQuery({
        queryKey: ['product', id],
        queryFn: () => fetchProduct(apiRequest, id),
    });

  
    
    // Vérifier si le produit est un parent
    const isParent = product?.type === "PARENT";
    
    // Récupérer les produits enfants
    const childProducts = isParent ? product?.child : [product];

    // Obtenir les langues disponibles à partir du premier produit enfant
    const languages =
        childProducts[0]?.productTranslates.map((translate: any, index: number) => ({
            idIndex: index,
            idRow:translate.id ,
            id:translate.language.id ,
            code: translate.language.code,
            name: translate.language.name,
        })) || [];
        
        // Dès que les langues sont disponibles et qu'aucune n'est sélectionnée, on choisit la première
        
        useEffect(() => {
            if (!selectedLanguage && languages.length > 0) {
                setSelectedLanguage(languages[0]);
            }
        }, [selectedLanguage, languages]);


        if (isLoading) {
            return (
                <div className="flex justify-center items-center h-screen">
                    <ProgressSpinner />
                </div>
            );
        }
    
        if (error) {
            return (
                <p className="text-center text-red-600">
                    Erreur : {(error as Error).message}
                </p>
            );
        }

        

        // En attendant que la langue soit définie (au cas où), on peut retourner null ou afficher un loader
        if (!selectedLanguage) return null;
        
        if (!product) return null;


    return (

        <div className="w-full flex flex-col items-center">
            <div className="w-full max-w-5xl">
                {/* En-tête */}
                <div className="flex w-full justify-between mt-6 items-center p-3 bg-green-100 border border-green-200 rounded-lg">
                    <Button
                        icon="pi pi-arrow-left"
                        onClick={() => navigate(-1)}
                        className="p-button-outlined"
                    />
                    <h1 className="text-xl font-semibold text-green-700">
                        {product?.label}
                    </h1>
                    <div className="sm:block hidden"></div>
                </div>

                {/* Boutons de sélection de langue */}
                <div className="flex flex-wrap gap-2 my-4 justify-center">
                    {languages.map((lang: any) => (
                        <button
                            key={lang.code}
                            onClick={() => setSelectedLanguage(lang)}
                            className={`px-4 py-2 rounded-full text-sm transition-colors duration-200 ${selectedLanguage.code === lang.code
                                    ? 'bg-green-500 text-white font-semibold'
                                    : 'bg-gray-200 text-gray-700 hover:bg-green-300'
                                }`}
                        >
                            {lang.name}
                        </button>
                    ))}
                </div>

                {/* Accordéon pour les produits enfants */}
                <Accordion multiple className="w-full">
                    {childProducts.map((productDetails: any, index: number) => {
                        // Sélection de la traduction en fonction de la langue sélectionnée
                        const selectedTranslation =
                            productDetails.productTranslates.find(
                                (pt: any) => pt.language.code === selectedLanguage.code
                            ) || productDetails.productTranslates[0];

                        const mainImage = `${apiUrlbase}/${selectedTranslation.image}`;
                        const images = selectedTranslation.productImages.map((img: any) => ({
                            ...img,
                            image: `${apiUrlbase}/${img.image}`,
                        }));

                        // Récupération des attributs du produit
                        const attributes = productDetails.productAttributes
                            .map((attr: any) => attr.attribute.name)
                            .join(', ');

                        // En-tête de l'onglet accordéon
                        const header = (
                            <div  className="flex items-center justify-between">
                                <h2 className="text-lg font-semibold text-gray-800">
                                    {productDetails.label}
                                    {attributes && (
                                        <span className="text-sm text-gray-500 font-normal ml-1">
                                            ({attributes})
                                        </span>
                                    )}
                                </h2>
                                <div className="flex items-center gap-2">
                                    <span className="text-sm text-gray-600">
                                        Réf: {productDetails.reference}
                                    </span>
                                    
                                    <Tag
                                        severity={productDetails.disponibilite ? 'success' : 'danger'}
                                        value={productDetails.disponibilite ? 'Disponible' : 'Indisponible'}
                                        className="text-xs"
                                    />
                                </div>
                            </div>
                        );

                        return (
                            <AccordionTab key={index} header={header}>
                                <div className="flex flex-col md:flex-row md:space-x-6">
                                    <div className="md:w-1/2 mb-6 md:mb-0">
                                        <ProductImages
                                            mainImage={mainImage}
                                            images={images}
                                            title={selectedTranslation.title}
                                        />
                                    </div>
                                    <div className="md:w-1/2">
                                        <ProductInfo
                                            product={productDetails}
                                            selectedLanguage={selectedLanguage}
                                            onUpdateSuccess={() =>
                                                queryClient.invalidateQueries({ queryKey: ['product', id] })
                                            }
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        );
                    })}
                </Accordion>
            </div>
        </div>
    );
};

export default ProductDetails;
