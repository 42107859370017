import React, { useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card } from 'primereact/card';
import { Button as PrimeButton } from 'primereact/button';
import { CategoriesEditSchema } from '../../utils/schemas/CategoriesSchema';
import { CategoriesIV } from '@utils/initValues/CategoriesIV';
import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';
import useApiRequest from '@mbs-dev/api-request';
import { getCategory, updateCategory } from '@utils/api/category';
import CategoryTranslationFields from '@components/categories/add/CategoryTranslationFields';
import FileUploadWithPreview from '@components/UI/Form/FileUploadWithPreview';
import LoadingData from '@components/Loading/LoadingData';
import { useNavigate, useParams } from 'react-router-dom';
import { notify, slugGenerator } from '@mbs-dev/react-helpers';
import { fetchLanguages } from '@utils/api/Lang';
import { apiUrlbase } from '@helpers/Helpers';
import { Language } from '@utils/interfaces/language';

interface CategoryFormData {
    image: File | null | string; // Include string to handle initial data
    banner: File | null | string;
    categoryTranslates: {
        title: string;
        slug: string;
        language: string;
        description: string;
    }[];
}

const CategoriesEdit: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id } = useParams<{ id: string }>(); // Get the category ID from the URL params

    // Fetch category data to edit
    const { data: categoryData, isLoading: isCategoryLoading, error: categoryError } = useQuery({
        queryKey: ['category', id],
        queryFn: () => getCategory(apiRequest, id),
    });

    const { data: languages, isLoading: isLanguagesLoading, error: languagesError } = useQuery<Language[]>({
        queryKey: ['languages'],
        queryFn: () => fetchLanguages(apiRequest),
    });

    const mutation = useMutation({
        mutationFn: (data: FormData) => updateCategory(apiRequest, id, data),
        onSuccess: () => {
            navigate('/dashboard/categories');
            queryClient.invalidateQueries({
                queryKey: ['categories'],
            });
        },
        onError: () => {
            notify.errorNotify('Erreur lors de la mise à jour de la catégorie');
        },
    });

    const formik = useFormik<CategoryFormData>({
        initialValues: CategoriesIV,
        validationSchema: CategoriesEditSchema,
        onSubmit: (values) => {
            const formData = new FormData();

            if (values.image instanceof File) {
                formData.append('image', values.image);
            }
            if (values.banner instanceof File) {
                formData.append('banner', values.banner);
            }

            values.categoryTranslates.forEach((translate:any, index) => {
                console.log("item " , translate) ;
                if(translate.id){
                    formData.append(`categoryTranslates[${index}][id]`,translate.id);
                }
                formData.append(`categoryTranslates[${index}][title]`, translate.title);
                formData.append(`categoryTranslates[${index}][slug]`, slugGenerator(translate.title));
                formData.append(`categoryTranslates[${index}][language]`,  `api/languages/${translate.language}`);
                formData.append(`categoryTranslates[${index}][description]`, translate.description);
            });

            console.log("form data to send to api : " , formData);
            console.log("token : " );
            
            mutation.mutate(formData);
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (categoryData) {
            formik.setValues({
                image: categoryData.image,
                banner: categoryData.banner,
                categoryTranslates: categoryData.categoryTranslates.map((translate: any) => ({
                    id:translate.id,
                    language: translate.language.id,
                    title: translate.title,
                    slug: translate.slug,
                    description: translate.description,
                })),
            });
        }
    }, [categoryData]);

    // File change handler
    const handleFileSelect = (setFieldValue: (field: string, value: any) => void, fieldName: string) => (file: File | null) => {
        setFieldValue(fieldName, file); // Update only if the file is selected
    };

    if (categoryError || languagesError) return <ErrorLoadingData entity="category or languages" />;

    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Edit Category</h2>
                    </div>

                    {isCategoryLoading || isLanguagesLoading ? (
                        <LoadingData />
                    ) : (
                        <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border border-green-200 rounded-bl-lg rounded-br-lg">
                            <div className="flex flex-col gap-4 md:flex-row">
                                <div className="md:w-1/2 w-full">
                                    <label className="block text-sm font-medium mb-2">Category Image :</label>
                                    <FileUploadWithPreview
                                        label="Choose Category Image"
                                        onFileSelect={handleFileSelect(formik.setFieldValue, 'image')}
                                        initialPreview={
                                            categoryData?.image ? `${apiUrlbase}/${categoryData.image}` : null
                                        }
                                    />
                                    {formik.touched.image && formik.errors.image && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.image}</p>
                                    )}
                                </div>

                                <div className="md:w-1/2 w-full">
                                    <label className="block text-sm font-medium mb-2">Banner Image :</label>
                                    <FileUploadWithPreview
                                        label="Choose Banner Image"
                                        onFileSelect={handleFileSelect(formik.setFieldValue, 'banner')}
                                        initialPreview={
                                            categoryData?.banner ? `${apiUrlbase}/${categoryData.banner}` : null
                                        }
                                    />
                                    {formik.touched.banner && formik.errors.banner && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.banner}</p>
                                    )}
                                </div>
                            </div>

                            <div className="mt-6">
                                <CategoryTranslationFields
                                    formik={formik}
                                    languages={languages || []}
                                />
                            </div>

                            <div className="flex justify-center">
                                <PrimeButton
                                    label="Update Category"
                                    icon="pi pi-check"
                                    className="p-button-success text-xs"
                                    type="submit"
                                />
                            </div>
                        </form>
                    )}
                </Card>
            </div>
        </FormikProvider>
    );
};

export default CategoriesEdit;
