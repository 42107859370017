
import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";


export const fetchProducts = async (apiRequest: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/products.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur products Not Found !!');
        throw new Error('Erreur lors de la récupération de produit');
    }
};
export const fetchProduct = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/products/${id}.json?`,
            method: 'GET',
            params: {
                "parent": "NULL",
                "as_admin":"true"
            }
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Product Not Found !!');
        throw new Error('Erreur lors de la récupération de la produit');
    }
};

export const addProduct = async (apiRequest: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/products`,
            method: 'POST',
            data: data,
            // requiresAuth: true,
            // token: `${localStorage.getItem('userToken')}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        notify.successNotify("Product create successfully");
    } catch (error) {
        notify.errorNotify("Erreur lors de la création de produit")
    }
};



export const updateProduct = async (apiRequest: any, id: string, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/products/${id}`,
            method: 'PUT',
            data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        notify.successNotify("Produit mis à jour avec succès");
        return response;
    } catch (error) {
        notify.errorNotify("Erreur lors de la mise à jour du produit");
        throw error;
    }
};

