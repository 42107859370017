export enum PaymentMethod {
    CREDIT_CARD = "CREDIT_CARD",
    COD = "COD",
    BANK_TRANSFER = "BANK_TRANSFER",
    CASH = "CASH",
    PAYPAL = "PAYPAL",
    STRIPE = "STRIPE",
    CMI="CMI"
}

export enum PaymentStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    PAID = "PAID",
    UNPAID = "UNPAID",
}


export enum OrderStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    COMPLETED = 'COMPLETED',
    IN_SHIPPING = 'IN_SHIPPING',
    CANCELED = 'CANCELED',
}


// Séquence et données pour le Timeline
export const orderStatusSequence = ["PENDING", "CONFIRMED", "IN_SHIPPING", "COMPLETED"];
export const timelineEvents = [
    { status: "PENDING", icon: "pi pi-clock", content: "Commande passée" },
    { status: "CONFIRMED", icon: "pi pi-check", content: "Commande confirmée" },
    { status: "IN_SHIPPING", icon: "pi pi-truck", content: "Commande en cours d'expédition" },
    { status: "COMPLETED", icon: "pi pi-check-circle", content: "Commande livrée" },
];


export const paymentMethodBadge: any = {
    CREDIT_CARD: { label: "Carte de Crédit", color: "bg-blue-500 text-white" },
    COD: { label: "Paiement à la livraison", color: "bg-gray-500 text-white" },
    BANK_TRANSFER: { label: "Virement Bancaire", color: "bg-indigo-500 text-white" },
    CASH: { label: "Espèces", color: "bg-green-500 text-white" },
    PAYPAL: { label: "PayPal", color: "bg-yellow-500 text-black" },
    STRIPE: { label: "Stripe", color: "bg-purple-500 text-white" },
};

// Liste des statuts de paiement avec badges
export const paymentStatusBadge: any = {
    PENDING: { label: "En attente", color: "bg-yellow-500 text-black" },
    COMPLETED: { label: "Complété", color: "bg-green-500 text-white" },
    PAID: { label: "Payé", color: "bg-blue-500 text-white" },
    UNPAID: { label: "Non payé", color: "bg-red-500 text-white" },
};


// Fonction pour attribuer une couleur et une icône à chaque méthode de paiement
export const getPaymentMethodBadge = (method: PaymentMethod | string) => {
    switch (method) {
        case PaymentMethod.CREDIT_CARD:
            return { label: "Carte", color: "bg-blue-500", icon: "pi pi-credit-card" };
            case PaymentMethod.CMI:
                return { label: "Carte", color: "bg-red-500", icon: "pi pi-credit-card" };
        case PaymentMethod.COD:
            return { label: "Cod", color: "bg-yellow-500", icon: "pi pi-wallet" };
        case PaymentMethod.BANK_TRANSFER:
            return { label: "Virement", color: "bg-indigo-500", icon: "pi pi-building" };
        case PaymentMethod.CASH:
            return { label: "Espèces", color: "bg-green-500", icon: "pi pi-money-bill" };
        case PaymentMethod.PAYPAL:
            return { label: "PayPal", color: "bg-blue-700", icon: "pi pi-paypal" };
        case PaymentMethod.STRIPE:
            return { label: "Stripe", color: "bg-purple-500", icon: "pi pi-globe" };
        default:
            return { label: "Inconnu", color: "bg-gray-400", icon: "pi pi-question" };
    }
};

export const getStatusBadge = (type: "order" | "payment" | "method", status: string) => {
    const statusConfig: any = {
        order: {
            PENDING: { label: "En attente", color: "bg-yellow-500", icon: "pi pi-clock" },
            CONFIRMED: { label: "Confirmée", color: "bg-blue-500", icon: "pi pi-check" },
            IN_SHIPPING: { label: "En livraison", color: "bg-indigo-500", icon: "pi pi-truck" },
            COMPLETED: { label: "Livrée", color: "bg-green-500", icon: "pi pi-check-circle" },
            CANCELED: { label: "Annulée", color: "bg-red-500", icon: "pi pi-times" },
        },
        payment: {
            PENDING: { label: "En attente", color: "bg-yellow-500", icon: "pi pi-clock" },
            COMPLETED: { label: "Complété", color: "bg-green-500", icon: "pi pi-check-circle" },
            PAID: { label: "Payé", color: "bg-blue-500", icon: "pi pi-dollar" },
            UNPAID: { label: "Non payé", color: "bg-red-500", icon: "pi pi-times" },
        },
        method: {
            CREDIT_CARD: { label: "Carte", color: "bg-blue-500", icon: "pi pi-credit-card" },
            COD: { label: "Cod", color: "bg-yellow-500", icon: "pi pi-wallet" },
            BANK_TRANSFER: { label: "Virement", color: "bg-indigo-500", icon: "pi pi-building" },
            CASH: { label: "Espèces", color: "bg-green-500", icon: "pi pi-money-bill" },
            PAYPAL: { label: "PayPal", color: "bg-blue-700", icon: "pi pi-paypal" },
            STRIPE: { label: "Stripe", color: "bg-purple-500", icon: "pi pi-globe" }

        }
    };
    return statusConfig[type][status] || { label: "Inconnu", color: "bg-gray-400", icon: "pi pi-question" };
};

export const formatDateOrders = (date: string, long: boolean = false): string => {
    return new Date(date).toLocaleString("fr-FR", {
        year: "numeric",
        month: `${long ? "long" : "2-digit"}`,
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
};

export const formatDate = (date: string, long: boolean = false): string => {
    return new Date(date).toLocaleString("fr-FR", {
        year: "numeric",
        month: `${long ? "long" : "2-digit"}`,
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
};
export function chunkItemsOrders<T>(
    items: T[],
    firstPageSize: number,
    otherPagesSize: number
): T[][] {
    const chunks: T[][] = [];
    if (items.length === 0) return chunks;

    // Première page
    chunks.push(items.slice(0, firstPageSize));

    // Pages suivantes
    for (let i = firstPageSize; i < items.length; i += otherPagesSize) {
        chunks.push(items.slice(i, i + otherPagesSize));
    }
    return chunks;
}