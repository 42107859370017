import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { Panel } from "primereact/panel";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import LoadingTable from "@components/Loading/LoadingTable";
import HeaderCard from "@components/UI/Header/HeaderCard";
import { CodePromo } from "@utils/interfaces/CodePromo";
import { CodePromoColumns } from "@components/CodePromo/CodePromoColumns";

// Suppression de useNavigate car non utilisé

const CodePromoList: React.FC = () => {
    // Typage explicite pour les filtres
    const [filters, setFilters] = useState<{ item_page: number }>({ item_page: 10 });
    const [totalItems, setTotalItems] = useState(0);
const {apiRequest} = useApiRequest()
    // Pagination
    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(
        () => ({
            page: currentPage,
            items_per_page: filters.item_page,
        }),
        [filters, currentPage]
    );

    const fetchCodePromos = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/promo_codes.json`,
            method: "GET",
            params,
        });

        // On suppose que l'API retourne un tableau de codes promo
        const rawPromos: CodePromo[] = response.data || [];
        setTotalItems(rawPromos.length);
        return rawPromos;
    }, [params]);

    const { data, isLoading, error } = useQuery({
        queryKey: ["promo_codes", params],
        queryFn: fetchCodePromos,
    });

    const table = useReactTable({
        data: data || [],
        columns: CodePromoColumns,
        getCoreRowModel: getCoreRowModel(),
    });

    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prev) => ({
                    ...prev,
                    [key]: key === "item_page" ? parseInt(value, 10) : value,
                }));
                setPage(1);
            }, 500),
        [setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="promo_codes" />;

    return (
        <div className="pt-3">
            <HeaderCard
                title="Liste des Codes Promo"
                btnPath="/dashboard/promocode/add"
                btntext="Ajouter un Code Promo"
            />
            <Panel className="rounded-lg p-2">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="bg-green-400 text-white uppercase text-xs text-center leading-tight"
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        className="border-b hover:bg-green-50 transition ease-in-out text-sm"
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setPage} />
            </Panel>
        </div>
    );
};

export default CodePromoList;
