import React, { useState, useRef } from "react";
import { Card } from "primereact/card";
import { Button as PrimeButton } from "primereact/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@mbs-dev/api-request";
import { useNavigate } from "react-router-dom";
import { notify } from "@mbs-dev/react-helpers";
import { apiUrl } from "@helpers/Helpers";

const SliderAdd: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const mutation = useMutation({
        mutationFn: async (formData: FormData) => {
            const response = await apiRequest({
                route: `${apiUrl}/sliders`,
                method: "POST",
                data: formData,
            });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["sliders"] });
            notify.successNotify("Slider ajouté avec succès !");
            navigate("/dashboard/slider");
        },
        onError: () => {
            notify.errorNotify("Erreur lors de l'ajout du slider.");
        },
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedFile) {
            notify.errorNotify("Veuillez sélectionner une image.");
            return;
        }
        const formData = new FormData();
        formData.append("image", selectedFile);
        mutation.mutate(formData);
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <Card className="w-full max-w-lg shadow-lg rounded-lg overflow-hidden bg-white">
                <div className="bg-green-600 text-white py-3 px-5 text-center">
                    <h2 className="text-xl font-semibold">Ajouter un Slider</h2>
                </div>
                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    {/* Sélection d'image */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Sélectionnez une image :
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:ring-2 focus:ring-green-500 focus:border-green-500"
                        />
                    </div>

                    {/* Aperçu de l'image sélectionnée */}
                    {previewUrl && (
                        <div className="flex justify-center">
                            <img
                                src={previewUrl}
                                alt="Prévisualisation"
                                className="h-48 w-full object-cover rounded-lg border border-gray-200 shadow-md"
                            />
                        </div>
                    )}

                    {/* Bouton de soumission */}
                    <div className="flex justify-center">
                        <PrimeButton
                            type="submit"
                            label={mutation.isPending ? "Chargement..." : "Ajouter le Slider"}
                            icon={mutation.isPending ? "pi pi-spinner pi-spin" : "pi pi-check"}
                            className="p-button-success bg-green-600 hover:bg-green-700 border-none text-white text-lg px-5 py-3 rounded-lg shadow-md transition-all duration-300"
                            disabled={mutation.isPending}
                        />
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default SliderAdd;
