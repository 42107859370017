import { Route, Routes } from 'react-router-dom';
import Login from '@pages/Login';
import Home from '@pages/Home';
import { ProtectedRoute } from '@routes/ProtectedRoute';
import { HomeRoute } from '@routes/HomeRoute';
import Profile from '@pages/Profile';
import CategoryList from '@pages/Categories/CategoriesList';
import NotAuthorized from '@pages/NotAuthorized';
import CategoriesAdd from '@pages/Categories/CategoriesAdd';
import UserList from '@pages/users/UserList';
import UserAdd from '@pages/users/UserAdd';
import LanguagesList from '@pages/setting/language/LanguageList';
import LanguageAdd from '@pages/setting/language/LanguageAdd';
import LanguageEdit from '@pages/setting/language/LanguageEdit';
import LanguageDeletePage from '@pages/setting/language/LanguageDeletePage';
import CategoriesDeletePage from '@pages/Categories/CategoriesDeletePage';
import CategoriesEdit from '@pages/Categories/CategoriesEdit';
import ProductList from '@pages/Products/ProductList';
import ProductDetails from '@pages/Products/ProductDetails';
import CountriesList from '@pages/setting/countries/CountriesList';
import CountryAdd from '@pages/setting/countries/CountryAdd';
import CountryEdit from '@pages/setting/countries/CountryEdit';
import CountryDeletePage from '@pages/setting/countries/CountryDeletePage';
import AttributesList from '@pages/setting/attributes/AttributesList';
import AttributAdd from '@pages/setting/attributes/AttributAdd';
import AttributEdit from '@pages/setting/attributes/AttributEdit';
import AttributesDeletePage from '@pages/setting/attributes/AttributesDeletePage';
import ProductForm from '@pages/Products/ProductForm';
import OrdersList from '@pages/orders/OrdersList';
import OrderDetails from '@pages/orders/OrderDetails';
import OrderOfflineList from '@pages/orders/OrderOfflineList';
import OrderOfflineDetails from '@pages/orders/OrderOfflineDetails';
import SocialMediaList from '@pages/setting/socialMedia/SocialMediaList';
import SocialMediaAdd from '@pages/setting/socialMedia/SocialMediaAdd';
import SocialMediaEdit from '@pages/setting/socialMedia/SocialMediaEdit';
import SocialMediaDeletePage from '@pages/setting/socialMedia/SocialMediaDeletePage';
import WebsiteInfoList from '@pages/setting/websiteInfo/websiteInfoList';
import WebsiteInfoEdit from '@pages/setting/websiteInfo/WebsiteInfoEdit';
import PromotionAdd from '@pages/Promotion/PromotionAdd';
import ReviewsList from '@pages/review/ReviewList';
import Stock from '@pages/stock/Stock';
import PromotionList from '@pages/Promotion/PromotionList';
import CodePromoAdd from '@pages/promocode/CodePromoAdd';
import CodePromoList from '@pages/promocode/CodePromoList';
import CodePromoEdit from '@pages/promocode/CodePromoEdit';
import SliderList from '@pages/setting/silders/SliderList';
import SliderAdd from '@pages/setting/silders/SliderAdd';


function MainNavigation() {


  return (

    <Routes>

      <Route path='/not-authorized' element={<NotAuthorized />} />
      <Route element={<HomeRoute />}>
        <Route path='/' element={<Login />} />
      </Route>
      <Route path='/dashboard' element={<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', "ROLE_USER"]} />}>
        <Route path='home' element={<Home />} />
        <Route path='profile' element={<Profile />} />
        {/* categories */}
        <Route path='categories' element={<CategoryList />} />
        <Route path='categories/add' element={<CategoriesAdd />} />
        <Route path='categories/edit/:id' element={<CategoriesEdit />} />
        <Route path='categories/delete/:id' element={<CategoriesDeletePage />} />
        {/* products */}
        <Route path='products' element={<ProductList />} />
        <Route path='products/details/:id' element={<ProductDetails />} />
        <Route path='products/add' element={<ProductForm />} />
        {/* promotion */}
        <Route path='promotion/add' element={<PromotionAdd />} />
        <Route path='promotion' element={<PromotionList />} />
        {/* code promo */}
        <Route path='promocode' element={<CodePromoList />} />
        <Route path='promocode/add' element={<CodePromoAdd />} />
        <Route path='promocode/edit/:id' element={<CodePromoEdit />} />
        {/* review */}
        <Route path='reviews/' element={<ReviewsList />} />
        {/* stock */}
        <Route path='stock/' element={<Stock />} />
        {/* commande */}
        <Route path='orders' element={<OrdersList />} />
        <Route path='orders/details/:orderId' element={<OrderDetails />} />
        <Route path='ordersOffline' element={<OrderOfflineList />} />
        <Route path='ordersOffline/details/:orderId' element={<OrderOfflineDetails />} />
        {/* users */}
        <Route path='users' element={<UserList />} />
        <Route path='users/add' element={<UserAdd />} />
        {/* languages */}
        <Route path='setting/languages' element={<LanguagesList />} />
        <Route path='setting/languages/add' element={<LanguageAdd />} />
        <Route path='setting/languages/edit/:id' element={<LanguageEdit />} />
        <Route path='setting/languages/delete/:id' element={<LanguageDeletePage />} />
        {/* attributes */}
        <Route path='setting/attributes' element={<AttributesList />} />
        <Route path='setting/attributes/add' element={<AttributAdd />} />
        <Route path='setting/attributes/edit/:id' element={<AttributEdit />} />
        <Route path='setting/attributes/delete/:id' element={<AttributesDeletePage />} />
        {/* countries */}
        <Route path='setting/countries' element={<CountriesList />} />
        <Route path='setting/countries/add' element={<CountryAdd />} />
        <Route path='setting/countries/edit/:id' element={<CountryEdit />} />
        <Route path='setting/countries/delete/:id' element={<CountryDeletePage />} />
        {/* socialMedia */}
        <Route path='setting/socialMedia' element={<SocialMediaList />} />
        <Route path='setting/socialMedia/add' element={<SocialMediaAdd />} />
        <Route path='setting/socialMedia/edit/:id' element={<SocialMediaEdit />} />
        <Route path='setting/socialMedia/delete/:id' element={<SocialMediaDeletePage />} />
        {/* info */}
        <Route path='setting/info' element={<WebsiteInfoList />} />
        <Route path='setting/info/edit/:id' element={<WebsiteInfoEdit />} />
        {/* silder */}
        <Route path='setting/slider' element={<SliderList />} />
        <Route path='setting/slider/add' element={<SliderAdd />} />
      </Route>


      <Route path='/dashboard/commercial' element={<ProtectedRoute roles={['ROLE_COMMERCIAL']} />}>
        <Route path='home' element={<Home />} />
      </Route>

      <Route path='/dashboard/livreur' element={<ProtectedRoute roles={['ROLE_LIVREUR']} />}>
        <Route path='home' element={<Home />} />
      </Route>
    </Routes>
  );
}

export default MainNavigation;
