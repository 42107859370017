// src/pages/OrderOfflineList.tsx
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Panel } from "primereact/panel";
import ErrorLoadingData from "@components/UI/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import LoadingTable from "@components/Loading/LoadingTable";
import { offlineOrderColumns } from "@components/orders/offlineOrderColumns";
import { SerializedOfflineOrder } from "@utils/interfaces/order";
import { Button } from "primereact/button";

// Définition des filtres pour les commandes offline
interface FilterOfflineOrders {
    reference: string;
    item_page: number;
}

const OrderOfflineList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // État des filtres
    const [filters, setFilters] = useState<FilterOfflineOrders>({
        reference: "",
        item_page: 10,
    });

    const [totalItems, setTotalItems] = useState(0);

    // Pagination
    const { currentPage, totalPages, setPage } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    // Paramètres de la requête
    const params = useMemo(
        () => ({
            page: currentPage,
            items_per_page: filters.item_page,
            ...(filters.reference && { reference: filters.reference }),
        }),
        [filters, currentPage]
    );

    // Fonction pour récupérer et sérialiser les commandes offline
    const fetchFilteredOfflineOrders = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/offline_orders`,
            method: "GET",
            params,
            headers: {
                Accept: "application/ld+json",
            },
        });

        // Mise à jour du total d'éléments
        if (response.data && response.data["hydra:totalItems"]) {
            setTotalItems(response.data["hydra:totalItems"]);
        } else {
            setTotalItems(0);
        }

        const rawOrders = response.data?.["hydra:member"] || [];

        const serializedOrders: SerializedOfflineOrder[] = rawOrders.map(
            (order: any) => ({
                id: order.id,
                uid: order.uid,
                reference: order.reference,
                fullName: order.fullName,
                email: order.email,
                telephone: order.telephone,
                address: order.address,
                ville: order.ville,
                zipCode: order.zipCode,
                price: order.price ?? 0,
                itemsQuantity: order.itemsQuantity ?? 0,
                comment: order.comment ? `${order.comment.slice(0, 30)}...` : "---",
                orderStatus: order.orderStatus ?? "PENDING",
                paymentMethod: order.paymentMethod ?? "---",
                paymentStatus: order.paymentStatus ?? "PENDING",
                offlineOrderItems: order.offlineOrderItems || [],
                createdAt: order.createdAt,
                updatedAt: order.updatedAt,
            })
        );

        return serializedOrders;
    }, [params, apiRequest]);

    const { data, isLoading, error } = useQuery({
        queryKey: ["offline_orders", params],
        queryFn: fetchFilteredOfflineOrders,
    });

    // Initialisation du tableau avec TanStack React Table
    const table = useReactTable({
        data: data || [],
        columns: offlineOrderColumns,
        getCoreRowModel: getCoreRowModel(),
    });

    // Gestion des filtres avec debounce
    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prev) => ({
                    ...prev,
                    [key]: key === "item_page" ? parseInt(value, 10) : value,
                }));
                setPage(1);
            }, 500),
        [setFilters, setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="offline orders" />;

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-blue-100 border-2 border-blue-200 rounded-lg">
            <h2 className="text-lg font-semibold text-blue-700">
                Liste des commandes offline
            </h2>
            <Button
                label="Online Order"
                icon="pi pi-receipt"
                className="p-button-sm p-button-outlined"
                onClick={() => navigate("/dashboard/orders")}
            />
        </div>
    );

    return (
        <div className="pt-3">
            <Panel headerTemplate={header} className="rounded-lg p-2">
                {/* Filtres */}
                <div className="px-4 py-3">
                    <div className="flex flex-row gap-3">
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="text"
                                id="reference"
                                name="reference"
                                placeholder="Filtrer par référence"
                                defaultValue={filters.reference}
                                onChange={(e) =>
                                    debouncedHandleFilterChange("reference", e.target.value)
                                }
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                            />
                        </div>
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="number"
                                id="item_page"
                                name="item_page"
                                placeholder="Items par page"
                                defaultValue={filters.item_page}
                                onChange={(e) =>
                                    debouncedHandleFilterChange("item_page", e.target.value)
                                }
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                            />
                        </div>
                    </div>
                </div>

                {/* Tableau */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="bg-blue-400 text-white uppercase text-xs text-center leading-tight">
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-blue-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id} className="border-b hover:bg-blue-50 transition ease-in-out text-sm">
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setPage} />
            </Panel>
        </div>
    );
};

export default OrderOfflineList;
