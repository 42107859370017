import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";
import { Language } from "@utils/interfaces/language";

// requiresAuth: true,
// token: `${localStorage.getItem('userToken')}`,
// headers: {
//     'Content-Type': 'multipart/form-data',
// }


export interface AddLanguagePayload {
    name: string;
    code: string;
}




    export const fetchLanguages = async (apiRequest: any) => {

        try {
            const response = await apiRequest({
                route: `${apiUrl}/languages.json`,
                method: 'GET',
            });

            return response.data ;
        } catch (error) {
            notify.errorNotify("error fetch Language")
        }
    };



export const addLanguage = async (apiRequest: any, data: AddLanguagePayload) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/languages`,
            method: 'POST',
            data: data,
            // requiresAuth: true,
            // token: `${localStorage.getItem('userToken')}`,
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
        });

        notify.successNotify("Language create successfully");
    } catch (error) {
        notify.errorNotify("error in create Language")
    }
};




export const getLanguage = async (apiRequest: any, id: any): Promise<Language> => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/languages/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur langue Not Found !!');
        throw new Error('Erreur lors de la récupération de la langue');
    }
};


export const getGeo = async (apiRequest: any): Promise<Language> => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/getgeo`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur langue Not Found !!');
        throw new Error('Erreur lors de la récupération de la langue');
    }
};





export const deleteLanguage = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/languages/${id}.json`,
            method: 'DELETE',
        });
        notify.successNotify('Langue supprimée avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la suppression de la langue');
        throw new Error('Erreur lors de la suppression de la langue');
    }
};

export const updateLanguage = async (apiRequest: any, id: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/languages/${id}`,
            method: 'PUT',
            data: data,
        });

        notify.successNotify('Langue mise à jour avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la mise à jour de la langue');
    }
};