import React from "react";

const LoadingSkeleton: React.FC = () => {
    return (
        <div className="animate-pulse bg-white shadow-md rounded-xl overflow-hidden">
            <div className="w-full h-40 bg-gray-300 rounded-t-xl"></div>
            <div className="p-4 space-y-3">
                <div className="h-4 bg-gray-300 rounded w-3/4 mx-auto"></div>
                <div className="h-3 bg-gray-200 rounded w-5/6 mx-auto"></div>
                <div className="h-3 bg-gray-200 rounded w-2/3 mx-auto"></div>
            </div>
        </div>
    );
};

export default LoadingSkeleton;
