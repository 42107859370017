// src/components/products/add/ProductForm.tsx

import React, { useState, useEffect, Suspense } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card } from 'primereact/card';
import { Button as PrimeButton } from 'primereact/button';
import { Steps } from 'primereact/steps';
import useApiRequest from '@mbs-dev/api-request';
import LoadingData from '@components/Loading/LoadingData';
import ErrorLoadingData from '@components/UI/Errors/ErrorLoadingData';
import { notify } from '@mbs-dev/react-helpers';
import { useNavigate } from 'react-router-dom';
import { addProduct, fetchProducts } from '@utils/api/Product';
import { fetchLanguages } from '@utils/api/Lang';
import { fetchCategories, fetchCategoriesParent } from '@utils/api/category';

import productValidationSchema from '@utils/schemas/productValidationSchema';
import buildProductFormData, { ProductFormValues } from '@components/products/add/helpers/buildProductFormData';
import StepContent from '@components/products/add/helpers/StepContent';
import { Dialog } from 'primereact/dialog';
import 'primeicons/primeicons.css';
import HeaderCard from '@components/UI/Header/HeaderCard';

const ProductForm = () => {
    const { apiRequest } = useApiRequest();
    const queryClient = useQueryClient();
    const [productType, setProductType] = useState('parent'); // parent, variant, final_solo, final_with_parent
    const [activeIndex, setActiveIndex] = useState(0); // For the stepper
    const [showConfirm, setShowConfirm] = useState(false); // Confirmation dialog

    // Fetch languages
    const {
        data: languages,
        isLoading: loadingLanguages,
        error: languagesError
    } = useQuery({
        queryKey: ['languages'],
        queryFn: () => fetchLanguages(apiRequest)
    });

    // Fetch categories
    const {
        data: categories,
        isLoading: loadingCategories,
        error: categoriesError
    } = useQuery({
        queryKey: ['categories'],
        queryFn: () => fetchCategoriesParent(apiRequest)
    });

    // Fetch products (for variants and final products with parent)
    const {
        data: products,
        isLoading: loadingProducts,
        error: productsError
    } = useQuery({
        queryKey: ['products'],
        queryFn: () => fetchProducts(apiRequest)
    });

    // Mutation for adding product
    const mutation = useMutation({
        mutationFn: async (data: FormData) => await addProduct(apiRequest, data),
        onSuccess: () => {
            setShowConfirm(true);
            queryClient.invalidateQueries({ queryKey: ['products'] });
        },
        onError: () => {
            notify.errorNotify("Erreur lors de l'ajout du produit.");
        }
    });

    // Initialize Formik
    const formik = useFormik<ProductFormValues>({
        initialValues: {
            type: 'parent',
            category: '',
            subCategory:'' , 
            parentProduct: '',
            availability: true,
            stock: 0,
            reference: '',
            barcode: '',
            productTranslates: [
                {
                    language: 0,
                    slug: "",
                    title: '',
                    description: '',
                    howToUse: '',
                    ingredients: '',
                    image: null,
                    productImages: []
                }
            ],
            countryPrices: [{ country: '', price: 0 }],
            productAttributes: [{ attribute: '', value: '' }]
        },
        // validationSchema: productValidationSchema,
        onSubmit: async (values) => {
            // **Await** the asynchronous buildProductFormData
            const formData = await buildProductFormData(values);
            console.log("form Data : ", formData);

            // If you want to wait for the mutation to complete before continuing,
            // you can also do: await mutation.mutateAsync(formData);
            mutation.mutate(formData);
        },
    });

    // Product type options
    const productTypeOptions = [
        { label: 'Parent', value: 'parent' },
        { label: 'Variante', value: 'variant' },
        { label: 'Produit Final Solo', value: 'final_solo' },
        { label: 'Produit Final avec Parent', value: 'final_with_parent' },
    ];

    // Parent category options
    const parentCategoryOptions =
        categories?.map((category: any) => {
            const translate = category.categoryTranslates.find(
                (t: any) => t.language.code === 'en'
            );

            console.log("categoryy"  , category);
            
            return {
                label: translate ? translate.title : 'Untitled',
                value: category.id,
            };
        }) || [];

    // Parent product options (for variants, finals with parent, etc.)
    const parentProductOptions =
        products?.filter((p: any) => p.type !== 'FINAL').map((p: any) => ({
            label: p.fullTitle,
            value: p.id,
        })) || [];

    // Specifically for final_with_parent if needed
    const parentProductVariantOptions =
        products?.filter((p: any) => p.type === 'PARENT').map((p: any) => ({
            label: p.fullTitle,
            value: p.id,
        })) || [];

    // Steps
    const getSteps = (type: string) => {
        const steps = [
            { label: 'Informations Générales', icon: 'pi pi-info-circle' }
        ];
        if (type === 'final_solo' || type === 'final_with_parent') {
            steps.push(
                { label: 'Disponibilité et Stock', icon: 'pi pi-box' },
                { label: 'Prix par Pays', icon: 'pi pi-dollar' }
            );
            // "Attributs" only for final_with_parent
            if (type === 'final_with_parent') {
                steps.push({ label: 'Attributs', icon: 'pi pi-sliders-h' });
            }
        }
        steps.push(
            { label: 'Traductions', icon: 'pi pi-globe' },
            { label: 'Résumé', icon: 'pi pi-check-circle' }
        );
        return steps;
    };

    const steps = getSteps(productType);

    // Adjust activeIndex if the number of steps changes
    useEffect(() => {
        if (activeIndex >= steps.length) {
            setActiveIndex(steps.length - 1);
        }
    }, [productType, steps.length, activeIndex]);

    const handleNext = () => {
        formik.validateForm().then(errors => {
            const currentStepFields = getCurrentStepFields();
            const stepErrors = currentStepFields.filter(
                (field) => field in errors && errors[field as keyof typeof errors]
            );
            if (stepErrors.length === 0) {
                if (activeIndex < steps.length - 1) {
                    setActiveIndex(prev => prev + 1);
                }
            } else {
                // Mark fields as touched
                const touchedFields = currentStepFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {} as any);
                formik.setTouched(touchedFields);
                notify.errorNotify("Veuillez corriger les erreurs avant de continuer.");
            }
        });
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(prev => prev - 1);
        }
    };

    const getCurrentStepFields = () => {
        const stepLabel = steps[activeIndex].label;
        switch (stepLabel) {
            case 'Informations Générales':
                return ['type', 'category', 'parentProduct'];
            case 'Disponibilité et Stock':
                return ['availability', 'stock', 'reference', 'barcode'];
            case 'Prix par Pays':
                return ['countryPrices'];
            case 'Traductions':
                return ['productTranslates'];
            case 'Résumé':
                return [];
            case 'Attributs':
                return ['productAttributes'];
            default:
                return [];
        }
    };

    // Custom renderer with icons
    const itemRenderer = (item: any, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

        return (
            <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => setActiveIndex(itemIndex)}
            >
                <span
                    className="inline-flex align-items-center justify-content-center border-circle border-primary border-1 h-3rem w-3rem z-1"
                    style={{ backgroundColor: backgroundColor, color: textColor }}
                >
                    <i className={`${item.icon} text-xl`} />
                </span>
                <span
                    className="mt-2 text-sm text-center"
                    style={{ color: isActiveItem ? 'var(--primary-color)' : 'var(--text-color-secondary)' }}
                >
                    {item.label}
                </span>
            </div>
        );
    };

    useEffect(() => {
        console.log("product to submit ", formik.values);
    }, [formik.values]);

    if (loadingLanguages || loadingCategories || loadingProducts) {
        return <LoadingData />;
    }

    if (languagesError || categoriesError || productsError) {
        return <ErrorLoadingData entity='Products' />;
    }

    return (
        <FormikProvider value={formik}>
            <div className="max-w-6xl mx-auto my-6 p-4">
                <Card className="shadow-lg">
                    <HeaderCard title="Ajouter un Produit" />

                    <div className="p-4">
                        <div className="mb-4">
                            <Steps
                                model={steps.map((step, index) => ({
                                    ...step,
                                    template: () => itemRenderer(step, index)
                                }))}
                                activeIndex={activeIndex}
                                className="mb-2"
                                readOnly={false}
                            />
                        </div>

                        <Suspense fallback={<LoadingData />}>
                            <div className="step-content">
                                <StepContent
                                    label={steps[activeIndex].label}
                                    productType={productType}
                                    setProductType={setProductType}
                                    formik={formik}
                                    languages={languages}
                                    parentCategoryOptions={parentCategoryOptions}
                                    parentProductOptions={parentProductOptions}
                                    parentProductVariantOptions={parentProductVariantOptions}
                                />
                            </div>
                        </Suspense>

                        {/* Step Navigation */}
                        <div className="flex justify-between mt-6">
                            {activeIndex > 0 && (
                                <PrimeButton
                                    label="Précédent"
                                    icon="pi pi-arrow-left"
                                    onClick={handlePrev}
                                    className="p-button-secondary text-xs"
                                />
                            )}
                            {activeIndex < steps.length - 1 && (
                                <PrimeButton
                                    label="Suivant"
                                    icon="pi pi-arrow-right"
                                    onClick={handleNext}
                                    className="p-button-primary text-xs"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </div>
                    </div>
                </Card>
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                header="Succès"
                visible={showConfirm}
                style={{ width: '30vw' }}
                onHide={() => setShowConfirm(false)}
            >
                <p>Le produit a été ajouté avec succès.</p>
                <div className="flex justify-end mt-4">
                    <PrimeButton
                        label="Fermer"
                        onClick={() => setShowConfirm(false)}
                        className="p-button-success text-xs"
                    />
                </div>
            </Dialog>
        </FormikProvider>
    );
};

export default ProductForm;
