// ParentProductSelector.tsx
import React from 'react';
import { Dropdown } from 'primereact/dropdown';

interface ParentProductSelectorProps {
    parentProduct: string;
    setParentProduct: (value: string) => void;
    options: { label: string; value: string }[];
    error?: string;
    touched?: boolean;
}



const ParentProductSelector: React.FC<ParentProductSelectorProps> = ({
    parentProduct,
    setParentProduct,
    options,
    error,
    touched,
}) => {


    console.log("parent Product " , parentProduct);
    
    return (
        <div>
            <label className="block text-sm font-extrabold mb-2">Produit Parent :</label>
            <Dropdown
                value={parentProduct}
                options={options}
                onChange={(e) => setParentProduct(e.value)}
                placeholder="Sélectionnez un produit parent"
                className="w-full"
            />
            {touched && error && (
                <p className="text-red-500 text-xs mt-1">{error}</p>
            )}
        </div>
    );
};

export default ParentProductSelector;
