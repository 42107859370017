import React from "react";
import { useFormik, FormikProvider } from "formik";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button as PrimeButton } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import useApiRequest from "@mbs-dev/api-request";
import { useNavigate } from "react-router-dom";
import { notify } from "@mbs-dev/react-helpers";
import * as Yup from "yup";
import { apiUrl } from "@helpers/Helpers";
import LoadingData from "@components/Loading/LoadingData";

// ----------------------------------------------------------------------
// Interfaces et types
// ----------------------------------------------------------------------
interface Country {
    id: number;
    name: string;
    code: string;
}

interface PromotionFormData {
    name: string;
    discount: number;
    startAt: Date | null;
    endAt: Date | null;
    status: number;
    country: Country | null;
}

// ----------------------------------------------------------------------
// Valeurs initiales et schéma de validation
// ----------------------------------------------------------------------
const PromotionIV: PromotionFormData = {
    name: "",
    discount: 0,
    startAt: null,
    endAt: null,
    status: 1,
    country: null,
};

const PromotionAddSchema = Yup.object().shape({
    name: Yup.string().required("Le nom de la promotion est requis"),
    discount: Yup.number()
        .min(0, "Doit être positif")
    ,
    startAt: Yup.date().required("La date de début est requise"),
    endAt: Yup.date().required("La date de fin est requise"),
    country: Yup.object().nullable().required("Le pays est requis"),
});

// ----------------------------------------------------------------------
// Composant CodePromoAdd
// ----------------------------------------------------------------------
const CodePromoAdd: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const formik = useFormik<PromotionFormData>({
        initialValues: PromotionIV,
        validationSchema: PromotionAddSchema,
        onSubmit: (values) => {
            // Construction du payload à envoyer à l'API
            const payload: any = {
                name: values.name,
                startAt: values.startAt ? values.startAt.toISOString() : null,
                endAt: values.endAt ? values.endAt.toISOString() : null,
                status: values.status, // Déjà un int (1 ou 0)
                country: values.country ? `api/countries/${values.country.id}` : null,
                discount: values.discount
            };

            mutation.mutate(payload);
        },
    });

    // Mutation pour envoyer la requête POST sur /promo_codes
    const mutation = useMutation({
        mutationFn: (data: any) => {
            return apiRequest({
                route: `${apiUrl}/promo_codes`,
                method: "POST",
                data,
            });
        },
        onMutate: () => {
            formik.setSubmitting(true);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["promo_codes"] });
            navigate("/dashboard/promocode");
            formik.setSubmitting(false);
            notify.successNotify("Promotion créée avec succès !");
        },
        onError: () => {
            formik.setSubmitting(false);
            notify.errorNotify(
                "Erreur lors de la création de la promotion. Vérifiez la console pour plus de détails."
            );
        },
    });

    // Récupération des pays
    const { data: countries, isLoading: loadingCountries, error: countriesError } = useQuery<Country[]>({
        queryKey: ["countries"],
        queryFn: () =>
            apiRequest({
                route: `${apiUrl}/countries.json`,
                method: "GET",
            }).then((res) => res.data),
    });

    if (countriesError) {
        return <div>Erreur lors du chargement des pays.</div>;
    }

    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Créer un Code Promo</h2>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 border border-green-200 rounded-bl-lg rounded-br-lg text-sm">
                        {/* Nom du code promo */}

                        <div className="flex  flex-col gap-4 md:flex-row">
                            <div className="md:w-1/2 w-full">
                                <label className=" text-sm font-medium mb-2">Nom du Code Promo :</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="ARG2025FR"
                                    className="p-2 border rounded w-full"
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                                )}
                            </div>

                            <div className="md:w-1/2 w-full">
                                <label className=" text-sm font-medium mb-2">Discount (%) :</label>
                                <input
                                    type="number"
                                    name="discount"
                                    value={formik.values.discount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Entrer le pourcentage de discount"
                                    className="p-2 border rounded w-full"
                                />
                                {formik.touched.discount && formik.errors.discount && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.discount}</p>
                                )}
                            </div>
                        </div>




                        {/* Discount ou Prix */}



                        {/* Dates de début et de fin */}
                        <div className="flex flex-col gap-4 md:flex-row">
                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium mb-2">Date de début :</label>
                                <Calendar
                                    value={formik.values.startAt}
                                    onChange={(e) => formik.setFieldValue("startAt", e.value)}
                                    showTime
                                    dateFormat="dd/mm/yy"
                                    className="w-full"
                                />
                                {formik.touched.startAt && formik.errors.startAt && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.startAt}</p>
                                )}
                            </div>
                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium mb-2">Date de fin :</label>
                                <Calendar
                                    value={formik.values.endAt}
                                    onChange={(e) => formik.setFieldValue("endAt", e.value)}
                                    showTime
                                    dateFormat="dd/mm/yy"
                                    className="w-full"
                                />
                                {formik.touched.endAt && formik.errors.endAt && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.endAt}</p>
                                )}
                            </div>
                        </div>

                        {/* Sélection du pays */}
                        <div>
                            <label className="block text-sm font-medium mb-2">Pays :</label>
                            {loadingCountries ? (
                                <div><LoadingData /></div>
                            ) : (
                                <Dropdown
                                    value={formik.values.country}
                                    options={
                                        countries?.map((ctry) => ({
                                            label: ctry.name,
                                            value: ctry,
                                        })) || []
                                    }
                                    onChange={(e) => formik.setFieldValue("country", e.value)}
                                    placeholder="Sélectionner un pays"
                                    className="w-full"
                                />
                            )}
                            {formik.touched.country && formik.errors.country && (
                                <p className="text-red-500 text-xs mt-1">{formik.errors.country}</p>
                            )}
                        </div>

                        {/* Status */}
                        <div>
                            <label className="block text-sm font-medium mb-2">Status :</label>
                            <InputSwitch
                                checked={formik.values.status === 1}
                                onChange={(e) => formik.setFieldValue("status", e.value ? 1 : 0)}
                            />
                        </div>

                        {/* Bouton de soumission */}
                        <div className="flex justify-center">
                            <PrimeButton
                                type="submit"
                                label={formik.isSubmitting ? "Création en cours..." : "Créer le Code Promo"}
                                icon={formik.isSubmitting ? "pi pi-spinner pi-spin" : "pi pi-check"}
                                className="p-button-success text-xs"
                                disabled={formik.isSubmitting}
                            />
                        </div>
                    </form>
                </Card>
            </div>
        </FormikProvider>
    );
};

export default CodePromoAdd;
